import React, { useContext } from 'react';
import Animated from 'animated/lib/targets/react-dom';
import { styles } from './styledComponent';
import ImageBW from '../ImageBoundsWrapper';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ThemeContext from 'shared/context/ThemeContext';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import TooltipContainer from './components/TooltipContainer';

export default function FtueTooltip({ props }) {
  const { displayText, arrowStyle, animationClassName, showArrow = true, style, containerStyle = {} } = props;
  const themeContext = useContext(ThemeContext);
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <Animated.div
      className={`Tooltip-Container ${animationClassName ? animationClassName : 'AnimationFadeIn'}`}
      style={{
        ...style,
      }}
    >
      <TooltipContainer containerStyle={containerStyle} showArrow={showArrow} arrowStyle={arrowStyle}>
        <div style={styles.content}>
          <div style={styles.content}>
            <span
              className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
              style={themeContext.FtueTooltip.TooltipTitleStyle}
              data-label={displayText}
            >
              {displayText}
            </span>
            <ImageBW
              style={styles.divider}
              source={assetSource(ASSET_KEY.LEVEL_UP_LIGHT_DIVIDER)}
              ibw={require(asset`./images/level-up/light-divider.ibw`)}
              widthScale={0.5}
            />
          </div>
          <div className="TooltipGameUnlock" style={{ marginTop: 10 }} onClick={() => {}}>
            <div style={styles.left}>
              <ImageBW
                source={assetSource(ASSET_KEY.COIN_ICON)}
                ibw={require(asset`./images/level-up/icon.ibw`)}
                style={styles.icon}
              />
            </div>
            <div style={styles.right}>
              <span
                className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                style={themeContext.FtueTooltip.TooltipRewardStyle}
                data-label={getText(TEXT_KEY.FTUE_BETTER_REWARDS)}
              >
                {getText(TEXT_KEY.FTUE_BETTER_REWARDS)}
              </span>
            </div>
          </div>
          <div className="TooltipGameUnlock" style={{ marginTop: 10 }} onClick={() => {}}>
            <div style={styles.left}>
              <ImageBW
                source={assetSource(ASSET_KEY.MAX_BET)}
                ibw={require(asset`./images/level-up/icon.ibw`)}
                style={styles.icon}
              />
            </div>
            <div style={styles.right}>
              <span
                className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                style={themeContext.FtueTooltip.BiggerBetsStyle}
                data-label={getText(TEXT_KEY.FTUE_BETTER_BIGGER_BETS)}
              >
                {getText(TEXT_KEY.FTUE_BETTER_BIGGER_BETS)}
              </span>
            </div>
          </div>
        </div>
      </TooltipContainer>
    </Animated.div>
  );
}

