import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { postLinkGuestAccountMutation } from './queries';
export var linkGuestAccountApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postLinkGuestAccount: builder.mutation(postLinkGuestAccountMutation)
    };
  }
});
linkGuestAccountApi.enhanceEndpoints({
  addTagTypes: ['LinkGuestAccount']
});
var usePostLinkGuestAccountMutation = linkGuestAccountApi.usePostLinkGuestAccountMutation,
  postLinkGuestAccount = linkGuestAccountApi.endpoints.postLinkGuestAccount;
export { usePostLinkGuestAccountMutation, postLinkGuestAccount };