import React, { Component } from 'react';
import Utils from 'shared/utils';
import { generateGameListData } from 'shared/components/GamePage/utils';
import GameTile from '../GameTile';
import CustomScrollBar from '../CustomScrollBar';
import { MainContainer, getScrollBarConfig } from './styles';
import './scrollBarStyles.scss';

export default class Home extends Component {
  gameTileRef = React.createRef();
  gameTileWidth = 0;

  measureGameTileWidth = () => {
    if (this.gameTileRef.current) {
      this.gameTileWidth = this.gameTileRef.current.offsetWidth;
    }
  };

  componentDidUpdate() {
    this.measureGameTileWidth();
  }

  render() {
    const { pagedGames, pageNumber, navigation, ftueStep, gameIndex, hasPromotions } = this.props;
    const { gameList, totalGamesCount } = generateGameListData(pagedGames, pageNumber, ftueStep);

    return (
      <CustomScrollBar {...getScrollBarConfig(ftueStep, gameIndex, hasPromotions, totalGamesCount)}>
        <MainContainer>
          {gameList.map((panelList, indexPaged) => {
            return (
              <div className="live-panel-grid" key={indexPaged}>
                {!Utils.isEmpty(panelList) &&
                  panelList.map((item, index) => {
                    return (
                      item && (
                        <GameTile
                          gameTileRef={this.gameTileRef}
                          highlightedForOnboarding={index === 0 && indexPaged === 0}
                          itemData={item}
                          key={index}
                          navigation={navigation}
                        />
                      )
                    );
                  })}
              </div>
            );
          })}
        </MainContainer>
      </CustomScrollBar>
    );
  }
}

