import React from 'react';
import {
  DateRowItem,
  DescriptionImage,
  DescriptionRowItem,
  DescriptionText,
  HistoryTableRow,
  HistoryTableRowItem,
  RemainingRowItem,
  StateRowItem,
} from './styledComponents';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import Button from '../../../../../components/Button/Button';
import { getDecimalAmount } from 'shared/utils/number';
import Utils from 'shared/utils';
import { isStar } from 'shared/utils/targetApplication';

const HistoryItem = ({ item, displayDetailsColumn, setActiveHistoryItem, index }) => {
  return (
    <HistoryTableRow withDetailsButton={item.showDetailsButton} index={index}>
      <DescriptionRowItem displayDetailsColumn={displayDetailsColumn} withDetailsButton={item.showDetailsButton}>
        <DescriptionImage src={item.cat_icon} ieHack={Utils.ieVersion() !== 0} />
        <DescriptionText>{item.desc}</DescriptionText>
      </DescriptionRowItem>
      <StateRowItem displayDetailsColumn={displayDetailsColumn} withDetailsButton={item.showDetailsButton}>
        {item.state}
      </StateRowItem>
      <DateRowItem displayDetailsColumn={displayDetailsColumn} withDetailsButton={item.showDetailsButton}>
        {item.date}
      </DateRowItem>
      {isStar && (
        <RemainingRowItem displayDetailsColumn={displayDetailsColumn} withDetailsButton={item.showDetailsButton}>
          {item.state !== 'expired' ? getDecimalAmount(item.amountLeft || 0) : '-'}
        </RemainingRowItem>
      )}
      {item.showDetailsButton && (
        <HistoryTableRowItem>
          <Button
            containerStyle={{ position: 'relative' }}
            imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_DANGER)}
            onClick={() => setActiveHistoryItem(item)}
            imageStyle={{ width: '75%', height: '100%' }}
            textStyle={{ padding: '2%' }}
            label={getText(TEXT_KEY.DETAILS)}
          />
        </HistoryTableRowItem>
      )}
    </HistoryTableRow>
  );
};

export default HistoryItem;

