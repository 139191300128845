import React, { Component } from 'react';
import SOnboardingLayer from 'shared/components/OnboardingLayer';
import { FTUE_STEPS_KEYS } from 'shared/constants';
import ThemeContext from 'shared/context/ThemeContext';
import TooltipContainer from '../Tooltip/components/TooltipContainer';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
export default class OnboardingLayer extends Component {
  tooltipPosition = (x, y, width, height, position = 'right') => {
    switch (position) {
      case 'right':
        return { top: y + height / 2 - 30, left: x + width + 15 };
      case 'bottom':
        return { top: y + height + 15, left: x + width / 2 - 30 };
      case 'left':
        return { top: y + height / 2 - 30, right: window.innerWidth - x + 15 };
      case 'top':
        return { bottom: window.innerHeight - y + 15, left: x + width / 2 - 30 };
      default:
        return { top: y + height / 2 - 30, left: x + width + 15 };
    }
  };

  tooltipTriangleClass = (position = 'right') => {
    switch (position) {
      case 'right':
        return 'OnboardingLayerTooltipRightTriangle';
      case 'bottom':
        return 'OnboardingLayerTooltipBottomTriangle';
      case 'left':
        return 'OnboardingLayerTooltipLeftTriangle';
      case 'top':
        return 'OnboardingLayerTooltipTopTriangle';
      default:
        return 'OnboardingLayerTooltipRightTriangle';
    }
  };

  tooltipTriangleStyles = (x, y, width, height, position = 'right') => {
    switch (position) {
      case 'right':
        return { top: y + height / 2 - 10, left: x + width + 5 };
      case 'bottom':
        return { top: y + height + 5, left: x + width / 2 - 10 };
      case 'left':
        return { top: y + height / 2 - 10, right: window.innerWidth - x + 5 };
      case 'top':
        return { bottom: window.innerHeight - y + 5, left: x + width / 2 - 10 };
      default:
        return { top: y + height / 2 - 10, left: x + width - 5 };
    }
  };

  render() {
    const themeContext = this.context;
    return (
      <SOnboardingLayer
        navigation={this.props.navigation}
        render={args => {
          const { x, y, width, height, tooltip, transparent, hasImage, isCircle, ftueStep, skipTutorial } = args;

          const boxBackgroundColor = transparent ? 'transparent' : 'rgba(0,0,0,0.75)';

          const boxesSpecificStyles = {
            left: { top: 0, bottom: 0, left: 0, width: x, backgroundColor: boxBackgroundColor },
            top: { top: 0, left: x, height: y, width, backgroundColor: boxBackgroundColor },
            bottom: { top: y + height, bottom: 0, left: x, width, backgroundColor: boxBackgroundColor },
            right: { top: 0, bottom: 0, left: x + width, right: 0, backgroundColor: boxBackgroundColor },
            main: {
              position: 'absolute',
              width: width,
              height: height,
              left: x,
              top: y,
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              display: 'flex',
              backgroundImage: hasImage ? `url(${hasImage})` : 'none',
            },
          };

          return (
            <div className="OnboardingLayer">
              {ftueStep > FTUE_STEPS_KEYS.INITIAL && (
                <div class="FtueSkipHolder" onClick={skipTutorial}>
                  <img
                    src={assetSource(ASSET_KEY.SKIP_BUTTON)}
                    alt=""
                    style={{ width: 140, height: 50, objectFit: 'contain' }}
                  />
                </div>
              )}
              <div className="OnboardingLayerBox" style={boxesSpecificStyles.left} />
              <div className="OnboardingLayerBox" style={boxesSpecificStyles.top} />
              <div className="OnboardingLayerBox" style={boxesSpecificStyles.bottom} />
              <div className="OnboardingLayerBox" style={boxesSpecificStyles.right} />
              {isCircle && (
                <div className={'OnboardingLayerImage'} style={boxesSpecificStyles.main}>
                  <div
                    className={'boxShaddow'}
                    style={{
                      width: width,
                      height: width,
                      top: 0,
                      left: 0,
                      backgroundColor: 'transparent',
                      borderRadius: width / 2,
                    }}
                  ></div>
                </div>
              )}
              {hasImage && (
                <div
                  className={'OnboardingLayerImage'}
                  style={Object.assign(boxesSpecificStyles.main, { backgroundColor: boxBackgroundColor })}
                >
                  <img src={hasImage} alt="" style={{ width: width, zIndex: -1, opacity: 0 }} />
                </div>
              )}
              {tooltip.message && (
                <>
                  <div
                    className={`OnboardingLayerTooltipTriangle ${this.tooltipTriangleClass(tooltip.position)}`}
                    style={this.tooltipTriangleStyles(x, y, width, height, tooltip.position)}
                  />
                  <div
                    className="OnboardingLayerTooltip"
                    style={{
                      ...this.tooltipPosition(x, y, width, height, tooltip.position),
                      ...tooltip.customStyles,
                    }}
                  >
                    <TooltipContainer
                      showArrow={false}
                      style={{
                        ...tooltip.customStyles,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={assetSource(ASSET_KEY.FLY_OUT_STAR)}
                        alt="Italian Trulli"
                        style={{ position: 'absolute', left: 12, top: -25, width: 50, height: 50 }}
                      />
                      <img
                        src={assetSource(ASSET_KEY.FLY_OUT_STAR)}
                        alt="Italian Trulli"
                        style={{ position: 'absolute', right: 12, bottom: -25, width: 50, height: 50 }}
                      />
                      <span style={themeContext.OnboardingLayer.MessageTitleTooltip} data-label={tooltip.message}>
                        {tooltip.message}
                      </span>
                    </TooltipContainer>
                  </div>
                </>
              )}
            </div>
          );
        }}
      />
    );
  }
}

OnboardingLayer.contextType = ThemeContext;

