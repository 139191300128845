import React, { useContext } from 'react';
import styles from '../styles';
import { PlayerInfoWrapper, PlayerInfoBox, LabelHolder, DividerWrapper } from '../styledComponents';
import ThemeContext from 'shared/context/ThemeContext';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import ImageBW from '../../../../components/ImageBoundsWrapper';

const PlayerInfo = props => {
  const { Account } = useContext(ThemeContext);
  const { playerId, casinoId, email, isGuestUser } = props;

  return (
    <>
      <PlayerInfoWrapper>
        <PlayerInfoBox>
          <LabelHolder
            theme={Account.Label}
            className="account-title"
            data-label={getText(TEXT_KEY.MY_USER_ID)}
          ></LabelHolder>
          <span className="account-value">{playerId || getText(TEXT_KEY.NONE)}</span>
        </PlayerInfoBox>
        <PlayerInfoBox>
          <LabelHolder
            theme={Account.Label}
            className="account-title"
            data-label={getText(TEXT_KEY.MEMBER_ID)}
          ></LabelHolder>
          <span className={`account-value`} data-label={casinoId || getText(TEXT_KEY.NONE)}></span>
        </PlayerInfoBox>
      </PlayerInfoWrapper>
      <DividerWrapper>
        <ImageBW
          style={styles.divider}
          source={assetSource(ASSET_KEY.SETTINGS_DIVIDER)}
          ibw={require(asset`./images/settings/settings-divider.ibw`)}
          widthScale={0.5}
        />
      </DividerWrapper>
      {(ENVIRONMENT_VARIABLES.ACCOUNT_EMAIL.toString() === 'false' || isGuestUser) && (
        <PlayerInfoBox>
          <LabelHolder theme={Account.Label} className="account-title">
            {getText(TEXT_KEY.EMAIL)}
          </LabelHolder>
          <span className="account-value">{email || getText(TEXT_KEY.NONE)}</span>
        </PlayerInfoBox>
      )}
    </>
  );
};
export default PlayerInfo;

