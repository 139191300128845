import React from 'react';
import { AccountCintainer, EditInfoRow } from './styledComponents';
import styles from './styles';
import LinkAccount from './components/LinkAccount/';
import PlayerInfo from './components/PlayerInfo';
import EditInfoSection from './components/EditInfoSection';
import PromotionSection from './components/PromotionSection';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import ImageBW from '../../../components/ImageBoundsWrapper';

const AccountSettings = props => {
  const {
    email,
    emailOptIn,
    isGuest,
    verifiedAccount,
    lastLoginInfo,
    toggleEmailOptIn,
    onChangeComponentHandler,
    screensEnum,
    isLinkGuestAccountEnabled,
  } = props;
  const isGuestUser = isGuest || lastLoginInfo.loginMethod === 'Guest';
  const isEditInfoEnable =
    !isGuestUser &&
    (ENVIRONMENT_VARIABLES.ACCOUNT_EMAIL === 'true' || ENVIRONMENT_VARIABLES.ACCOUNT_CHANGE_PASSWORD === 'true');

  return (
    <AccountCintainer>
      <PlayerInfo {...props} isGuestUser={isGuestUser} />
      {isEditInfoEnable && (
        <>
          <EditInfoRow>
            {ENVIRONMENT_VARIABLES.ACCOUNT_EMAIL === 'true' && (
              <EditInfoSection
                title={getText(TEXT_KEY.EMAIL)}
                handle={() => onChangeComponentHandler(screensEnum.updateEmail)}
                value={email}
                verifiedAccount={verifiedAccount}
              />
            )}
            {ENVIRONMENT_VARIABLES.ACCOUNT_CHANGE_PASSWORD === 'true' && (
              <EditInfoSection
                title={getText(TEXT_KEY.CHANGE_PASSWORD)}
                handle={() => onChangeComponentHandler(screensEnum.changePassword)}
                value={getText(TEXT_KEY.PASSWORD_HINT)}
                verifiedAccount={verifiedAccount}
                isPassword={true}
              />
            )}
          </EditInfoRow>
          <div className="bottom-divider">
            <ImageBW
              style={styles.divider}
              source={assetSource(ASSET_KEY.SETTINGS_DIVIDER)}
              ibw={require(asset`./images/settings/settings-divider.ibw`)}
              widthScale={0.5}
            />
          </div>
        </>
      )}
      {isGuestUser && isLinkGuestAccountEnabled && <LinkAccount />}
      {!isGuestUser && <PromotionSection handle={() => toggleEmailOptIn()} value={emailOptIn} />}
    </AccountCintainer>
  );
};

export default AccountSettings;

