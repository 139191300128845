import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import SForm from 'shared/components/Form';
import AutoFormInput from '../AutoFormInput';
import Button from '../Button/Button';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default class Form extends Component {
  state = {};

  render() {
    return (
      <SForm
        {...this.props}
        render={args => {
          const {
            header,
            steps,
            currentStep,
            status,
            complete,
            getValidation,
            nextTouchHandler,
            prevTouchHandler,
            validatedHandler,
            inputChangeHandler,
            layoutChangeHandler,
          } = args;

          const { formInputList, submitButtonText } = steps[currentStep];
          let formBody;
          if (formInputList) {
            formBody = formInputList.map((component, index) => {
              if (!component.hide) {
                return (
                  <AutoFormInput
                    {...component}
                    key={`${component.id}-${index}`}
                    emitValidated={validatedHandler}
                    emitInput={inputChangeHandler}
                    emitLayoutChange={layoutChangeHandler}
                    validated={getValidation(component.id)}
                    customStyle={this.props.customInputStyle}
                  />
                );
              }
            });
          }

          return (
            <>
              {!this.props.hideTopBar && (
                <div className="web-form-top-bar">
                  <Col className="left">
                    <a className="link" onClick={prevTouchHandler}>
                      {header.backButtonText ? header.backButtonText : getText(TEXT_KEY.CANCEL)}
                    </a>
                  </Col>
                  {header.helpLink && (
                    <Col className="right">
                      <a className="link" href={header.helpLink} target="_blank" rel="noopener noreferrer">
                        {getText(TEXT_KEY.NEED_HELP)}
                      </a>
                    </Col>
                  )}
                </div>
              )}
              <div className="form-container">
                <div className="form-scrollContainer">
                  {!!header.title && <h4 className="form-title">{header.title}</h4>}

                  {!!this.props.subtitle && <div>{this.props.subtitle}</div>}

                  <div className={this.props.formCustomContent ? 'form-custom-content' : 'form-content'}>
                    {steps.length > 0 && formBody}
                    {status.length > 0 && <p className="form-wide-status">{status}</p>}
                    {!!this.props.customConfirmButton ? (
                      <Button
                        textStroke={BUTTON_STROKES.PRIMARY}
                        imageSource={
                          !!complete
                            ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)
                            : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
                        }
                        onClick={!!complete ? nextTouchHandler : null}
                        label={submitButtonText}
                        disabled={!complete}
                        containerStyle={(!!this.props.customStyle && this.props.customStyle) || {}}
                        objectFit={'contain'}
                      />
                    ) : (
                      <div
                        className={!!complete ? 'form-confirm-button' : 'form-confirm-button disabled'}
                        onClick={!!complete ? nextTouchHandler : null}
                      >
                        <img
                          src={
                            !!complete
                              ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)
                              : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
                          }
                          alt="Complete"
                        />
                        <p className="form-confirm-button-label">{submitButtonText}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          );
        }}
      />
    );
  }
}
