import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { DefaultHeaderContainer, DefaultHeaderTextWrapper, DefaultSubtitle } from './styleComponents';
import ImageBW from '../../../../components/ImageBoundsWrapper';

const DefaultHeader = () => {
  return (
    <>
      <DefaultHeaderContainer>
        <DefaultHeaderTextWrapper
          className={`modalTitleGradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
          data-label={'SORRY!'}
        >
          {getText(TEXT_KEY.SORRY)}!
        </DefaultHeaderTextWrapper>
        <DefaultSubtitle>{getText(TEXT_KEY.SOMETHING_WENT_WRONG)}</DefaultSubtitle>
      </DefaultHeaderContainer>
      <ImageBW
        source={assetSource(ASSET_KEY.SHOP_VIP_DIVIDER_GLOWS)}
        ibw={require(asset`shop-vip-divider-glows.ibw`)}
        manualAssetScale={3}
        style={styles.divider}
        innerStyle={styles.dividerInner}
      ></ImageBW>
    </>
  );
};

export default DefaultHeader;

const styles = {
  divider: {
    height: '7vmin',
    width: '100%',
    marginTop: '1vmin',
  },
  dividerInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingLeft: '1vmin',
    paddingRight: '1vmin',
  },
};

