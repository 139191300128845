import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { tooltipDetails, shouldShowTooltip } from '../../../state/actions/tooltip';
import { EventsIconTootipDetail } from '../tooltips';
import { EVENTS_FEATURE_STATUS } from '../constants';
import { useDispatch } from 'react-redux';
import { TEXT_KEY, getText } from '../../../utils/localization';
import screens from '../../../screens';
var ICON_TOOLTIP_TEXT = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, EVENTS_FEATURE_STATUS.MAINTENANCE, getText(TEXT_KEY.EVENTS_ERROR_TOOLTIP)), EVENTS_FEATURE_STATUS.COMING_SOON, getText(TEXT_KEY.EVENTS_COMING_TOOLTIP)), EVENTS_FEATURE_STATUS.MUST_UPDATE, getText(TEXT_KEY.EVENTS_MUST_UPDATE_TOOLTIP)), "default", getText(TEXT_KEY.EVENTS_ERROR_TOOLTIP));
export default function useEventsIconTooltip(_ref) {
  var eventsState = _ref.eventsState,
    navigation = _ref.navigation;
  var _useSelector = useSelector(function (state) {
      return {
        modal: state.modal.modal,
        actionCommands: state.actionCommands.actions,
        showTooltip: state.tooltip.shouldShowTooltip
      };
    }),
    modal = _useSelector.modal,
    actionCommands = _useSelector.actionCommands,
    showTooltip = _useSelector.showTooltip;
  var dispatch = useDispatch();
  var firstCycle = useRef(true);
  var showTooltipIsChecked = useRef(false);
  var actionCommandsRef = useRef(actionCommands);
  var showTooltipRef = useRef(showTooltip);
  useEffect(function () {
    actionCommandsRef.current = actionCommands;
  }, [actionCommands]);
  useEffect(function () {
    showTooltipRef.current = showTooltip;
  }, [showTooltip]);
  var handleEventsIconTooltip = function handleEventsIconTooltip() {
    var _actionCommandsRef$cu;
    var checkActions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    var currentActionCommands = (_actionCommandsRef$cu = actionCommandsRef.current) === null || _actionCommandsRef$cu === void 0 ? void 0 : _actionCommandsRef$cu.length;
    var currentShowTooltip = showTooltipRef.current;
    if (checkActions && (currentActionCommands !== 0 || currentShowTooltip)) return;
    showTooltipIsChecked.current = true;
    var detail = EventsIconTootipDetail(ICON_TOOLTIP_TEXT[eventsState] || ICON_TOOLTIP_TEXT.default);
    dispatch(tooltipDetails(detail));
    dispatch(shouldShowTooltip(true));
    setTimeout(function () {
      dispatch(shouldShowTooltip(false));
    }, 2000);
  };
  var showTooltipOnAppOpen = useCallback(function () {
    /**
     * 1- modal on first cycle is always null so we need to skip it
     * 2- We need a timeout to ensure the bottom bar appears correctly after the slide-up intro animation (web)
     */
    if (firstCycle.current) {
      firstCycle.current = false;
      return;
    }
    if (modal || (actionCommands === null || actionCommands === void 0 ? void 0 : actionCommands.length) !== 0 || navigation.state.routes[navigation.state.index].key !== screens.Home || showTooltipIsChecked.current) return;
    setTimeout(function () {
      eventsState !== EVENTS_FEATURE_STATUS.ACTIVE && eventsState !== EVENTS_FEATURE_STATUS.INACTIVE && handleEventsIconTooltip(true);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsState, modal, actionCommands]);
  useEffect(function () {
    return showTooltipOnAppOpen();
  }, [showTooltipOnAppOpen]);
  return {
    handleEventsIconTooltip: handleEventsIconTooltip
  };
}