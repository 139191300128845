import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import { FE_SOCIAL } from '../../../utils/featureFlags/constants';
import useFriendsInvite from './useFriendsInvite';
import useMessages from './useMessages';
var useSocial = function useSocial() {
  var unreadMessagesCount = useSelector(function (state) {
    return state.notifications.unreadMessagesCount;
  });
  var _useFeatureFlags = useFeatureFlags(),
    hasFeature = _useFeatureFlags.hasFeature;
  var messagesData = useMessages();
  var inviteData = useFriendsInvite({
    skip: !hasFeature(FE_SOCIAL)
  });
  var inviteLoading = inviteData.inviteLoading,
    inviteErrorNotFound = inviteData.inviteErrorNotFound,
    hasPendingState = inviteData.hasPendingState,
    isGuestUser = inviteData.isGuestUser;
  var showLoading = useMemo(function () {
    return inviteLoading && hasFeature(FE_SOCIAL) && !inviteErrorNotFound;
  }, [inviteLoading, hasFeature, inviteErrorNotFound]);
  var showFriendsFeature = useMemo(function () {
    return hasFeature(FE_SOCIAL) && (!hasPendingState || isGuestUser && hasPendingState) && !inviteLoading && !inviteErrorNotFound;
  }, [hasFeature, hasPendingState, inviteLoading, inviteErrorNotFound, isGuestUser]);
  return _objectSpread(_objectSpread({
    count: unreadMessagesCount,
    showFriendsFeature: showFriendsFeature,
    showLoading: showLoading
  }, messagesData), inviteData);
};
export default useSocial;