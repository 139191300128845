import React from 'react';
import { getLinkTextClientColor } from 'shared/utils/linkTextsColors';
import styled from 'styled-components';

const LinkText = ({
  children,
  customColor = null,
  onClick = () => {},
  customStyle = {},
  underline = true,
  href = null,
  target = null,
  theme = null,
}) => {
  return (
    <Text
      color={customColor || getLinkTextClientColor}
      onClick={onClick}
      customStyle={customStyle}
      underline={underline}
      href={href}
      target={target}
      theme={theme}
    >
      {children}
    </Text>
  );
};

export default LinkText;

const Text = styled.a.attrs(({ href, target }) => ({
  href: href || '#',
  target: target || '',
}))`
  text-decoration: ${({ underline }) => (underline ? 'underline !important' : 'none')};
  color: ${({ color }) => color}!important;
  cursor: pointer;
  pointer-events: auto;
  ${({ customStyle }) => customStyle}
  ${({ theme }) => theme}
`;

