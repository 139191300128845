var SettingsScreensEnum = {
  customerSupport: 'customerSupport',
  messageSent: 'messageSent',
  messageSentError: 'messageSentError',
  account: 'account',
  settings: 'settings',
  changePassword: 'changePassword',
  changePasswordSuccess: 'changePasswordSuccess',
  changePasswordError: 'changePasswordError',
  updateEmail: 'updateEmail',
  updateEmailSuccess: 'updateEmailSuccess',
  updateEmailError: 'updateEmailError',
  languageSwitcher: 'languageSwitcher'
};
export default SettingsScreensEnum;