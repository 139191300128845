import { Endpoints } from '../../../api/Constants';
export var getSSOLinksQuery = {
  query: function query() {
    return {
      url: "".concat(Endpoints.GET_SSO_LINKS)
    };
  },
  transformResponse: function transformResponse(response) {
    if (!response) throw Error("[Get-SSO-Links-response] undefined");else if (response.sucess === false) throw new Error("[Get-SSO-Links-response] ".concat(response.error));
    return response;
  },
  providesTags: ['SSOLinks']
};