import { connect } from '../../node_modules/react-redux';
import SessionExpired from './SessionExpired';
import { setOnboardingFtueStep as _setOnboardingFtueStep } from '../../state/actions/currentOnboarding';
import { clearState as _clearState } from '../../state/actions/app';
import { apiBaseQuery } from '../../state/api/services/rtkQuery/apiBaseQuery';
var mapStateToProps = function mapStateToProps(state) {
  return {
    ftueStep: state.currentOnboarding.ftueStep
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setOnboardingFtueStep: function setOnboardingFtueStep(step) {
      dispatch(_setOnboardingFtueStep(step));
    },
    clearState: function clearState() {
      dispatch(_clearState());
    },
    clearApiQueryState: function clearApiQueryState() {
      dispatch(apiBaseQuery.util.resetApiState());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SessionExpired);