import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { useSelector } from 'react-redux';
import { EVENTS_FEATURE_STATUS } from '../constants';
export default function useGenerateEventsIconRender(_ref) {
  var eventsAssets = _ref.eventsAssets,
    eventsState = _ref.eventsState;
  var assetsUrls = useSelector(function (state) {
    return state.app.assetsUrls;
  });
  var baseUrl = "".concat(assetsUrls === null || assetsUrls === void 0 ? void 0 : assetsUrls.ImagesCdnBaseUrl, "/assetsManager/EVENTS_FEATURE/events-feature-global-default");
  var eventsIconRender = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, EVENTS_FEATURE_STATUS.ACTIVE, eventsAssets === null || eventsAssets === void 0 ? void 0 : eventsAssets.EVENTS_ICON_ACTIVE), EVENTS_FEATURE_STATUS.COMING_SOON, eventsAssets === null || eventsAssets === void 0 ? void 0 : eventsAssets.EVENTS_COMING_SOON), EVENTS_FEATURE_STATUS.MUST_UPDATE, eventsAssets === null || eventsAssets === void 0 ? void 0 : eventsAssets.EVENTS_MUST_UPDATE), EVENTS_FEATURE_STATUS.MAINTENANCE, "".concat(baseUrl, "/icons/events-error.png"));
  var EVENTS_DEFAULT_TOOLTIP = "".concat(baseUrl, "/tooltip/tooltip.png");
  var eventIcon = eventsAssets ? eventsIconRender[eventsState] : eventsIconRender[EVENTS_FEATURE_STATUS.MAINTENANCE];
  return {
    EVENTS_DEFAULT_TOOLTIP: EVENTS_DEFAULT_TOOLTIP,
    eventIcon: eventIcon
  };
}