import theme from 'shared/assets/style/theme';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const LoyaltyPointsValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const LoyaltyValueText = styled.p`
  color: ${theme.palette.common[1]};
  text-align: center;
  text-shadow: -1px 1px 1px ${theme.palette.common[28]};
  font-size: 5em;
  font-weight: 700;
  margin-bottom: 0;

  @media only screen and (max-height: 790px) {
    font-size: 3.5em;
  }
`;

const LoyaltyPointsExpiryText = styled.p`
  color: ${theme.palette.common[1]};
  text-transform: uppercase;
  text-align: center;
  text-shadow: -1px 1px 1px ${theme.palette.common[28]};
  font-size: 2em;
  width: 75%;
  font-weight: 700;

  @media only screen and (max-height: 790px) {
    font-size: 1.5em;
  }
`;

const LoyaltyIcon = styled.img`
  max-width: 150px;

  @media only screen and (max-height: 790px) {
    max-width: 105px;
  }
`;

export { Container, LoyaltyValueText, LoyaltyPointsExpiryText, LoyaltyPointsValueContainer, LoyaltyIcon };

