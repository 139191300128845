import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { styles } from '../../styles';
import LinkText from '../../../../components/LinkText/LinkText';

const DefaultFormFooter = ({ enableOrderCard, handleRegisterRewardCard, termsAndConditionsUrl }) => {
  return (
    <p style={styles.termsText}>
      {enableOrderCard && (
        <>
          {`${getText(TEXT_KEY.LINK_CARD_SIGNUP)}`}{' '}
          <LinkText customStyle={styles.footerLinkText} onClick={handleRegisterRewardCard} underline>
            {getText(TEXT_KEY.LINK_CARD_SIGNUP_CTA)}
          </LinkText>
        </>
      )}
      {getText(TEXT_KEY.TERMS_AGREE_MESSAGE)}{' '}
      <LinkText customStyle={styles.footerLinkText} onClick={() => window.open(termsAndConditionsUrl)} underline>
        {getText(TEXT_KEY.TERMS_OF_SERVICE)}
      </LinkText>
    </p>
  );
};

export default DefaultFormFooter;

