import React, { Component } from 'react';
import SFavouritesInformation from 'shared/screens/FavouritesInformation';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import LevelUpText from './components/LevelUpText';
import Button from '../../components/Button/Button';

export default class FavouritesInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-favourites-information',
    };
  }

  render() {
    return (
      <SFavouritesInformation
        navigation={this.props.navigation}
        render={args => {
          const { dismissModal } = args;

          return (
            <div style={{ textAlign: 'center' }}>
              <LevelUpText />
              <img
                alt="Favourites info"
                src={assetSource(ASSET_KEY.GAMES_IMAGE)}
                style={{ width: '80%', paddingTop: '2%' }}
              />
              <div>
                <p>{getText(TEXT_KEY.FAVORITES_REQUIRED_LEVEL)}</p>
              </div>
              <Button
                imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                containerStyle={{
                  bottom: '5%',
                  cursor: 'pointer',
                  pointerEvents: 'auto',
                  textAlign: 'center',
                  width: '25%',
                  margin: '0 auto',
                  padding: '1rem',
                }}
                label={getText(TEXT_KEY.OK)}
                onClick={dismissModal}
                textStroke={BUTTON_STROKES.PRIMARY}
              />
            </div>
          );
        }}
      />
    );
  }
}

FavouritesInformation.hideOverlay = true;
FavouritesInformation.hideBeforeSelector = true;

