import generateRender from 'shared/utils/generateRender';
import DefaultPreloader from './DefaultPreloader';
import HriPreloader from './HriPreloader';
import WidePreloader from './WidePreloader';

const AssetPreloader = generateRender({
  default: DefaultPreloader,
  hri: HriPreloader,
  millelacs: WidePreloader,
});

export default AssetPreloader;
