import React from 'react';
import styled from 'styled-components';
import Button from '../../../../components/Button/Button';

const RewardCenterCategoriesOpened = props => {
  const {
    categories,
    rewardCenterAssets,
    setFilterData,
    setShowCategories,
    categorySelected,
    setCategorySelected,
    clearFilterData,
  } = props;

  const onClickButton = (categoryId, categoryName) => {
    if (categoryId === categorySelected) {
      clearFilterData();
      return;
    }
    setCategorySelected(categoryId);
    setFilterData(categoryName);
    setShowCategories(false);
  };

  return (
    <Container>
      <Background length={categories.length} bgImage={rewardCenterAssets.REWARD_CENTER_OPENED_MENU_BACKGROUND}>
        <Header>
          <Arrow src={rewardCenterAssets.REWARD_CENTER_OPENED_MENU_ARROW} />
          <Title src={rewardCenterAssets.REWARD_CENTER_OPENED_MENU_TITLE} />
          <Arrow src={rewardCenterAssets.REWARD_CENTER_OPENED_MENU_ARROW} />
        </Header>
        <Buttons>
          {categories.map(({ categoryId, categoryName }) => (
            <Button
              key={categoryId}
              label={categoryName}
              imageSource={
                categorySelected === categoryId
                  ? rewardCenterAssets.REWARD_CENTER_OPENED_MENU_BUTTON_ACTIVE
                  : rewardCenterAssets.REWARD_CENTER_OPENED_MENU_BUTTON_DEFAULT
              }
              textStyle={getTextstyle(categoryName, categorySelected, categoryId)}
              imageStyle={buttonStyle}
              containerStyle={buttonContainerStyle}
              onClick={() => onClickButton(categoryId, categoryName)}
            />
          ))}
        </Buttons>
      </Background>
    </Container>
  );
};

export default RewardCenterCategoriesOpened;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Background = styled.div`
  width: 100%;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 34rem;
  height: ${({ length }) => 8 + Math.ceil(length / 3) * 2}rem;
  position: absolute;
  top: ${({ length }) => -2.8 + Math.ceil(length / 3) * -2}rem;
  z-index: 2;

  @media only screen and (max-width: 859px), only screen and (max-height: 520px) {
    width: 22rem;
    top: ${({ length }) => -2.3 + Math.ceil(length / 3) * -1.5}rem;
    height: ${({ length }) => 4 + Math.ceil(length / 3) * 2}rem;
  }

  @media only screen and (min-width: 860px) and (max-width: 960px) and (min-height: 521px) {
    width: 30rem;
    top: ${({ length }) => -3.8 + Math.ceil(length / 3) * -2}rem;
  }
`;

const Header = styled.div`
  justify-content: space-around;
  display: flex;
  margin-top: 1rem;
`;

const Title = styled.img`
  width: 45%;
  height: 100%;
  object-fit: contain;
`;

const Arrow = styled.img`
  width: 3%;
  height: 100%;
  object-fit: contain;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5% 1.5%;
`;

const buttonContainerStyle = `
  width: 175px;
  margin-bottom: 5%;
  @media (max-width: 859px) {
    width: 112px;
  }
  @media only screen and (min-width: 860px) and (max-width: 960px) {
    width: 155px;
  }
`;

const buttonStyle = `
  width: 155px;
  @media (max-width: 859px) {
    width: 92px;
  }
  @media only screen and (min-width: 860px) and (max-width: 960px) {
    width: 135px;
  }
`;

const getTextstyle = (categoryName, categorySelected, categoryId) => {
  const defaultColor = categorySelected === categoryId ? '#7C028D' : '#2E074F';
  return `
    text-transform: uppercase;
    font-size: ${categoryName?.length > 15 ? '0.8rem' : '1rem'};
    width: ${categoryName?.length > 15 ? '99%' : '100%'};
    padding-bottom: ${categoryName?.length > 15 ? '0%' : '3%'};
    text-stroke: 1px ${defaultColor};
    -webkit-text-stroke: 1px ${defaultColor};

    @media (max-width: 859px) {
      text-stroke: none;
      -webkit-text-stroke: none;
      font-size: ${categoryName?.length > 15 ? '0.5rem' : '0.7rem'};
    }
`;
};

