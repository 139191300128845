import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getSSOLinksQuery } from './queries';
export var ssoLinksApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getSSOLinks: builder.query(getSSOLinksQuery)
    };
  }
});
ssoLinksApi.enhanceEndpoints({
  addTagTypes: ['SSOLinks']
});
var getSSOLinks = ssoLinksApi.endpoints.getSSOLinks;
export { getSSOLinks };