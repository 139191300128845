import React from 'react';
import ImageBW from '../../../../../../components/ImageBoundsWrapper';
import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/JSModal';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import {
  KycPopUpContainer,
  KycContentWrapper,
  KycCheckTextWrapper,
  KycRewardMessageWrapper,
  KycRewardMessageStyle,
  KycContinueButtonWrapper,
  kycConsentPopupStyles,
} from './styledComponents';

const KycConsentPopUp = ({ onKycCheck, hideKycPopUp, showKycPopUp }) => {
  return (
    <Modal
      style={kycConsentPopupStyles.modalStyle}
      modalMainBodyStyle={kycConsentPopupStyles.modalMainBodyStyle}
      isVisible={showKycPopUp}
      onBackButtonPress={hideKycPopUp}
    >
      <KycPopUpContainer>
        <KycContentWrapper>
          <KycCheckTextWrapper>
            <span style={kycConsentPopupStyles.kycCheckTextStyle}>{getText(TEXT_KEY.KYC_CHECK)}</span>
          </KycCheckTextWrapper>
          <KycRewardMessageWrapper>
            <ImageBW
              source={assetSource(ASSET_KEY.SHOP_VIP_DIVIDER_GLOWS)}
              ibw={require(asset`shop-vip-divider-glows.ibw`)}
              style={{ height: '50%', width: '100%', marginTop: '2%' }}
            />
            <KycRewardMessageStyle>{getText(TEXT_KEY.REWARD_CENTER_KYC_MESSAGE)}</KycRewardMessageStyle>
          </KycRewardMessageWrapper>
          <KycContinueButtonWrapper>
            <Button
              imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
              imageStyle={{ width: '150px', height: '50px' }}
              onClick={onKycCheck}
              label={getText(TEXT_KEY.CONTINUE_BUTTON)}
            />
          </KycContinueButtonWrapper>
        </KycContentWrapper>
      </KycPopUpContainer>
    </Modal>
  );
};

export default KycConsentPopUp;

