import styled from 'styled-components';

const CarouselWrapper = styled.div`
  width: 30%;
  height: 100%;
  justify-content: center;
`;

const OfferFrame = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

const OfferImageLimitedDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 10%;
  @media only screen and (max-height: 900px) {
    ${({ theme }) => theme}
  }
`;

const OfferImageBannerStyle = styled.img`
  width: 100%;
  position: absolute;
`;

const CoinsIcon = styled.img`
  height: 70%;
  width: 8%;
  margin-right: 10px;
  object-fit: contain;
  z-index: 5;
`;

const CoinsValue = styled.p`
  font-size: 0.7rem;
  color: #fff;
  text-shadow: 0px -1.5px 1px #000;
  font-weight: 600;
  margin-bottom: 0;
  z-index: 5;
  @media only screen and (max-height: 900px) {
    ${({ theme }) => theme}
  }
`;

const CustomIndicator = styled.div`
  bottom: -4em;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
`;

export {
  CarouselWrapper,
  OfferFrame,
  OfferImageLimitedDescription,
  OfferImageBannerStyle,
  CoinsIcon,
  CoinsValue,
  CustomIndicator,
};

