import React, { useState } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { getDecimalAmount } from 'shared/utils/number';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  BarCodeContainer,
  ContentContainer,
  DividerImage,
  EmailSentMessage,
  HistoryDetailContainer,
  RemainingBalanceContainer,
  SendEmailButton,
} from './styledComponents';

const QrHistoryDetail = props => {
  const { offerHistoryDetails, voucherCode, activeHistoryItem, onSendEmail, emailSentMessage } = props;
  const termsParagraphs = offerHistoryDetails.terms?.split('<br><br>');
  const [width, setWidth] = useState(0);

  const onScroll = () => {
    setWidth(0.5);
  };

  return (
    <HistoryDetailContainer>
      <Scrollbars
        onScroll={onScroll}
        renderThumbVertical={props => <div {...props} className="thumb-vertical" style={{ width: width + 'rem' }} />}
        renderTrackVertical={props => (
          <div {...props} className="track-vertical" style={{ opacity: width > 0 ? 1 : 0 }} />
        )}
      >
        <ContentContainer>
          <DividerImage
            hasSubtitle={!!offerHistoryDetails.subtitle}
            src={assetSource(ASSET_KEY.SHOP_VIP_DIVIDER_GLOWS)}
          />
          <div className="RewardCenterHistoryDetailTitle">{offerHistoryDetails.rewardname}</div>
          <div className="RewardCenterHistoryDetailSubtitle star">{offerHistoryDetails.subtitle}</div>
          {offerHistoryDetails.showBarcode && (
            <BarCodeContainer>
              <div>
                <img
                  alt=""
                  className="RewardCenterHistoryDetailBarcode star"
                  src={offerHistoryDetails.barcodeImageURL}
                />
                <p className="voucherText">{voucherCode}</p>
              </div>
              <RemainingBalanceContainer>
                <div className="RewardCenterHistoryDetailRemainingBalance">
                  {getText(TEXT_KEY.REMAINING_BALANCE)}{' '}
                  {activeHistoryItem.state !== 'expired' ? getDecimalAmount(activeHistoryItem.amountLeft || 0) : '-'}
                </div>

                <div className="RewardCenterHistoryDetailIssueDate">
                  {getText(TEXT_KEY.ISSUED_ON)}
                  {offerHistoryDetails.redeemDate}
                </div>

                <div style={{ marginTop: '2px' }} className="RewardCenterHistoryDetailExpiryDate">
                  {offerHistoryDetails.expiryDate}
                </div>

                <SendEmailButton
                  imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                  onClick={onSendEmail}
                  label={getText(TEXT_KEY.SEND_EMAIL)}
                />
              </RemainingBalanceContainer>
            </BarCodeContainer>
          )}
          {emailSentMessage && (
            <EmailSentMessage isError={emailSentMessage.isError}>{emailSentMessage.message}</EmailSentMessage>
          )}
          <div className="RewardCenterHistoryDetailTermsTitle star">
            {getText(TEXT_KEY.PURCHASE_AND_USAGE_LIMITATIONS)}
          </div>
          <div className="RewardCenterHistoryDetailTerms">
            {termsParagraphs && termsParagraphs.map((paragraph, index) => <div key={index}>{paragraph}</div>)}
          </div>
        </ContentContainer>
      </Scrollbars>
    </HistoryDetailContainer>
  );
};

export default QrHistoryDetail;

