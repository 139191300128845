import React, { Component } from 'react';
import ImageBW from '../../components/ImageBoundsWrapper';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default class AdBlocker extends Component {
  headerHeight = '3.7rem';
  _style = {
    header: {
      width: '100%',
      height: this.headerHeight,
    },
    headerImage: {
      height: this.headerHeight,
      width: '100%',
      objectFit: 'contain',
      zIndex: 11,
      position: 'absolute',
      top: 0,
      left: 0,
    },
  };

  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-adblocker',
    };
  }

  render() {
    const description = Object.values(getText(TEXT_KEY.ADBLOCK_DESCRIPTION));
    const title = getText(TEXT_KEY.ADBLOCK_TITLE);
    return (
      <>
        <div className="adBlocker-header" style={this._style.header}>
          <ImageBW
            source={assetSource(ASSET_KEY.FULL_MODAL_HEADER)}
            ibw={require(asset`full-modal-header.ibw`)}
            style={this._style.headerImage}
          >
            <div className="header-content">
              <div style={{ width: '2rem' }} />
              <span className="adBlocker-title" data-label={title}>
                {title}
              </span>
              <div style={{ width: '2em' }}></div>
            </div>
          </ImageBW>
        </div>
        <div className="adBlocker-container">
          {description.map(element => (
            <p>{element}</p>
          ))}
        </div>
      </>
    );
  }
}

