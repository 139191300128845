import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getMissionsQuery, postMissionsMutation } from './queries';
export var missionsApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getMissions: builder.query(getMissionsQuery),
      postMissions: builder.mutation(postMissionsMutation)
    };
  }
});
missionsApi.enhanceEndpoints({
  addTagTypes: ['Missions'],
  keepUnusedDataFor: 10
});
var useGetMissionsQuery = missionsApi.useGetMissionsQuery,
  useLazyGetMissionsQuery = missionsApi.useLazyGetMissionsQuery,
  usePostMissionsMutation = missionsApi.usePostMissionsMutation,
  _missionsApi$endpoint = missionsApi.endpoints,
  getMissions = _missionsApi$endpoint.getMissions,
  postMissions = _missionsApi$endpoint.postMissions;
export { useGetMissionsQuery, useLazyGetMissionsQuery, usePostMissionsMutation, getMissions, postMissions };