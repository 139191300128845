const styles = {
  peeker: {
    position: 'absolute',
    top: 24,
    right: 16,
    zIndex: 2,
    cursor: 'pointer',
  },
  peekerImage: {
    maxWidth: `1.2rem`,
  },
};

export default styles;
