import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Endpoints } from '../../../api/Constants';
export var postGetInboxMessagesQuery = {
  query: function query() {
    return {
      url: Endpoints.GET_INBOX_MESSAGES,
      method: 'post'
    };
  },
  transformResponse: function transformResponse(response) {
    return response[0];
  },
  providesTags: ['InboxMessages']
};
export var getInboxMessageDetailsQuery = {
  query: function query(id) {
    return {
      url: "".concat(Endpoints.GET_INBOX_MESSAGE_DETAILS).concat(id),
      method: 'get'
    };
  },
  transformResponse: function transformResponse(response) {
    var _response$;
    return (_response$ = response[0]) === null || _response$ === void 0 ? void 0 : _response$.details;
  }
};
export var postCollectMessageRewardsMutation = {
  query: function query(payload) {
    return {
      url: Endpoints.COLLECT_MESSAGE_REWARDS,
      method: 'post',
      body: {
        data: _objectSpread({}, payload)
      }
    };
  },
  transformResponse: function transformResponse(response) {
    return response[0];
  }
};
export var deleteInboxMessagesMutation = {
  query: function query(id) {
    return {
      url: "".concat(Endpoints.DELETE_INBOX_MESSAGE).concat(id),
      method: 'get'
    };
  },
  transformResponse: function transformResponse(response) {
    return response;
  },
  invalidatesTags: ['InboxMessages']
};