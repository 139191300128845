import { Endpoints } from '../../../api/Constants';
export var postAddFavouriteGameMutation = {
  query: function query(itemId) {
    return {
      url: Endpoints.ADD_FAVOURITE,
      method: 'post',
      body: {
        data: {
          game_id: itemId
        }
      }
    };
  },
  transformResponse: function transformResponse(response) {
    return response[0];
  },
  invalidatesTags: ['LobbyItems']
};
export var postRemoveFavouriteGameMutation = {
  query: function query(itemId) {
    return {
      url: Endpoints.REMOVE_FAVOURITE,
      method: 'post',
      body: {
        data: {
          game_id: itemId
        }
      }
    };
  },
  transformResponse: function transformResponse(response) {
    return response[0];
  },
  invalidatesTags: ['LobbyItems']
};