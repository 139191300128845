import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { MISSIONS_STATES, MISSIONS_TYPES } from '../../screens/Missions/constants/missionsInfo';
import { hasExplorerType, hasGrandType } from '../../screens/Missions/utils/missionsPerformance';
import { FILTER_TYPES } from './filterTypes';
var handleDefaultFilterData = function handleDefaultFilterData(item) {
  return item;
};
var handleMissionFilterData = function handleMissionFilterData(mission, filterData) {
  switch (filterData) {
    case MISSIONS_TYPES.EXPLORER:
      return (hasExplorerType(mission.missionType) || hasGrandType(mission.missionType)) && mission.missionStatus !== MISSIONS_STATES.COMPLETED && mission.missionStatus !== MISSIONS_STATES.FINISHED;
    case MISSIONS_TYPES.EXPERT:
      return mission.missionType === MISSIONS_TYPES.EXPERT || mission.missionType === MISSIONS_TYPES.FLASH;
    case MISSIONS_TYPES.BATTLES:
      return mission.missionType === MISSIONS_TYPES.BATTLES;
    default:
      return mission;
  }
};
var handleRewardCenterFilterData = function handleRewardCenterFilterData(reward, filterData) {
  return (reward === null || reward === void 0 ? void 0 : reward.categoryType) === filterData;
};
export var handleFilterData = _defineProperty(_defineProperty(_defineProperty({}, FILTER_TYPES.DEFAULT, handleDefaultFilterData), FILTER_TYPES.MISSIONS, handleMissionFilterData), FILTER_TYPES.REWARD_CENTER_FILTER, handleRewardCenterFilterData);