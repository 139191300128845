import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'shared/assets/style/theme';
import Validators from 'shared/components/Form/InputValidator';
import ValidatorError from 'shared/components/Form/ValidatorError';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import AutoFormInput from '../../../../../../../components/AutoFormInput';
import Button from '../../../../../../../components/Button/Button';
import ThemeContext from 'shared/context/ThemeContext';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default function MemberLoginFormHandler(props) {
  const themeContext = useContext(ThemeContext);
  const { submitHandler, serverResponse, isLoading, queryResult } = props;
  const [fields, setfileds] = useState(defaultMemberLoginValues);
  const { email, password, isValidEmail, isValidPassword, passwordHint } = fields;
  const isValidInput = !isLoading && email && isValidEmail && password && isValidPassword;

  const handleInputChange = field => event => {
    if (event.validated) {
      if (field === 'email') {
        setfileds({ ...fields, isValidEmail: event.validated, email: event.value });
      } else if (field === 'password') {
        if (Validators.LENGTH_GREATER_THAN(event.value, 6)) {
          setfileds({ ...fields, isValidPassword: event.validated, password: event.value, passwordHint: '' });
        } else {
          setfileds({ ...fields, isValidPassword: false, passwordHint: ValidatorError().LENGTH_GREATER_THAN });
        }
      }
    } else {
      if (field === 'email') {
        setfileds({ ...fields, isValidEmail: false });
      } else {
        setfileds({ ...fields, isValidPassword: false });
      }
    }
  };

  useEffect(() => {
    if ((!isValidEmail || !isValidPassword) && !queryResult.isUninitialized) queryResult.reset();
  }, [isValidEmail, isValidPassword, queryResult.isUninitialized]);

  return (
    <>
      <FormRowHolder>
        <FormInputWrapper>
          <AutoFormInput
            customClassName="transparentInput"
            emitInput={handleInputChange('email')}
            value={email}
            label=""
            type="text"
            containerStyle={styles.inputContainer}
            validator={Validators.EMAIL}
            placeholder={getText(TEXT_KEY.EMAIL)}
            trimInput={true}
          />
        </FormInputWrapper>
        <FormInputWrapper>
          <AutoFormInput
            customClassName="transparentInput"
            customPeeker={styles.peeker}
            emitInput={handleInputChange('password')}
            value={password}
            label=""
            type="password"
            containerStyle={styles.inputContainer}
            validator={Validators.NOT_EMPTY}
            placeholder={getText(TEXT_KEY.PASSWORD)}
          />
          {passwordHint && <PasswordHint>{passwordHint}</PasswordHint>}
        </FormInputWrapper>
      </FormRowHolder>
      {serverResponse && isValidEmail && isValidPassword && <ServerResponse>{serverResponse}</ServerResponse>}
      <LoginButtonWrapper>
        <LoginButton
          textStyle={themeContext.LinkAccountForm.MemberLinkAccountLoginButton}
          isValidInput={isValidInput}
          onClick={isValidInput ? submitHandler(email, password) : () => {}}
          label={getText(TEXT_KEY.LOGIN)}
          containerStyle={{ height: '100px' }}
        />
      </LoginButtonWrapper>
    </>
  );
}

const defaultMemberLoginValues = {
  email: '',
  password: '',
  passwordHint: '',
  isValidEmail: false,
  isValidPassword: false,
};

const PasswordHint = styled.div`
  font-size: 12px;
  margin: 0px 12px;
`;

const FormRowHolder = styled.div`
  display: flex;
  width: 100%;
`;

const FormInputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ServerResponse = styled.div`
  color: red;
  margin-top: 1em;
  text-align: center;
`;

const LoginButton = styled(Button).attrs(({ isValidInput }) => ({
  imageSource: isValidInput
    ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)
    : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED),
}))`
  cursor: pointer;
  font-size: 1.7rem;
  font-weight: 600;
  width: 181px;
  height: 59px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
`;

const LoginButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const styles = {
  inputContainer: {
    flex: 1,
    margin: 10,
    '& input': {
      background: theme.palette.common[33],
      color: 'white',
      border: `1px solid ${theme.palette.common[33]}`,
    },
  },
  peeker: {
    position: 'absolute',
    top: '48%',
    right: 16,
    zIndex: 2,
    cursor: 'pointer',
  },
};

