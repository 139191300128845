import { css } from 'styled-components';

export const HeaderContainer = css`
  border-top-right-radius: 35px;
  border-top-left-radius: 35px;
  background-size: calc(100% + 1px) 100%;
  font-size: 1.8rem;
  font-weight: 600;
`;

export const DialogHeaderHeight = css`
  height: 3.7rem;
`;

export const HeaderHeight = css`
  height: 3.7rem;
  margin-bottom: 3.5rem;
`;

export const HeaderTitleWrapperStyle = css`
  margin-bottom: 6vmin;
  margin-top: 1.5vmin;
  font-size: calc(18px + (30 - 18) * ((100vw - 800px) / (1600 - 800)));
`;

export const DefaultHeaderResultStyle = css`
  margin-bottom: 7vmin;
  font-size: calc(18px + (30 - 18) * ((100vw - 800px) / (1600 - 800)));
`;

export const DialogheaderContainerStyle = css`
  top: -28px;
  height: 3.7rem;
  left: -22px;
  width: calc(100% + 44px);
  background-size: calc(100% + 2px) 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-bottom: -5px;
`;

export const wrapperStyle = css`
  width: calc(99%);
  left: 0.5%;
`;

