import _slicedToArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect, useRef } from 'react';
import { promoSlideChangeEventHandler } from '../utils';
export default function useCreateSliderInterval(_ref) {
  var lobbyPromotions = _ref.lobbyPromotions;
  var _useState = useState(0),
    _useState2 = _slicedToArray(_useState, 2),
    selectedSlide = _useState2[0],
    changeSelectedSlide = _useState2[1];
  var intervalRef = useRef(null);
  var bannerCount = lobbyPromotions === null || lobbyPromotions === void 0 ? void 0 : lobbyPromotions.length;
  var promoItemsMaxIndex = bannerCount - 1;
  var startCarousel = function startCarousel() {
    var intervalId = setInterval(function () {
      changeSelectedSlide(function (slide) {
        return parseInt(slide) + 1 > promoItemsMaxIndex ? 0 : parseInt(slide) + 1;
      });
    }, 5000);
    intervalRef.current = intervalId;
    return function () {
      return clearInterval(intervalId);
    };
  };
  useEffect(function () {
    var intervalEvent = bannerCount > 1 ? startCarousel() : null;
    if (bannerCount === 1) clearInterval(intervalRef.current);
    return function () {
      return bannerCount > 1 ? intervalEvent() : null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerCount]);
  var onDotPress = function onDotPress(dotIndex) {
    return function () {
      promoSlideChangeEventHandler();
      changeSelectedSlide(dotIndex);
      clearInterval(intervalRef.current);
      startCarousel();
    };
  };
  var onSlideGesture = function onSlideGesture(moveRight) {
    if (bannerCount === 1) return;
    promoSlideChangeEventHandler();
    changeSelectedSlide(function (selectedSlide) {
      return moveRight ? selectedSlide - 1 < 0 ? promoItemsMaxIndex : selectedSlide - 1 : selectedSlide + 1 > promoItemsMaxIndex ? 0 : selectedSlide + 1;
    });
    clearInterval(intervalRef.current);
    startCarousel();
  };
  return {
    selectedSlide: selectedSlide,
    bannerCount: bannerCount,
    onDotPress: onDotPress,
    onSlideGesture: onSlideGesture
  };
}