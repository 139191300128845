import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _schemas;
import { Endpoints } from '../../../api/Constants';
import getFbAppId from './getFbAppId';
import getProgressiveJackpots from './getProgressiveJackpots';
import getAssets from './getAssets';
import getMissions from './getMissions';
import postMissions from './postMissions';
import initProperty from './initProperty';
import dailyWheel from './dailyWheel';
import getGameRooms from './getGameRooms';
import postLinkGuestAccount from './postLinkGuestAccount';
import getEvents from './getEvents';
import initRewards from './initRewards';
import getFriendsInvite from './getFriendsInvite';
import getLevelUp from './getLevelUp';
import getPlayerProfile from './getPlayerProfile';
import getCasinoDollarBalance from './getCasinoDollarBalance';
import getToggleSound from './getToggleSound';
import getToggleMusic from './getToggleMusic';
import getDeleteAccount from './getDeleteAccount';
import postUpdatePlayerProfile from './postUpdatePlayerProfile';
import postToggleMarketingEmails from './postToggleMarketingEmails';
import postToggleNotifications from './postToggleNotifications';
import postTriggersNotify from './postTriggersNotify';
import getClaimTimedBonus from './getClaimTimedBonus';
import postSubmitBonusableLink from './postSubmitBonusableLink';
import postCollectWelcomeBonus from './postCollectWelcomeBonus';
var schemas = (_schemas = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_schemas, Endpoints.GET_FB_APP_ID, {
  GET: getFbAppId
}), Endpoints.PROGRESSIVE_JACKPOTS, {
  GET: getProgressiveJackpots
}), Endpoints.ASSETS, {
  GET: getAssets
}), Endpoints.MISSIONS_PLAYER, {
  GET: getMissions,
  POST: postMissions
}), Endpoints.PROPERTIES, {
  GET: initProperty
}), Endpoints.GET_DAILY_WHEEL_INFO, {
  GET: dailyWheel
}), Endpoints.GET_GAME_ROOMS, {
  GET: getGameRooms
}), Endpoints.LINK_GUEST_ACCOUNT_EMAIL, {
  POST: postLinkGuestAccount
}), Endpoints.LINK_GUEST_ACCOUNT_GOOGLE, {
  POST: postLinkGuestAccount
}), Endpoints.LINK_GUEST_ACCOUNT_APPLE, {
  POST: postLinkGuestAccount
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_schemas, Endpoints.LINK_GUEST_ACCOUNT_FACEBOOK, {
  POST: postLinkGuestAccount
}), Endpoints.EVENTS_PLAYER, {
  GET: getEvents
}), Endpoints.INIT_REWARDS, {
  GET: initRewards
}), Endpoints.FRIENDS_INVITE, {
  GET: getFriendsInvite
}), Endpoints.LEVEL_UP, {
  GET: getLevelUp
}), Endpoints.GET_PLAYER_PROFILE, {
  GET: getPlayerProfile
}), Endpoints.GET_CASINO_DOLLAR_BALANCE, {
  GET: getCasinoDollarBalance
}), Endpoints.TOGGLE_SOUND, {
  GET: getToggleSound
}), Endpoints.TOGGLE_MUSIC, {
  GET: getToggleMusic
}), Endpoints.DELETE_PLAYER_ACCOUNT, {
  GET: getDeleteAccount
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_schemas, Endpoints.UPDATE_PLAYER_PROFILE, {
  POST: postUpdatePlayerProfile
}), Endpoints.ACTION_COMMAND_MARKETING_EMAIL, {
  POST: postToggleMarketingEmails
}), Endpoints.ACTION_COMMAND_NOTIFICATION, {
  POST: postToggleNotifications
}), Endpoints.ACTION_COMMAND_TRIGGER, {
  POST: postTriggersNotify
}), Endpoints.CLAIM_TIMED_BONUS, {
  GET: getClaimTimedBonus
}), Endpoints.SUBMIT_BONUSABLE_LINK, {
  POST: postSubmitBonusableLink
}), Endpoints.COLLECT_WELCOME_BONUS, {
  POST: postCollectWelcomeBonus
}));
export var getSchema = function getSchema(id, method) {
  var _schemas$id;
  return (_schemas$id = schemas[id]) === null || _schemas$id === void 0 ? void 0 : _schemas$id[method];
};