import theme from 'shared/assets/style/theme';
import defaultTheme from './defaultTheme';
import Utils from 'shared/utils/index';

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const ScrollBar = {
  ...defaultTheme.ScrollBar,
  backgroundColor: theme.palette.primary[31],
};

const TiTheme = {
  //TODO: Refactor ScrollBar styles
  ...defaultTheme,
  ScrollBar,
  LimitedTimeOffer: {
    ...defaultTheme.LimitedTimeOffer,
    BannerContainer: {
      ...defaultTheme.LimitedTimeOffer.BannerContainer,
      marginBottom: 10,
    },
    BuyButton: {
      ...defaultTheme.LimitedTimeOffer.BuyButton,
      bottom: '6%',
      transform: 'translateX(-1%)',
    },
    BannerContainerText: {
      ...defaultTheme.LimitedTimeOffer.BannerContainerText,
      position: 'relative',
      top: '0rem',
    },
  },
  NavigationBar: {
    ...defaultTheme.NavigationBar,
    LoyaltyCurrencyWrapperStyle: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyWrapperStyle,
      right: `calc(50% - ${windowWidth < 1300 ? 195 : windowWidth < 1400 ? 205 : 270}px)`,
    },
    LoyaltyCurrencyWrapperStyleWithoutCard: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyWrapperStyleWithoutCard,
      right: `calc(50% - ${windowWidth < 1400 ? 215 : 320}px)`,
    },
    LoyaltyCurrencyIconStyle: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyIconStyle,
      height: 60,
      width: 60,
      top: -15,
    },
    TopBar: {
      height: '6rem',
    },
    BarSection: {
      ...defaultTheme.NavigationBar.BarSection,
      paddingBottom: 10,
      maxWidth: Utils.ieVersion() !== 0 ? 1430 : 1600,
    },
  },
  Tier: {
    ...defaultTheme.Tier,
    Card: {
      ...defaultTheme.Tier.Card,
      width: 75,
      top: -1,
    },
    TierWrapper: {
      ...defaultTheme.Tier.TierWrapper,
      marginRight: windowWidth < 1400 ? '75px !important' : '85px !important',
    },
  },
  DefaultTooltip: {
    ...defaultTheme.DefaultTooltip,
    Border: {
      ...defaultTheme.DefaultTooltip.Border,
      stopColor1: '#0c1f3f',
      stopColor2: '#0c1f3f',
    },
    Background: {
      ...defaultTheme.DefaultTooltip.Background,
      stopColor1: '#2b527b',
      stopColor2: '#2b527b',
    },
  },
  BonusLinkMessage: {
    ...defaultTheme.BonusLinkMessage,
    PromoImage: {
      ...defaultTheme.BonusLinkMessage.PromoImage,
      paddingTop: '376px',
    },
    BonusLinkButton: {
      ...defaultTheme.BonusLinkMessage.BonusLinkButton,
      textShadow: `1px 1px 0px ${theme.palette.secondary[0]}, 1px -1px 0px ${theme.palette.secondary[0]}, -1px 1px 0px ${theme.palette.secondary[0]}, -1px -1px 0px ${theme.palette.secondary[0]}`,
    },
    CoinsBonusContainer: {
      ...defaultTheme.BonusLinkMessage.CoinsBonusContainer,
      paddingTop: '376px',
    },
    BonusInfoContainer: {
      ...defaultTheme.BonusLinkMessage.BonusInfoContainer,
      marginBottom: '15px',
    },
    LPBonusContainer: {
      ...defaultTheme.BonusLinkMessage.LPBonusContainer,
      paddingTop: '337px',
    },
    CoinImage: {
      ...defaultTheme.BonusLinkMessage.CoinImage,
      width: '38px',
    },
    BonusText: {
      ...defaultTheme.BonusLinkMessage.BonusText,
      fontSize: '18px',
    },
  },
  OutOfCoin: {
    ...defaultTheme.OutOfCoin,
    QuickBuyOfferDetails: {
      ...defaultTheme.OutOfCoin.QuickBuyOfferDetails,
      bottom: '12.5%',
    },
  },
  Settings: {
    ...defaultTheme.Settings,
    HeaderImage: {
      ...defaultTheme.Settings.HeaderImage,
      height: '6rem',
    },
    HeaderContent: {
      ...defaultTheme.Settings.HeaderContent,
      height: '80%',
    },
    BackArrowContainer: {
      ...defaultTheme.Settings.BackArrowContainer,
      top: '1.5rem',
    },
  },
  OfferDetail: {
    ...defaultTheme.OfferDetail,
    CarouselIndicator: {
      ...defaultTheme.OfferDetail.CarouselIndicator,
      backgroundColor: theme.palette.secondary[2],
      boxShadow: 'inset 0 0 10px black',
      border: 0,
    },
    CarouselIndicatorActive: {
      ...defaultTheme.OfferDetail.CarouselIndicatorActive,
      backgroundColor: theme.palette.primary[31],
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.4)',
      border: 0,
    },
  },
  CreditCardDetails: {
    ...defaultTheme.CreditCardDetails,
    CheckBoxBackgroundImage: {
      ...defaultTheme.CreditCardDetails.CheckBoxBackgroundImage,
      width: '40%',
    },
  },
  LinkAccountForm: {
    ...defaultTheme.LinkAccountForm,
    HeaderImage: {
      ...defaultTheme.LinkAccountForm.HeaderImage,
      height: '6rem',
    },
    HeaderTitle: {
      ...defaultTheme.LinkAccountForm.HeaderTitle,
      fontSize: '1.6rem',
      lineHeight: '72px',
    },
    NeedHelpText: {
      ...defaultTheme.LinkAccountForm.NeedHelpText,
      top: '1.7rem',
    },
    FormContainer: {
      ...defaultTheme.LinkAccountForm.FormContainer,
      margin: '50px',
      marginBottom: '10px',
    },
  },
  RegisterRewardCard: {
    ...defaultTheme.RegisterRewardCard,
    HeaderImage: {
      ...defaultTheme.RegisterRewardCard.HeaderImage,
      height: '6rem',
    },
    HeaderTitle: {
      ...defaultTheme.RegisterRewardCard.HeaderTitle,
      lineHeight: '80px',
    },
    FormDescription: {
      ...defaultTheme.RegisterRewardCard.FormDescription,
      marginTop: '20px',
    },
  },
  DailySpin: {
    ...defaultTheme.DailySpin,
    BottomBarBackgroundWrapperImage: {
      ...defaultTheme.DailySpin.BottomBarBackgroundWrapperImage,
      width: '120%',
      height: '150%',
    },
    MilestoneLoyaltyValue: {
      ...defaultTheme.DailySpin.MilestoneLoyaltyValue,
      marginLeft: -2,
    },
  },
  BottomBar: {
    ...defaultTheme.BottomBar,
    NotificationMail: {
      ...defaultTheme.BottomBar.NotificationMail,
      left: '1.5em',
    },
  },
  MessageDetails: {
    ...defaultTheme.MessageDetails,
    ScrollBar,
  },
  HistoryList: {
    ...defaultTheme.HistoryList,
    ScrollBar,
  },
  OfferList: {
    ...defaultTheme.OfferList,
    ScrollBar,
  },
  MissionsProgress: {
    ...defaultTheme.MissionsProgress,
    TextProgress: {
      ...defaultTheme.MissionsProgress.TextProgress,
      left: windowWidth < 1400 ? 50 : 65,
      top: windowWidth < 1400 ? '-47%' : '-8%',
    },
  },
  LinkAccount: {
    ...defaultTheme.LinkAccount,
    Description: {
      ...defaultTheme.LinkAccount.Description,
      padding: '0 7.5%',
    },
    LinkAccountContainer: {
      ...defaultTheme.LinkAccount.LinkAccountContainer,
      position: 'relative',
    },
    AvailableCards: {
      ...defaultTheme.LinkAccount.AvailableCards,
      position: 'absolute',
      margin: '3.5rem 7rem',
    },
    LightDividerUp: {
      ...defaultTheme.LinkAccount.LightDividerUp,
      display: 'flex',
    },
    LightDividerDown: {
      ...defaultTheme.LinkAccount.LightDividerDown,
      display: 'flex',
    },
  },
  ShopScreen: {
    ...defaultTheme.ShopScreen,
    ShopContainer: {
      ...defaultTheme.ShopScreen.ShopContainer,
      PackagesContainer: {
        ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer,
        padding: '6% 5.5rem 0.65rem',
        CoinItemContainer: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.CoinItemContainer,
          marginTop: windowWidth < 1300 && windowHeight > 850 ? '15px' : '5px',
        },
        CoinItem: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.CoinItem,
          left: windowWidth < 1400 ? '0.8%' : '1%',
          width: '97%',
        },
      },
    },
    SaleTimer: {
      ...defaultTheme.ShopScreen.SaleTimer,
      top: '10%',
    },
  },
  MessagesHeader: {
    ...defaultTheme.MessagesHeader,
    Image: {
      ...defaultTheme.MessagesHeader.Image,
      height: '6rem',
    },
  },
  Start: {
    ...defaultTheme.Start,
    BottomBarFirstLine: {
      ...defaultTheme.Start.BottomBarFirstLine,
      marginTop: 35,
    },
    BottomBarFooterText: {
      ...defaultTheme.Start.BottomBarFooterText,
      marginTop: 0,
      marginBottom: -10,
    },
  },
  CoinItem: {
    ...defaultTheme.CoinItem,
    CoinAmount: {
      ...defaultTheme.CoinItem.CoinAmount,
      fontSize: '22px',
    },
    Extra: {
      ...defaultTheme.CoinItem.Extra,
      fontSize: '1vmin',
      marginTop: 0,
    },
  },
  RewardHome: {
    ...defaultTheme.RewardHome,
    RewardImagePlaceholder: {
      ...defaultTheme.RewardHome.RewardImagePlaceholder,
      borderRadius: '30px',
    },
    RewardImage: {
      ...defaultTheme.RewardHome.RewardImage,
      borderRadius: '30px',
    },
    RewardHoverOverlay: {
      ...defaultTheme.RewardHome.RewardHoverOverlay,
      borderRadius: '30px',
    },
  },
  RewardListItem: {
    ...defaultTheme.RewardListItem,
    RewardImagePlaceholderContainer: {
      ...defaultTheme.RewardListItem.RewardImagePlaceholderContainer,
      borderRadius: '30px',
    },
    OfferCost: {
      ...defaultTheme.RewardListItem.OfferCost,
      height: '11%',
      top: '-28.5%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4px',
      paddingRight: '5%',
      marginBottom: '0.5em',
    },
  },
  SignUp: {
    ...defaultTheme.SignUp,
    ErrorMessage: {
      ...defaultTheme.SignUp.ErrorMessage,
      color: theme.palette.common[1],
    },
  },
  NotificationModal: {
    ...defaultTheme.NotificationModal,
    HeaderContainer: {
      ...defaultTheme.NotificationModal.HeaderContainer,
      height: '25%',
    },
  },
  SideBar: {
    ...defaultTheme.SideBar,
    MenuContainer: {
      ...defaultTheme.SideBar.MenuContainer,
      height: 'calc(100vh - 5rem - 5.5rem)', // 100vh - bottom bar height - top bar height
      borderBottomRightRadius: '8vh',
      borderTopRightRadius: '8vh',
      border: '5px solid #008dc5',
      background: 'linear-gradient(to bottom, #003F57 0%, #00506E 25%, #006D95 50%, #00506E 75%, #003F57 100%)',
    },
    ItemButton: {
      ...defaultTheme.SideBar.ItemButton,
      height: '17.5vh',
    },
    MenuItemText: {
      ...defaultTheme.SideBar.MenuItemText,
      fontSize: '3.1vw',
    },
  },
  Bar: {
    ...defaultTheme.Bar,
    BarContainer: {
      ...defaultTheme.Bar.BarContainer,
      background: 'linear-gradient(180deg, rgba(84, 85, 85, 1) 0%, rgba(143, 143, 143, 1) 100%)',
    },
    BarProgress: {
      ...defaultTheme.Bar.BarProgress,
      background: `linear-gradient(
        180deg, 
        rgba(237, 28, 143, 1) 0%, 
        rgba(244, 134, 243, 1) 50%, 
        rgba(237, 28, 143, 1) 100%
      )`,
    },
  },
  TimeRemaining: {
    ...defaultTheme.TimeRemaining,
    VIPTimer: {
      ...defaultTheme.TimeRemaining.VIPTimer,
      top: '5.5rem',
    },
  },
  PurchaseFlow: {
    ...defaultTheme.PurchaseFlow,
    billingProfileSelect: {
      ...defaultTheme.PurchaseFlow.billingProfileSelect,
      border: `1px solid #5a91af`,
    },
  },
  BillingProfile: {
    ...defaultTheme.BillingProfile,
    AddDeleteProfileText: {
      ...defaultTheme.BillingProfile.AddDeleteProfileText,
      border: `4px solid ${theme.palette.primary[31]}`,
    },
  },
};

export default TiTheme;

