import React from 'react';
import styled from 'styled-components';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default function SliderDot({ selected, size, onPress, index }) {
  return (
    <SliderDotWrapper onClick={onPress(index)}>
      <DotImage size={size} selected={selected} />
    </SliderDotWrapper>
  );
}

const SliderDotWrapper = styled.div`
  margin: 0px 0px;
  cursor: pointer;
`;

const DotImage = styled.img.attrs(({ selected }) => ({
  src: selected ? assetSource(ASSET_KEY.DOT_SELECTED) : assetSource(ASSET_KEY.DOT_UNSELECTED),
}))`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  @media only screen and (max-height: 790px) {
    width: ${({ size }) => size * 0.8}px;
    height: ${({ size }) => size * 0.8}px;
  }
`;

