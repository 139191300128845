import { useCallback } from 'react';

function useCustomScrollBar({ arrowClickScrollAmount = 10, scrollBoxRef }) {
  const smoothScroll = (element, target, duration = 250) => {
    let start = element.scrollLeft;
    let change = target - start;
    let startTime = null;

    const animateScroll = currentTime => {
      if (!startTime) startTime = currentTime;
      let timeElapsed = currentTime - startTime;
      let progress = Math.min(timeElapsed / duration, 1);

      element.scrollLeft = start + change * progress;

      if (timeElapsed < duration) {
        window.requestAnimationFrame && window.requestAnimationFrame(animateScroll);
      }
    };

    window.requestAnimationFrame && window.requestAnimationFrame(animateScroll);
  };

  const handleArrowScrollClick = useCallback(
    direction => {
      if (scrollBoxRef.current) {
        const scrollDelta = direction === 'left' ? -arrowClickScrollAmount : arrowClickScrollAmount;
        let newScrollPosition = scrollBoxRef.current.scrollLeft + scrollDelta;
        smoothScroll(scrollBoxRef.current, newScrollPosition);
      }
    },
    [arrowClickScrollAmount, scrollBoxRef]
  );

  return {
    smoothScroll,
    handleArrowScrollClick,
  };
}

export default useCustomScrollBar;

