import React, { useContext } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { lightAssets } from 'shared/screens/DailySpin/utils';
import ThemeContext from 'shared/context/ThemeContext';
import ImageBW from '../../../../../components/ImageBoundsWrapper';
import {
  StreakColumnStyles,
  RegDaysRow,
  RegDaysLightImageLeft,
  RegDaysLightImageRight,
  RegDaysStreakDayStar,
  CurrentDayStreakLayout,
  RegDayRowContentWrapper,
  RegDaysItemText,
  RegDaysItemWrapperStar,
  RegDaysItemCoinIcon,
  RegDaysItemWrapperCenter,
  RegDaysItemWrapperRight,
  RegDaysItemWrapperInnerTextStar,
  RegDaysItemWrapperInnerIconStar,
  EmptyDivSpace,
} from '../../styledComponents';

export default function StreakDaysRowStar(props) {
  const themeContext = useContext(ThemeContext);
  const { item, index, day, bonusInfo, dailyWheelAssets, enableLoyalty } = props;
  const opacity = bonusInfo?.CURRENT_REWARD_DAY >= day + 1 ? 0.25 : 1;
  const light = lightAssets(dailyWheelAssets);

  return (
    <React.Fragment>
      <RegDaysRow>
        {bonusInfo?.CURRENT_REWARD_DAY === day && <CurrentDayStreakLayout index={index} />}
        <RegDaysLightImageLeft src={light[bonusInfo?.CURRENT_REWARD_DAY >= day ? index + 1 : 0]} alt="" />
        <RegDaysLightImageRight src={light[bonusInfo?.CURRENT_REWARD_DAY >= day ? index + 1 : 0]} alt="" />
        <RegDaysStreakDayStar length={String(day).length} opacity={opacity}>
          {`${day}${day > 100 ? '+' : ''}`}
        </RegDaysStreakDayStar>
        <RegDayRowContentWrapper>
          <RegDaysItemWrapperStar center={!enableLoyalty}>
            <RegDaysItemWrapperInnerIconStar>
              <RegDaysItemCoinIcon opacity={opacity} src={icons.coin} alt="coins" />
            </RegDaysItemWrapperInnerIconStar>
            <RegDaysItemWrapperInnerTextStar flex={enableLoyalty ? 2 : 1.2}>
              <RegDaysItemText
                theme={themeContext.DailySpin.StreakDaysRowValues}
                data-label={item.coins}
                opacity={opacity}
              >
                {item.coins}
              </RegDaysItemText>
            </RegDaysItemWrapperInnerTextStar>
          </RegDaysItemWrapperStar>
          {enableLoyalty && item.loyalty && item.loyalty !== '0' ? (
            <>
              <RegDaysItemWrapperCenter opacity={opacity}>{getText(TEXT_KEY.PLUS_SYMBOL)}</RegDaysItemWrapperCenter>
              <RegDaysItemWrapperRight>
                <EmptyDivSpace flex={0.2} />
                <RegDaysItemWrapperInnerIconStar paddingright={5}>
                  <ImageBW
                    source={icons.loyalty}
                    ibw={require(asset`loyalty-icon.ibw`)}
                    resizeMode="contain"
                    style={{
                      ...themeContext.DailySpin.RegDaysItemLoyaltyIcon,
                      opacity: opacity,
                    }}
                  />
                </RegDaysItemWrapperInnerIconStar>
                <RegDaysItemWrapperInnerTextStar>
                  <RegDaysItemText
                    theme={themeContext.DailySpin.StreakDaysRowValues}
                    data-label={item.loyalty}
                    opacity={opacity}
                  >
                    {item.loyalty}
                  </RegDaysItemText>
                </RegDaysItemWrapperInnerTextStar>
              </RegDaysItemWrapperRight>
            </>
          ) : (
            <EmptyDivSpace flex={enableLoyalty ? 1.3 : 0} />
          )}
        </RegDayRowContentWrapper>
      </RegDaysRow>
      {index < 8 && (
        <img src={dailyWheelAssets.DAILY_SPIN_STREAK_ROW_DIVIDER} alt="" style={StreakColumnStyles.rowDivider} />
      )}
    </React.Fragment>
  );
}

const icons = {
  coin: assetSource(ASSET_KEY.COIN_ICON),
  loyalty: assetSource(ASSET_KEY.LOYALTY_ICON),
};

