import theme from 'shared/assets/style/theme';
import defaultTheme from './defaultTheme';

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const StarTheme = {
  ...defaultTheme,
  LimitedTimeOffer: {
    ...defaultTheme.LimitedTimeOffer,
    LTOWrapper: {
      ...defaultTheme.LimitedTimeOffer.LTOWrapper,
      height: '82%',
    },
    BuyButton: {
      ...defaultTheme.LimitedTimeOffer.BuyButton,
      bottom: '11%',
      transform: 'translateX(2px)',
    },
    BuyButtonImageStyle: {
      ...defaultTheme.LimitedTimeOffer.BuyButtonImageStyle,
      width: windowHeight > 900 && windowWidth < 1300 ? '22vh' : '28vh',
    },
    BannerContainerText: {
      ...defaultTheme.LimitedTimeOffer.BannerContainerText,
      position: 'relative',
      top: '-0.4rem',
    },
  },
  SideBar: {
    ...defaultTheme.SideBar,
    MenuItem: {
      ...defaultTheme.SideBar.MenuItem,
      padding: `0 0 0 ${windowWidth < 1400 ? 3 : 4}rem`,
    },
  },
  TimeRemaining: {
    ...defaultTheme.TimeRemaining,
    TimerLabel: {
      ...defaultTheme.TimeRemaining.TimerLabel,
      top: '-0.5rem',
    },
    VIPTimer: {
      ...defaultTheme.TimeRemaining.VIPTimer,
      top: '5rem',
    },
  },
  BottomBar: {
    ...defaultTheme.BottomBar,
    NotificationText: {
      ...defaultTheme.BottomBar.NotificationText,
      paddingTop: '15%',
    },
    NotificationEvents: {
      ...defaultTheme.BottomBar.NotificationEvents,
      paddingTop: '0.83rem',
    },
  },
  DefaultTooltip: {
    ...defaultTheme.DefaultTooltip,
    Border: {
      ...defaultTheme.DefaultTooltip.Border,
      stopColor1: '#995edc',
      stopColor2: '#995edc',
    },
    Background: {
      ...defaultTheme.DefaultTooltip.Background,
      stopColor1: '#210043',
      stopColor2: '#210043',
    },
  },
  PurchaseFlow: {
    ...defaultTheme.PurchaseFlow,
    CreditCardDetails: {
      ...defaultTheme.PurchaseFlow.CreditCardDetails,
      display: 'none',
    },
    billingProfileSelect: {
      ...defaultTheme.PurchaseFlow.billingProfileSelect,
      borderBottom: `2px solid ${theme.palette.secondary[0]}`,
    },
  },
  CreditCardDetails: {
    ...defaultTheme.CreditCardDetails,
    CheckBoxContainer: {
      ...defaultTheme.CreditCardDetails.CheckBoxContainer,
      marginRight: '1em',
    },
  },
  VipAccessFlow: {
    ...defaultTheme.VipAccessFlow,
    VipAccessButtonText: {
      ...defaultTheme.VipAccessFlow.VipAccessButtonText,
      paddingBottom: 0,
      fontSize: 22,
      width: '98%',
    },
    VipAccessRedeemButtonText: {
      ...defaultTheme.VipAccessFlow.VipAccessRedeemButtonText,
      paddingBottom: 0,
      fontSize: 22,
      width: '98%',
    },
  },
  RewardListItem: {
    ...defaultTheme.RewardListItem,
    OfferNameText: {
      ...defaultTheme.RewardListItem.OfferNameText,
      paddingTop: 4,
    },
    OfferCost: {
      ...defaultTheme.RewardListItem.OfferCost,
      paddingRight: '4%',
    },
  },
  CongratulationsContainer: {
    ...defaultTheme.CongratulationsContainer,
    CongratsSpan: {
      ...defaultTheme.CongratulationsContainer.CongratsSpan,
      textShadow: `${theme.palette.primary[16]} 1px 1px 4px`,
    },
  },
  NavigationBar: {
    ...defaultTheme.NavigationBar,
    LoyaltyCurrencyWrapperStyle: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyWrapperStyle,
      right: 'calc(50% - 200px)',
    },
    LoyaltyCurrencyIconStyle: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyIconStyle,
      height: 55,
      width: 55,
    },
    LoyaltyCurrencyIconStyleWithMissionsbar: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyIconStyleWithMissionsbar,
      marginLeft: windowWidth < 1300 ? '-8px' : '2px',
    },
    BuyDealText: {
      ...defaultTheme.NavigationBar.BuyDealText,
      top: 0,
    },
    FullButton: {
      ...defaultTheme.NavigationBar.FullButton,
      top: '-0.25rem',
    },
  },
  DailySpin: {
    ...defaultTheme.DailySpin,
    WheelItems: {
      ...defaultTheme.DailySpin.WheelItems,
      fontSize: windowWidth < 1400 ? '1.8rem' : '2rem',
    },
    StreakDaysRowNumber: {
      ...defaultTheme.DailySpin.StreakDaysRowNumber,
      paddingRight: 1,
      paddingTop: 4,
      paddingBottom: 2,
    },
    StreakDaysRowBigNumber: {
      ...defaultTheme.DailySpin.StreakDaysRowBigNumber,
      paddingRight: 1,
      paddingTop: 4,
      paddingBottom: 2,
    },
    StreakDaysRowValues: {
      ...defaultTheme.DailySpin.StreakDaysRowValues,
      lineHeight: 0,
    },
    MilestoneCoinsValue: {
      ...defaultTheme.DailySpin.MilestoneCoinsValue,
      marginBottom: 0,
    },
    MilestoneLoyaltyValue: {
      ...defaultTheme.DailySpin.MilestoneLoyaltyValue,
      marginBottom: -7,
    },
    MilestoneCircleValue: {
      ...defaultTheme.DailySpin.MilestoneCircleValue,
      marginLeft: 0,
      marginTop: windowWidth < 1300 ? '1%' : windowWidth < 1500 ? '3%' : '1%',
    },
    BottomRightCoinImage: {
      ...defaultTheme.DailySpin.BottomRightCoinImage,
      marginBottom: 5,
    },
    DailyWheelCollectButton: {
      ...defaultTheme.DailySpin.DailyWheelCollectButton,
      paddingBottom: 0,
    },
  },
  Settings: {
    ...defaultTheme.Settings,
    TitleShadow: {
      ...defaultTheme.Settings.TitleShadow,
      display: 'none',
    },
    TitleMargin: {
      ...defaultTheme.Settings.TitleMargin,
      marginBottom: '30px',
    },
    HeaderImage: {
      ...defaultTheme.Settings.HeaderImage,
      height: '3.7rem',
      width: '100%',
      objectFit: 'contain',
      zIndex: 11,
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  Tier: {
    ...defaultTheme.Tier,
    Card: {
      ...defaultTheme.Tier.Card,
      height: 60,
      width: 82,
    },
    TierWrapper: {
      ...defaultTheme.Tier.TierWrapper,
      marginRight: windowWidth < 1400 ? '85px !important' : '103px !important',
    },
    CardAnimation: {
      ...defaultTheme.Tier.CardAnimation,
      left: -30,
    },
  },
  CoinItem: {
    ...defaultTheme.CoinItem,
    CoinAmount: {
      ...defaultTheme.CoinItem.CoinAmount,
      fontSize: '25px',
    },
    Extra: {
      ...defaultTheme.CoinItem.Extra,
      fontSize: '0.9vmin',
      marginTop: 0,
    },
  },
  MissionsProgress: {
    ...defaultTheme.MissionsProgress,
    TimeRemainingTextWithoutCard: {
      ...defaultTheme.MissionsProgress.TimeRemainingTextWithoutCard,
      top: 45,
    },
    FillProgressWithoutCard: {
      ...defaultTheme.MissionsProgress.FillProgressWithoutCard,
      marginLeft: '-25px',
    },
    FillProgress: {
      ...defaultTheme.MissionsProgress.FillProgress,
      marginTop: windowWidth < 1400 ? '-22px' : '-17px',
      marginLeft: windowWidth < 1400 ? '-17px' : '-25px',
    },
    FillEnd: {
      ...defaultTheme.MissionsProgress.FillEnd,
      marginTop: windowWidth < 1400 ? '-22px' : '-17px',
    },
    ProgressAnimation: {
      ...defaultTheme.MissionsProgress.ProgressAnimation,
      bottom: windowWidth < 1400 ? '-155%' : '-77%',
    },
    ProgressAnimationWithoutCard: {
      ...defaultTheme.MissionsProgress.ProgressAnimationWithoutCard,
      bottom: '-77%',
    },
    TextProgress: {
      ...defaultTheme.MissionsProgress.TextProgress,
      marginBottom: '-4px',
    },
  },
  Account: {
    ...defaultTheme.Account,
    Label: {
      fontWeight: 400,
    },
  },
  BonusLinkMessage: {
    ...defaultTheme.BonusLinkMessage,
    LPBonusContainer: {
      ...defaultTheme.BonusLinkMessage.LPBonusContainer,
      paddingTop: '360px',
    },
    CoinsBonusContainer: {
      ...defaultTheme.BonusLinkMessage.CoinsBonusContainer,
      paddingTop: '370px',
    },
  },
  OfferDetail: {
    ...defaultTheme.OfferDetail,
    CarouselIndicator: {
      ...defaultTheme.OfferDetail.CarouselIndicator,
      backgroundColor: theme.palette.primary[0],
      border: 0,
    },
    CarouselIndicatorActive: {
      ...defaultTheme.OfferDetail.CarouselIndicatorActive,
      background: `linear-gradient(
        to bottom, 
        rgba(176, 140, 75, 1) 0%,  
        rgba(234, 211, 163, 1) 35%, 
        rgba(234, 211, 163, 1) 65%, 
        rgba(176, 140, 75, 1) 100%
      )`,
      border: 0,
    },
    RewardCenterPurchaseResponseMessage: {
      ...defaultTheme.OfferDetail.RewardCenterPurchaseResponseMessage,
      marginTop: 0,
      height: '16%',
      marginBottom: '1rem',
      fontSize: '1.2em',
    },
  },
  Bar: {
    ...defaultTheme.Bar,
    BarContainer: {
      ...defaultTheme.Bar.BarContainer,
      background: 'linear-gradient(180deg, rgba(9, 1, 22, 1) 0%, rgba(49, 33, 76, 1) 100%)',
    },
    BarProgress: {
      ...defaultTheme.Bar.BarProgress,
      background: 'linear-gradient(to bottom right, rgb(202, 75, 255) 0%, rgb(202, 75, 255) 60%)',
    },
  },
  BillingProfile: {
    ...defaultTheme.BillingProfile,
    AddDeleteProfileText: {
      ...defaultTheme.BillingProfile.AddDeleteProfileText,
      border: `2px solid ${theme.palette.secondary[0]}`,
    },
  },
};

export default StarTheme;

