import React, { Component } from 'react';
import SCheatMenu from 'shared/screens/CheatMenu';
import ImageBW from '../../components/ImageBoundsWrapper';
import modalComponents from '../../components/modals';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default class CheatMenu extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-cheatMenu',
    };
  }

  getSwitch = (handle, value) => {
    return (
      <>
        <div style={{ marginTop: 8 }}>
          <label className="custom-switch" style={{ marginBottom: 0, marginLeft: 8 }}>
            <>
              <input type="checkbox" checked={value} readOnly />
              <span className="slider" onClick={handle} />
            </>
          </label>
        </div>
      </>
    );
  };

  render() {
    return (
      <SCheatMenu
        navigation={this.props.navigation}
        dismissModal={this.props.dismissModal}
        render={args => {
          const { modal, gameId, handleModalChange, handleGoToModalClick, handleGameIdChange, handleTestGame } = args;

          return (
            <>
              <div className="header">
                <ImageBW
                  source={assetSource(ASSET_KEY.FULL_MODAL_HEADER)}
                  ibw={require(asset`full-modal-header.ibw`)}
                  className="headerImage"
                >
                  <div className="headerContent">
                    <span className="headerTitle" data-label="CHEAT MENU">
                      {getText(TEXT_KEY.CHEAT_MENU)}
                    </span>
                  </div>
                </ImageBW>
              </div>
              <div className="content">
                <div className="item">
                  <div className="itemContent">
                    <span className="itemLabel" data-label="Open game with game id">
                      {getText(TEXT_KEY.OPEN)}
                    </span>
                    <input
                      className="testGameInputField"
                      type="text"
                      value={gameId > 0 ? gameId : null}
                      maxLength="50"
                      onChange={e => handleGameIdChange(e.target.value)}
                      placeholder="Type the game id"
                    />
                  </div>
                  <div className="itemButton" onClick={handleTestGame}>
                    <ImageBW
                      source={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                      ibw={require(asset`rounded-rect-primary.ibw`)}
                      style={{ height: '100%', width: '100%' }}
                      innerStyle={{ cursor: 'pointer', width: '100%', height: '100%' }}
                    >
                      <span data-label="OPEN">{getText(TEXT_KEY.OPEN_BUTTON)}</span>
                    </ImageBW>
                  </div>
                </div>
                <div className="divider">
                  <ImageBW
                    style={{ width: '90%', height: '0.2rem', zIndex: 500 }}
                    source={assetSource(ASSET_KEY.SETTINGS_DIVIDER)}
                    ibw={require(asset`./images/settings/settings-divider.ibw`)}
                    widthScale={0.5}
                  />
                </div>
                <div className="item">
                  <div className="itemContent">
                    <span className="itemLabel" data-label="Select Modal">
                      {getText(TEXT_KEY.SELECT_MODAL)}
                    </span>
                    <select onChange={event => handleModalChange(event.target.value)} value={modal}>
                      <option value="">{getText(TEXT_KEY.SELECT_OPTION)}</option>
                      {Object.keys(modalComponents).map(modalName => (
                        <option key={modalName} value={modalName}>
                          {modalName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="itemButton" onClick={handleGoToModalClick}>
                    <ImageBW
                      source={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                      ibw={require(asset`rounded-rect-primary.ibw`)}
                      style={{ height: '100%', width: '100%' }}
                      innerStyle={{ cursor: 'pointer', width: '100%', height: '100%' }}
                    >
                      <span data-label="GO">{getText(TEXT_KEY.GO)}</span>
                    </ImageBW>
                  </div>
                </div>
                <div className="divider">
                  <ImageBW
                    style={{ width: '90%', height: '0.2rem', zIndex: 500 }}
                    source={assetSource(ASSET_KEY.SETTINGS_DIVIDER)}
                    ibw={require(asset`./images/settings/settings-divider.ibw`)}
                    widthScale={0.5}
                  />
                </div>
                <div className="divider">
                  <ImageBW
                    style={{ width: '90%', height: '0.2rem', zIndex: 500 }}
                    source={assetSource(ASSET_KEY.SETTINGS_DIVIDER)}
                    ibw={require(asset`./images/settings/settings-divider.ibw`)}
                    widthScale={0.5}
                  />
                </div>
              </div>
            </>
          );
        }}
      />
    );
  }
}
