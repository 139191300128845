export var HTTP_REQUEST = {
  AXIOS: 'AXIOS'
};
export var METRICS = {
  REQUEST_SENT: 'REQUEST_SENT',
  RESPONSE_RECEIVED: 'RESPONSE_RECEIVED',
  RESTART_SESSION: 'RESTART_SESSION',
  REQUEST_CATCH: 'REQUEST_CATCH'
};
export var HEADER = {
  SESSION_ID: 'X-BBG-sessionId',
  REQUEST_ID: 'X-BBG-requestId',
  LANGUAGE: 'Accept-Language',
  CONTENT_TYPE: 'Content-Type'
};
export var HEADER_VALUE = {
  HEADER_APPLICATION_JSON: 'application/json'
};
export var HTTP_STATUS = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500
};