import styled from 'styled-components';
import ImageBW from '../../components/ImageBoundsWrapper';

const DefaultBackgroundImage = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

const SocialModalStyle = {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  padding: '0 0',
  width: '100%',
};

const ModalMainBodyStyle = {
  width: '700px',
};

const ModalContainer = styled.div`
  height: 40vh;
  margin-bottom: -1vw;
  margin-top: -1vw;
  width: 100%;
`;

const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  padding: 12% 8% 6% 8%;
  width: 100%;
`;

const HeaderContainer = styled(ImageBW)`
  height: 18%;
  left: 0;
  object-fit: contain;
  top: 0;
  width: 100%;
  z-index: 11;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const HeaderContnet = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const HeaderItem = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: ${({ justify }) => justify || 'center'};
`;

const Message = styled.p`
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  padding-bottom: 1rem;
  text-align: center;
`;

const NeedsHelpText = styled.p`
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  padding-right: 15px;
  cursor: pointer;
  margin: 0 !important;
`;

export {
  DefaultBackgroundImage,
  HeaderContainer,
  HeaderItem,
  Message,
  ModalContainer,
  ModalContent,
  ModalMainBodyStyle,
  SocialModalStyle,
  HeaderContnet,
  NeedsHelpText,
};

