import DefaultFormInputs from './DefaultFormInputs';
import TiFormInputs from './TiFormInputs';
import MillelacsFormInput from './MillelacsFormInputs';
import generateRender from 'shared/utils/generateRender';

const FormInputs = generateRender({
  default: DefaultFormInputs,
  ti: TiFormInputs,
  millelacs: MillelacsFormInput,
  jamul: TiFormInputs,
});

export default FormInputs;

