import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const AccessDenied = ({ canEnterRoom, firstTimeAccess }) => {
  const imageDescriptions = [
    {
      condition: !canEnterRoom,
      source: assetSource(ASSET_KEY.VIP_PASS),
      textKey: TEXT_KEY.CHOOSE_PASS,
    },
    {
      condition: canEnterRoom && firstTimeAccess,
      source: assetSource(ASSET_KEY.CHECKMARK),
      textKey: TEXT_KEY.AGREE_TCS,
    },
    {
      source: assetSource(ASSET_KEY.QUEST20_LOCK),
      textKey: TEXT_KEY.GAIN_INSTANT_ACCESS,
    },
    {
      source: assetSource(ASSET_KEY.SLOT_COINS),
      textKey: TEXT_KEY.PLAY_50_GAMES,
    },
  ];
  return (
    <>
      <div className="Overlay">
        <div className="Title">{getText(TEXT_KEY.VIP_GAMES_TITLE)}</div>
        <div className="Container">
          {imageDescriptions.map(
            ({ condition = true, source, textKey }, index) =>
              condition && (
                <div className="Column">
                  <img src={source} alt={'VIP Pass'} className="Icon" />
                  <p>{getText(textKey)}</p>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default AccessDenied;

