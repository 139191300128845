import store from '../../state/store';
import { imageSource } from '../../manifests/imageSource';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import { FE_ASSETS_CDN } from '../featureFlags/constants';
export var assetSource = function assetSource(key) {
  var _store$getState$asset;
  var _useFeatureFlags = useFeatureFlags(),
    hasFeature = _useFeatureFlags.hasFeature;
  var assetsManager = store === null || store === void 0 || (_store$getState$asset = store.getState().assetsPackage) === null || _store$getState$asset === void 0 || (_store$getState$asset = _store$getState$asset.assets) === null || _store$getState$asset === void 0 ? void 0 : _store$getState$asset.LOCALIZATION;
  return assetsManager && key in assetsManager && hasFeature(FE_ASSETS_CDN) ? assetsManager[key] : imageSource[key];
};