import { useEffect, useRef } from 'react';

function useScrollToCard({ scrollBoxRef, scrollOffset = 0, scrollOnMount = false, horizontalScroll = true }) {
  const elementRef = useRef(null);

  useEffect(() => {
    const scrollPosition = horizontalScroll ? elementRef?.current.offsetLeft : elementRef?.current.offsetTop;
    if (scrollOnMount && scrollBoxRef?.current) scrollBoxRef.current.scrollLeft = scrollPosition + scrollOffset;
  }, [elementRef, scrollBoxRef.current, scrollOnMount, scrollOffset, horizontalScroll]);

  return {
    elementRef,
  };
}

export default useScrollToCard;

