import generateRender from 'shared/utils/generateRender';
import styled from 'styled-components';
import theme from 'shared/assets/style/theme';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const FormWrapperDefault = styled.div`
  max-height: 70vh;
  width: 90%;
  max-width: 800px;

  & input {
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

const FormWrapperMillelacs = styled.div`
  height: 100%;
  width: 90%;
  max-width: 800px;

  & input {
    background: ${theme.palette.primary[32]};
    color: white;
    border: none !important;
    border-radius: 0.6em;

    &:focus {
      background: ${theme.palette.primary[32]};
      color: white;
      border: none !important;
      border-radius: 0.6em;
    }
  }
`;

const FormWrapperJamul = styled.div`
  height: 100%;
  width: 90%;
  max-width: 800px;

  & input {
    background: ${theme.palette.primary[32]};
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
    border-radius: 0.6em;

    &:focus {
      background: ${theme.palette.primary[32]};
      color: white;
      border: 1px solid rgba(255, 255, 255, 0.3) !important;
      border-radius: 0.6em;
    }
  }
`;

export const FormWrapper = generateRender({
  default: FormWrapperDefault,
  millelacs: FormWrapperMillelacs,
  jamul: FormWrapperJamul,
});

export const styles = {
  modal: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  submitBtn: {
    backgroundImage: `url(${assetSource(ASSET_KEY.ROUNDED_RECT_SECONDARY)}`,
    cursor: 'pointer',
    fontSize: '1.7rem',
    fontWeight: '600',
    width: 181,
    height: 59,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelBtn: {
    backgroundImage: `url(${assetSource(ASSET_KEY.ORANGE_BUTTON)}`,
    cursor: 'pointer',
    fontSize: '1.7rem',
    fontWeight: '600',
    width: 181,
    height: 59,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBodyStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '0 2vmin',
  },
};

