import React from 'react';
import CustomScrollBar from '../../../../../components/CustomScrollBar';
import { HistoryTable, HistoryTableHeaderContainer, HistoryTableBody } from './styledComponents';
import HistoryItem from './HistoryItem';
import HistoryListHeader from './HistoryListHeader';
import './scrollBarStyles.scss';

const HistoryList = ({ history, setActiveHistoryItem, rewardCenterAssets }) => {
  const displayDetailsColumn = history.some(item => item.showDetailsButton === true);

  return (
    <>
      <HistoryTable>
        <HistoryTableHeaderContainer background={rewardCenterAssets.REWARD_CENTER_MENU_HISTORY_BANNER}>
          <HistoryListHeader displayDetailsColumn={displayDetailsColumn} />
        </HistoryTableHeaderContainer>
        <HistoryTableBody>
          <CustomScrollBar
            containerStyle={{
              marginTop: '0.5rem',
              width: '100%',
            }}
            customClassNames="custom-scrollbar-history-list"
          >
            {history.map((item, idx) => (
              <HistoryItem
                key={`HistoryItem-${item.rpid}`}
                item={item}
                displayDetailsColumn={displayDetailsColumn}
                setActiveHistoryItem={setActiveHistoryItem}
                index={idx}
              />
            ))}
          </CustomScrollBar>
        </HistoryTableBody>
      </HistoryTable>
    </>
  );
};

export default HistoryList;

