import { SET_GAME_ID, SET_GAME_TYPE, SET_GAME_PROVIDER, SET_NEW_GAMES, SET_FEATURED_GAMES, SET_SLOT_GAMES, SET_TABLE_GAMES, SET_VIP_GAMES, SET_FAVOURITE_GAMES, SET_GAME_STATE, SET_LAST_GAME_VISITED, SET_GAME_INFO, SET_SPORTS_GAMES } from './types';
export var setGameId = function setGameId(gameId) {
  return {
    type: SET_GAME_ID,
    payload: gameId
  };
};
export var setLastGameVisited = function setLastGameVisited(gameId) {
  return {
    type: SET_LAST_GAME_VISITED,
    payload: gameId
  };
};
export var setGameType = function setGameType(gameType) {
  return {
    type: SET_GAME_TYPE,
    payload: gameType
  };
};
export var setGameProvider = function setGameProvider(provider) {
  return {
    type: SET_GAME_PROVIDER,
    payload: provider
  };
};
export var setGameState = function setGameState(gameState) {
  return {
    type: SET_GAME_STATE,
    payload: gameState
  };
};
export var setNewGames = function setNewGames(gameList) {
  return {
    type: SET_NEW_GAMES,
    payload: gameList
  };
};
export var setFeaturedGames = function setFeaturedGames(gameList) {
  return {
    type: SET_FEATURED_GAMES,
    payload: gameList
  };
};
export var setSlotGames = function setSlotGames(gameList) {
  return {
    type: SET_SLOT_GAMES,
    payload: gameList
  };
};
export var setTableGames = function setTableGames(gameList) {
  return {
    type: SET_TABLE_GAMES,
    payload: gameList
  };
};
export var setVipGames = function setVipGames(gameList) {
  return {
    type: SET_VIP_GAMES,
    payload: gameList
  };
};
export var setSportsGames = function setSportsGames(gameList) {
  return {
    type: SET_SPORTS_GAMES,
    payload: gameList
  };
};
export var setFavouriteGames = function setFavouriteGames(gameList) {
  return {
    type: SET_FAVOURITE_GAMES,
    payload: gameList
  };
};
export var setGameInfo = function setGameInfo(gameInfo) {
  return {
    type: SET_GAME_INFO,
    payload: gameInfo
  };
};