import React, { useReducer, useEffect } from 'react';
import styled from 'styled-components';
import { Image, Spinner } from 'react-bootstrap';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import SStart from 'shared/screens/Start';
import TermsModal from '../../components/TermsModal';
import audio from 'shared/utils/audio';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import StarTCs from '../../components/TermsAndConditions/TCStar';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import SocialLoginInterface from 'shared/api/SocialLoginInterface';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const Star = props => {
  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      oauthLoading: false,
      statusMessage: null,
      emailPermissionAccepted: null,
      socialError: null,
      socialErrorType: null,
      error: null,
      playAsGuest: ENVIRONMENT_VARIABLES.PLAY_AS_GUEST,
      isGoogleSignIn: false,
    }
  );

  useEffect(() => {
    if (props.navigation.getParam(RouteParamConstants.PARAM_ACTION)) {
      _handleActionRedirect();
    }
    if (props.navigation.getParam(RouteParamConstants.PARAM_TRACKING_URL_AFF_ID)) {
      let aff_Id = props.navigation.getParam(RouteParamConstants.PARAM_TRACKING_URL_AFF_ID);
      Array.isArray(aff_Id) ? props.setUserAffId(aff_Id[0]) : props.setUserAffId(aff_Id);
    }
  }, []);

  const _handleActionRedirect = () => {
    window.location.assign(props.navigation.getParam(RouteParamConstants.PARAM_ACTION));
  };

  useEffect(() => {
    props.googleToken &&
      SocialLoginInterface.googleLoginHandler({
        setSuccessState: setSuccessState,
        setErrorState: setErrorState,
        setCanceledState: setCanceledState,
      });
  }, [props.googleToken]);

  const setSuccessState = () => {
    setState({
      oauthLoading: true,
      statusMessage: null,
      socialError: null,
      socialErrorType: null,
      error: null,
    });
  };

  const setErrorState = () => {
    setState({
      oauthLoading: false,
      statusMessage: null,
      socialError: null,
      socialErrorType: null,
      error: true,
    });
  };

  const setCanceledState = ({ statusMessage, emailPermissionAccepted, socialErrorType }) => {
    setState({
      oauthLoading: false,
      statusMessage,
      emailPermissionAccepted,
      socialError: true,
      socialErrorType,
      error: null,
    });
  };

  const closeModal = () => {
    audio.onClick();
    setState({
      statusMessage: null,
      socialError: false,
      socialErrorType: null,
      error: null,
    });
  };

  return (
    <SStart
      navigation={props.navigation}
      mountIntent={{
        facebook: () =>
          SocialLoginInterface.facebookLoginHandler({
            FB,
            emailPermissionAccepted: state.emailPermissionAccepted,
            setSuccessState: setSuccessState,
            setErrorState: setErrorState,
            setCanceledState: setCanceledState,
          }),
      }}
      render={args => {
        const {
          onGuestActivate,
          onAccountActivate,
          onFacebookActivate,
          onGoogleActivate,
          googleAuthServiceAvailable,
          isVisible,
          terms,
          isLoading,
          closeModal: argsCloseModal,
          onSubmit,
          setTerms,
          termsAndConditionsUrl,
          privacyPolicyUrl,
          hasFacebook,
        } = args;

        const hasFotterText = getText(TEXT_KEY.FOOTER_TEXT) !== '';

        const startGoogleSignInFlow = () => {
          setState({
            isGoogleSignIn: true,
          });
          onGoogleActivate();
        };

        return (
          <SplashScreen className="SplashScreen">
            <StarTCs
              isVisible={isVisible}
              closeModal={argsCloseModal}
              setTerms={setTerms}
              termsAndConditionsUrl={termsAndConditionsUrl}
              privacyPolicyUrl={privacyPolicyUrl}
              terms={terms}
              onSubmit={onSubmit}
              isGoogleSignIn={state.isGoogleSignIn}
              googleAuth={props.googleAuth}
            />
            <TermsModal
              isVisible={!!state.error && !state.socialError}
              onFacebookActivate={onFacebookActivate}
              onGoogleActivate={onGoogleActivate}
              onAccountActivate={onAccountActivate}
              mark={assetSource(ASSET_KEY.MARK)}
              closeModal={closeModal}
              closeRetryModal={closeModal}
              error={state.error}
              socialError={state.socialError}
              socialErrorType={state.socialErrorType}
              statusMessage={state.statusMessage}
            />
            <div className="splash-logo">
              <SplashLogo src={assetSource(ASSET_KEY.START_SCREEN_LOGO)} />
            </div>
            <SplashMainContainer footer={hasFotterText} className="splash-actions-container">
              <div className="splash-facebook-button" onClick={() => hasFacebook && onFacebookActivate()}>
                {hasFacebook && <LoginButtonHolder src={assetSource(ASSET_KEY.FACEBOOK_BUTTON)} />}
              </div>

              <div className="splash-member-button">
                <MemberLoginButtonHolder
                  src={assetSource(ASSET_KEY.MEMBER_BUTTON)}
                  playAsGuest={state.playAsGuest}
                  className="member-button-image"
                  onClick={onAccountActivate}
                />
                {state.playAsGuest === 'true' && (
                  <p className="splash-member-text" onClick={onGuestActivate}>
                    {getText(TEXT_KEY.PLAY_AS_GUEST)}
                  </p>
                )}
                {hasFotterText && (
                  <FooterTextContainer>
                    <FooterText>{getText(TEXT_KEY.FOOTER_TEXT)}</FooterText>
                  </FooterTextContainer>
                )}
              </div>
              <div className="splash-google-button" onClick={googleAuthServiceAvailable && startGoogleSignInFlow}>
                <LoginButtonHolder src={assetSource(ASSET_KEY.GOOGLE_BUTTON)} />
              </div>
            </SplashMainContainer>
            {isLoading && !state.oauthLoading && <Spinner animation="grow" className="custom-spinner" />}
            {state.oauthLoading && !isLoading && <Spinner animation="grow" className="custom-spinner" />}
          </SplashScreen>
        );
      }}
    />
  );
};
Star.path = '';

export default Star;

const SplashScreen = styled.div`
  background-image: url(${assetSource(ASSET_KEY.SPLASH_BG)});
`;

const SplashLogo = styled(Image)`
  width: 100%;
  height: auto;
`;

const SplashMainContainer = styled.div`
  height: ${({ footer }) => (footer ? 'calc(11rem + 40px)' : '11rem')};
  background-image: url(${assetSource(ASSET_KEY.SPLASH_BAR_BACKING)});
  bottom: 0px;
  align-items: center;
  justify-content: center;
`;

const LoginButtonHolder = styled(Image)`
  width: 60%;
  height: 35%;
`;

const MemberLoginButtonHolder = styled(Image)`
  margin-bottom: ${({ playAsGuest }) => (playAsGuest === 'true' ? 6 : 46)}px;
`;

const FooterTextContainer = styled.div`
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 200%;
  margin-top: -10px;
  margin-bottom: 10px;
`;

const FooterText = styled.p`
  max-width: 1100px;
  text-align: center;
  font-size: 0.7em;
`;

