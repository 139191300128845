import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getGameRoomsQuery, getInitGameQuery, getFavGamesQuery } from './queries';
export var gamesApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getGameRooms: builder.query(getGameRoomsQuery),
      getInitGame: builder.query(getInitGameQuery),
      getFavGames: builder.query(getFavGamesQuery)
    };
  }
});
gamesApi.enhanceEndpoints({
  addTagTypes: ['GetGameRooms', 'GetFavGames']
});
var _gamesApi$endpoints = gamesApi.endpoints,
  getGameRooms = _gamesApi$endpoints.getGameRooms,
  getInitGame = _gamesApi$endpoints.getInitGame,
  getFavGames = _gamesApi$endpoints.getFavGames;
export { getGameRooms, getInitGame, getFavGames };