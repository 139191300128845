import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { postGetInboxMessagesQuery, getInboxMessageDetailsQuery, postCollectMessageRewardsMutation, deleteInboxMessagesMutation } from './queries';
export var inboxApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postGetInboxMessages: builder.query(postGetInboxMessagesQuery),
      getInboxMessageDetails: builder.query(getInboxMessageDetailsQuery),
      postCollectMessageRewards: builder.mutation(postCollectMessageRewardsMutation),
      deleteInboxMessages: builder.mutation(deleteInboxMessagesMutation)
    };
  },
  overrideExisting: true
});
inboxApi.enhanceEndpoints({
  addTagTypes: ['InboxMessages']
});
var usePostGetInboxMessagesQuery = inboxApi.usePostGetInboxMessagesQuery,
  useGetInboxMessageDetailsQuery = inboxApi.useGetInboxMessageDetailsQuery,
  usePostCollectMessageRewardsMutation = inboxApi.usePostCollectMessageRewardsMutation,
  useDeleteInboxMessagesMutation = inboxApi.useDeleteInboxMessagesMutation,
  _inboxApi$endpoints = inboxApi.endpoints,
  postGetInboxMessages = _inboxApi$endpoints.postGetInboxMessages,
  getInboxMessageDetails = _inboxApi$endpoints.getInboxMessageDetails,
  postCollectMessageRewards = _inboxApi$endpoints.postCollectMessageRewards,
  deleteInboxMessages = _inboxApi$endpoints.deleteInboxMessages;
export { usePostGetInboxMessagesQuery, useGetInboxMessageDetailsQuery, usePostCollectMessageRewardsMutation, useDeleteInboxMessagesMutation, postGetInboxMessages, getInboxMessageDetails, postCollectMessageRewards, deleteInboxMessages };