import React, { useContext } from 'react';
import { OneLineActionsContainer, BottomBar, BottomBarSecondLine, FooterText } from '../../styledComponents';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import ThemeContext from 'shared/context/ThemeContext';

const AdmiralBottomBar = ({ onAccountActivate, withGuest, onGuestActivate, isGuestLoginButton }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <BottomBar bgImage={assetSource(ASSET_KEY.DAILY_BONUS_PRIZES_BOTTOM_BAR)}>
      <OneLineActionsContainer theme={themeContext.Start.AdmiralBottomBarContainer}>
        <BottomBarSecondLine onClick={onAccountActivate}>{getText(TEXT_KEY.LOG_IN_HERE)}</BottomBarSecondLine>
        {withGuest && !isGuestLoginButton && (
          <BottomBarSecondLine qa-id="playAsGuestBtn" onClick={onGuestActivate}>
            {getText(TEXT_KEY.PLAY_AS_GUEST)}
          </BottomBarSecondLine>
        )}
      </OneLineActionsContainer>
      {getText(TEXT_KEY.FOOTER_TEXT) !== '' && (
        <FooterText theme={themeContext.Start.BottomBarFooterText}>{getText(TEXT_KEY.FOOTER_TEXT)}</FooterText>
      )}
    </BottomBar>
  );
};

export default AdmiralBottomBar;

