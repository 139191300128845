import React from 'react';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import styled from 'styled-components';

const MessageContainer = ({ component }) => {
  return (
    <Container>
      <Background src={assetSource(ASSET_KEY.MESSAGE_BACKGROUND)} resizeMode="stretch" />
      <WrapperText>
        <Title>{getText(TEXT_KEY.SOCIAL_MESSAGES_TITLE)}</Title>
      </WrapperText>
      <ScreenContainer>{component}</ScreenContainer>
    </Container>
  );
};

export default MessageContainer;

const Container = styled.div`
  height: 40rem;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 4rem;
  margin: 0 auto;
`;

const Background = styled.img`
  width: 85%;
  height: 92%;
  position: absolute;
  z-index: 1;
  bottom: 0.3rem;
`;

const WrapperText = styled.div`
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Title = styled.h4`
  margin: 0 auto;
  font-size: 2.8rem;
  background: linear-gradient(to bottom, rgb(237 237 130) 35%, rgb(214 177 104) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  text-stroke: 2px #4d4729;
  -webkit-text-stroke: 2px #4d4729;
  text-transform: uppercase;
`;

const ScreenContainer = styled.div`
  position: absolute;
  height: 95%;
  width: 100%;
  z-index: 5;
  bottom: 0;
  padding: 5rem;
  padding-bottom: 0;
  display: flex;
`;

