import React, { useRef } from 'react';
import { COUNTRY, STATES } from 'shared/utils/kyc/Constants';
import { Form, Col, Row } from 'react-bootstrap';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import getValuesArray from 'shared/utils/getValuesArray';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import { registerCardsFormKeys, checkOnInputPhoneNumber } from 'shared/screens/RegisterRewardCard/utils';
import { getDefaultCountryValue } from 'shared/utils/kyc/getDefaultCountry';
import PropertySelector from '../../../../components/PropertySelector';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

import { styles } from '../../styles';
export default function FormInputs(props) {
  const recaptchaRef = useRef();
  const {
    propertyRequiredFields,
    customPropertyOption,
    handleChange,
    setFieldValue,
    handleBlur,
    changeProperty,
    showProperty,
    values,
  } = props;

  return (
    <>
      <RowHolder>
        {propertyRequiredFields[registerCardsFormKeys.FIRST_NAME] && (
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <Form.Control
                  className={'transparentInput'}
                  autoCapitalize="none"
                  onChange={handleChange(registerCardsFormKeys.FIRST_NAME)}
                  onBlur={handleBlur(registerCardsFormKeys.FIRST_NAME)}
                  value={values[registerCardsFormKeys.FIRST_NAME]}
                  placeholder={propertyRequiredFields[registerCardsFormKeys.FIRST_NAME]?.placeHolder}
                />
              </label>
            </div>
          </ColumnHolder>
        )}
        {propertyRequiredFields[registerCardsFormKeys.LAST_NAME] && (
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <Form.Control
                  className={'transparentInput'}
                  autoCapitalize="none"
                  onChange={handleChange(registerCardsFormKeys.LAST_NAME)}
                  onBlur={handleBlur(registerCardsFormKeys.LAST_NAME)}
                  value={values[registerCardsFormKeys.LAST_NAME]}
                  placeholder={propertyRequiredFields[registerCardsFormKeys.LAST_NAME]?.placeHolder}
                />
              </label>
            </div>
          </ColumnHolder>
        )}
      </RowHolder>

      <RowHolder>
        {propertyRequiredFields[registerCardsFormKeys.EMAIL] && (
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <Form.Control
                  className={'transparentInput'}
                  autoCapitalize="none"
                  onChange={handleChange(registerCardsFormKeys.EMAIL)}
                  onBlur={handleBlur(registerCardsFormKeys.EMAIL)}
                  value={values[registerCardsFormKeys.EMAIL]}
                  placeholder={propertyRequiredFields[registerCardsFormKeys.EMAIL]?.placeHolder}
                />
              </label>
            </div>
          </ColumnHolder>
        )}
        {showProperty && (
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <div className="inputWithDropdown">
                  <PropertSelectorHolder customPropertyOption={customPropertyOption}>
                    <PropertySelector
                      onChangeDOB={({ value }) => {
                        if (value) {
                          changeProperty(value);
                          !values[registerCardsFormKeys.IS_PROPERTY_SELECTED] &&
                            setFieldValue(registerCardsFormKeys.IS_PROPERTY_SELECTED, true);
                        }
                      }}
                      selectedPropertyId={values[registerCardsFormKeys.PROPERTY]}
                      styles={styles.inputContainer}
                      customOptions={customPropertyOption}
                      hidePlaceHolder={customPropertyOption ? true : false}
                      registerCard
                    />
                  </PropertSelectorHolder>
                  <DropDownCaret />
                </div>
              </label>
            </div>
          </ColumnHolder>
        )}
      </RowHolder>
      {propertyRequiredFields[registerCardsFormKeys.YEAR] && (
        <>
          <DateOfBirthTitle>{getText(TEXT_KEY.REGISTER_REWARD_CARD_DATE)}</DateOfBirthTitle>
          <RowHolder>
            <ColumnHolder>
              <div className="inputField">
                <label className="inputLabel">
                  <Form.Control
                    className={'transparentInput'}
                    autoCapitalize="none"
                    onChange={handleChange(registerCardsFormKeys.YEAR)}
                    onBlur={handleBlur(registerCardsFormKeys.YEAR)}
                    value={values[registerCardsFormKeys.YEAR]}
                    placeholder={propertyRequiredFields[registerCardsFormKeys.YEAR]?.placeHolder}
                  />
                </label>
              </div>
            </ColumnHolder>
            <ColumnHolder>
              <div className="inputField">
                <label className="inputLabel">
                  <div className="inputWithDropdown">
                    <Form.Control
                      className={'transparentInput'}
                      autoCapitalize="none"
                      as="select"
                      onChange={handleChange(registerCardsFormKeys.MONTH)}
                      onBlur={handleBlur(registerCardsFormKeys.MONTH)}
                      value={values[registerCardsFormKeys.MONTH]}
                    >
                      <option disable hidden value="">
                        {propertyRequiredFields[registerCardsFormKeys.MONTH]?.placeHolder}
                      </option>
                      {getValuesArray(12).map(option => {
                        return (
                          <option key={option.value} className="form-input-options" value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <DropDownCaret />
                  </div>
                </label>
              </div>
            </ColumnHolder>
            <ColumnHolder>
              <div className="inputField">
                <label className="inputLabel">
                  <div className="inputWithDropdown">
                    <Form.Control
                      className={'transparentInput'}
                      autoCapitalize="none"
                      as="select"
                      onChange={handleChange(registerCardsFormKeys.DAY)}
                      onBlur={handleBlur(registerCardsFormKeys.DAY)}
                      value={values[registerCardsFormKeys.DAY]}
                    >
                      <option disable hidden value="">
                        {propertyRequiredFields[registerCardsFormKeys.DAY]?.placeHolder}
                      </option>
                      {getValuesArray(31).map(option => {
                        return (
                          <option key={option.value} className="form-input-options" value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <DropDownCaret />
                  </div>
                </label>
              </div>
            </ColumnHolder>
          </RowHolder>
        </>
      )}
      {propertyRequiredFields[registerCardsFormKeys.COUNTRY] && <LabelHolder>{getText(TEXT_KEY.COUNTRY)}</LabelHolder>}
      <RowHolderWithLabel>
        {propertyRequiredFields[registerCardsFormKeys.COUNTRY] && (
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <div className="inputWithDropdown">
                  <Form.Control
                    className={'transparentInput'}
                    autoCapitalize="none"
                    as="select"
                    onChange={handleChange(registerCardsFormKeys.COUNTRY)}
                    onBlur={handleBlur(registerCardsFormKeys.COUNTRY)}
                    value={values[registerCardsFormKeys.COUNTRY]}
                  >
                    {COUNTRY().map(option => {
                      return (
                        <option key={option.value} className="form-input-options" value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <DropDownCaret />
                </div>
              </label>
            </div>
          </ColumnHolder>
        )}
        {propertyRequiredFields[registerCardsFormKeys.PHONE] && (
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <Form.Control
                  className={'transparentInput'}
                  autoCapitalize="none"
                  onInput={e => (e.target.value = checkOnInputPhoneNumber(e.target.value))}
                  onChange={handleChange(registerCardsFormKeys.PHONE)}
                  onBlur={handleBlur(registerCardsFormKeys.PHONE)}
                  value={values[registerCardsFormKeys.PHONE]}
                  placeholder={propertyRequiredFields[registerCardsFormKeys.PHONE]?.placeHolder}
                />
              </label>
            </div>
          </ColumnHolder>
        )}
      </RowHolderWithLabel>

      <RowHolder>
        {propertyRequiredFields[registerCardsFormKeys.STATE] && (
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <div className="inputWithDropdown">
                  <Form.Control
                    className={'transparentInput'}
                    autoCapitalize="none"
                    as="select"
                    onChange={handleChange(registerCardsFormKeys.STATE)}
                    onBlur={handleBlur(registerCardsFormKeys.STATE)}
                    value={values[registerCardsFormKeys.STATE]}
                  >
                    {STATES[values[registerCardsFormKeys.COUNTRY] || getDefaultCountryValue]().map(option => {
                      return (
                        <option
                          selected={option.value === values[registerCardsFormKeys.STATE]}
                          key={option.value}
                          className="form-input-options"
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <DropDownCaret />
                </div>
              </label>
            </div>
          </ColumnHolder>
        )}
        {propertyRequiredFields[registerCardsFormKeys.CITY] && (
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <Form.Control
                  className={'transparentInput'}
                  autoCapitalize="none"
                  onChange={handleChange(registerCardsFormKeys.CITY)}
                  onBlur={handleBlur(registerCardsFormKeys.CITY)}
                  value={values[registerCardsFormKeys.CITY]}
                  placeholder={propertyRequiredFields[registerCardsFormKeys.CITY]?.placeHolder}
                />
              </label>
            </div>
          </ColumnHolder>
        )}
      </RowHolder>

      {propertyRequiredFields[registerCardsFormKeys.ADDRESS_LINE_1] && (
        <RowHolder>
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <Form.Control
                  className={'transparentInput'}
                  autoCapitalize="none"
                  onChange={handleChange(registerCardsFormKeys.ADDRESS_LINE_1)}
                  onBlur={handleBlur(registerCardsFormKeys.ADDRESS_LINE_1)}
                  value={values[registerCardsFormKeys.ADDRESS_LINE_1]}
                  placeholder={propertyRequiredFields[registerCardsFormKeys.ADDRESS_LINE_1]?.placeHolder}
                />
              </label>
            </div>
          </ColumnHolder>
        </RowHolder>
      )}

      {propertyRequiredFields[registerCardsFormKeys.ADDRESS_LINE_2] && (
        <RowHolder>
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <Form.Control
                  className={'transparentInput'}
                  autoCapitalize="none"
                  onChange={handleChange(registerCardsFormKeys.ADDRESS_LINE_2)}
                  onBlur={handleBlur(registerCardsFormKeys.ADDRESS_LINE_2)}
                  value={values[registerCardsFormKeys.ADDRESS_LINE_2]}
                  placeholder={propertyRequiredFields[registerCardsFormKeys.ADDRESS_LINE_2]?.placeHolder}
                />
              </label>
            </div>
          </ColumnHolder>
        </RowHolder>
      )}

      <RowHolder>
        {propertyRequiredFields[registerCardsFormKeys.ZIP] && (
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <Form.Control
                  className={'transparentInput'}
                  autoCapitalize="none"
                  onChange={handleChange(registerCardsFormKeys.ZIP)}
                  onBlur={handleBlur(registerCardsFormKeys.ZIP)}
                  value={values[registerCardsFormKeys.ZIP]}
                  placeholder={propertyRequiredFields[registerCardsFormKeys.ZIP]?.placeHolder}
                />
              </label>
            </div>
          </ColumnHolder>
        )}
        {propertyRequiredFields[registerCardsFormKeys.PIN] && (
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <Form.Control
                  className={'transparentInput'}
                  autoCapitalize="none"
                  onChange={handleChange(registerCardsFormKeys.PIN)}
                  onBlur={handleBlur(registerCardsFormKeys.PIN)}
                  value={values[registerCardsFormKeys.PIN]}
                  placeholder={propertyRequiredFields[registerCardsFormKeys.PIN]?.placeHolder}
                />
              </label>
            </div>
          </ColumnHolder>
        )}
      </RowHolder>

      {!values[registerCardsFormKeys.TOKEN] && (
        <RowHolder>
          <ColumnHolder>
            <ReCAPTCHA
              style={styles.captcha}
              ref={captchaRef => {
                recaptchaRef.current = captchaRef;
              }}
              sitekey={ENVIRONMENT_VARIABLES.SITE_KEY}
              onChange={token => setFieldValue(registerCardsFormKeys.TOKEN, token)}
            />
          </ColumnHolder>
        </RowHolder>
      )}
    </>
  );
}

const DateOfBirthTitle = styled.p`
  margin-top: -5px;
  margin-bottom: -5px;
  margin-left: 25px;
  color: #d4b8be;
`;

const LabelHolder = styled(DateOfBirthTitle)`
  margin-bottom: -10px;
`;

const ColumnHolder = styled(Col)`
  padding: 0.1rem 0.5rem;
`;

const RowHolder = styled(Row)`
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 5px;
`;

const PropertSelectorHolder = styled.div`
  margin-top: -10px;
  width: 100%;
  pointer-events: ${({ customPropertyOption }) => (customPropertyOption ? 'none' : 'auto')};
`;

const RowHolderWithLabel = styled(RowHolder)`
  margin-top: 0.5em;
`;

const DropDownCaret = styled.img.attrs(() => ({
  src: assetSource(ASSET_KEY.CARET_DOWN),
  className: 'downIcon',
  alt: 'caret_down',
}))`
  pointer-events: none;
`;

