import React, { Component } from 'react';
import STimestampManagerComponent from 'shared/components/TimestampManagerComponent';

export default class TimestampManagerComponent extends Component {
  state = {};

  render() {
    return (
      <STimestampManagerComponent
        navigation={this.props.navigation}
        render={() => {
          return <></>;
        }}
      />
    );
  }
}
