import React from 'react';
import Button from '../../../../components/Button/Button';
import { styles } from './styles';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const TiFormButton = props => {
  const { handleLinkBtn, handlePinAndAccount, error } = props;

  return (
    <Button
      onClick={() => {
        if (!error) handleLinkBtn(handlePinAndAccount);
      }}
      label={getText(TEXT_KEY.LINK)}
      containerStyle={styles.buttonContainerStyle}
      imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_SECONDARY)}
      textStroke={BUTTON_STROKES.SECONDARY}
    />
  );
};

export default TiFormButton;

