import React, { Component } from 'react';
import SGameTile from 'shared/components/GameTile';
import { ONBOARDING_FLOWS } from 'shared/utils/onboarding';
import { FAVOURITE_ONBOARDING_STEPS } from '../OnboardingLayer/onboardingSteps';
import PanelType from 'shared/components/PinnedPanel/types';
import CdnPath from 'shared/utils/cdnPath';
import audio from 'shared/utils/audio';
import { counterTypes } from 'shared/utils/TimestampManager';
import CountdownTimer from '../../lib/time/component/CountdownTimer';
import { FTUE_EVENT_TYPES, FTUE_STEPS_KEYS } from 'shared/constants';
import { sendFtueEvent } from 'shared/lib/analytics/ftueLogger';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import ThemeContext from 'shared/context/ThemeContext';

/** @typedef {import('shared/api/Constants').LivePanelInfo} LivePanelInfo */

export default class GameTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFavourite: false,
      gameImageLoaded: false,
      hover: false,
      timerIsFinished: false,
    };
    this.ftueGameID = null;
    this.firstTime = true;
  }

  start = gameID => {
    if (this.firstTime) {
      this.moveToStop(gameID);
    }
  };

  updateStopBg = style => {
    let message = {
      message: getText(TEXT_KEY.TAP_START),
    };
    let position = {
      x: style.left,
      y: style.top,
      width: style.width,
      height: style.height,
      hasImage: this.iconImage,
    };
    this.openFtueOnboarding(message, position);
    this.firstTime = false;
  };

  updateStop = () => {
    if (!this.currentStopElm) return;
    let style = this.currentStopElm.getBoundingClientRect();

    this.updateStopBg(style);
  };

  moveToStop = gameID => {
    this.currentStopElm = document.getElementById(gameID);
    this.updateStop();
  };

  componentDidUpdate(prevProps) {
    const { highlightedForOnboarding, onboardingStep, itemData, markOnboardingSeen, clearOnboarding } = this.props;

    if (itemData.GAME_ID !== prevProps.itemData.GAME_ID) {
      this.setState({ gameImageLoaded: false });
    }

    if (!highlightedForOnboarding) return;
    if (onboardingStep === prevProps.onboardingStep) return;
    if (onboardingStep === FAVOURITE_ONBOARDING_STEPS.FINISH) {
      markOnboardingSeen(ONBOARDING_FLOWS.FAVOURITES);
      return clearOnboarding();
    }
  }

  handleLoad = () => {
    const { highlightedForOnboarding, onboardingStep, markOnboardingSeen, clearOnboarding } = this.props;

    if (!highlightedForOnboarding) return;
    if (onboardingStep === FAVOURITE_ONBOARDING_STEPS.FINISH) {
      markOnboardingSeen(ONBOARDING_FLOWS.FAVOURITES);
      return clearOnboarding();
    }
  };

  handleClick = (baseHandler, ftueStep = null) => {
    audio.onClick();
    const { clearOnboarding, markOnboardingSeen, onboardingStep } = this.props;
    if (onboardingStep === FAVOURITE_ONBOARDING_STEPS.FINISH) {
      markOnboardingSeen(ONBOARDING_FLOWS.FAVOURITES);
      return clearOnboarding();
    }
    if (ftueStep > FTUE_STEPS_KEYS.INITIAL) {
      sendFtueEvent(FTUE_EVENT_TYPES.GAME_TUTORIAL_NEXT_STEP, { step: ftueStep });
      this.changeFtueStep(FTUE_STEPS_KEYS.SHOW_IN_GAME);
    }
    baseHandler();
  };

  handleHeartClick = (event, baseHandler) => {
    audio.onClick();
    const { highlightedForOnboarding, onboardingStep, clearOnboarding, markOnboardingSeen } = this.props;

    if (highlightedForOnboarding && onboardingStep === FAVOURITE_ONBOARDING_STEPS.FINISH) {
      markOnboardingSeen(ONBOARDING_FLOWS.FAVOURITES);
      return clearOnboarding();
    }
    baseHandler(event);
  };

  handleGameImageLoaded = () => {
    this.setState({ gameImageLoaded: true });
  };

  _hoverHandler = event => {
    this.setState({
      hover: true,
    });
  };

  _leaveHandler = event => {
    this.setState({
      hover: false,
    });
  };

  endTimerCallback = () => this.setState({ timerIsFinished: true });

  render() {
    const themeContext = this.context;
    return (
      <SGameTile
        itemData={this.props.itemData}
        navigation={this.props.navigation}
        render={
          /**
           * @param {Object} args
           * @param {Function} args.clickHandle
           * @param {Function} args.addFavouriteGame
           * @param {Function} args.removeFavouriteGame
           * @param {LivePanelInfo} args.data
           * @param {Boolean} args.isFavourite
           */
          args => {
            const {
              clickHandle,
              data,
              addFavouriteGame,
              removeFavouriteGame,
              isFavourite,
              locked,
              changeFtueStep,
              openFtueOnboarding,
              ftueStep,
              cacheId,
              sneakPeekTimer,
              progressiveJackpotsData,
            } = args;
            const { gameImageLoaded, hover } = this.state;
            const { userCanFavourite } = this.props;
            data.item_id = data.GAME_ID;
            data.item_type = PanelType.GAMES;
            const imageUrl = cacheId
              ? `${CdnPath.SLOT_ICON_WEB}${data.item_id}.png?cacheId=${cacheId}`
              : `${CdnPath.SLOT_ICON_WEB}${data.item_id}.png`;

            let hideFavouriteIcon = data.extras && data.extras.showFavouriteIcon === false;
            if (data.item_id === this.props.ftueGameId && ftueStep > FTUE_STEPS_KEYS.INITIAL) {
              this.changeFtueStep = changeFtueStep;
              this.openFtueOnboarding = openFtueOnboarding;
              this.iconImage = imageUrl;
              this.ftueGameID = `game-${this.props.ftueGameId}`;
              this.start(this.ftueGameID);
            }

            const lockDismiss = sneakPeekTimer && sneakPeekTimer > 0 && !this.state.timerIsFinished;

            return (
              <div
                qa-id="gameTitle"
                className="live-panel-item"
                style={{ display: 'flex', ...themeContext.GameTile.LivePanelItem }}
                onLoad={this.handleLoad}
                ref={this.props.gameTileRef}
              >
                <div id={`game-${data.item_id}`}>
                  <div
                    onClick={() => (!locked || lockDismiss) && this.handleClick(clickHandle, ftueStep)}
                    style={{ cursor: !locked || lockDismiss ? 'pointer' : 'default' }}
                    onMouseEnter={this._hoverHandler}
                    onMouseLeave={this._leaveHandler}
                  >
                    {progressiveJackpotsData && (
                      <div className="jackpotContainer">
                        <div className="jackpotText">
                          <div className="contentJackpot">
                            <div className="jackpotData">{progressiveJackpotsData}</div>
                          </div>
                        </div>
                        <img src={assetSource(ASSET_KEY.JACKPOT_DISPLAYED_SCREEN)} alt="Progressive Jackpot" />
                      </div>
                    )}

                    {sneakPeekTimer && sneakPeekTimer > 0 && !this.state.timerIsFinished && (
                      <div className="sneakPeekTimerHolderOnTile">
                        <img src={assetSource(ASSET_KEY.TIMER_DISPLAYED_SCREEN)} alt="Sneak Peek" />
                        <CountdownTimer
                          counterType={counterTypes.SNEAK_PEEK}
                          endDate={sneakPeekTimer * 1000}
                          justTimerText={true}
                          endTimerCallback={this.endTimerCallback}
                          style={{ opacity: 0.9, fontWeight: 700 }}
                        />
                      </div>
                    )}
                    {!gameImageLoaded && (
                      <div
                        className="GameTilePlaceholder"
                        style={{
                          backgroundImage: `url(${assetSource(ASSET_KEY.LOBBY_COMING_SOON)})`,
                        }}
                      />
                    )}

                    <div
                      className={`GameTileOverlay ${locked && !lockDismiss ? 'locked' : ''} ${
                        hover ? 'highlighted' : ''
                      }`}
                      style={{
                        backgroundImage: `url(${imageUrl})`,
                      }}
                    />

                    <img
                      src={imageUrl}
                      alt=""
                      style={{ width: '16vw', zIndex: -1, opacity: 0 }}
                      onLoad={this.handleGameImageLoaded}
                    />

                    {locked && !lockDismiss && (
                      <div
                        className={`GameTileOverlay ${this.state.hover ? 'AnimatedLock' : ''}`}
                        style={{
                          backgroundImage: `url(${assetSource(ASSET_KEY.GAMES_LOCK)})`,
                        }}
                        onMouseEnter={this._hoverHandler}
                        onMouseLeave={this._leaveHandler}
                      >
                        {this.state.hover && (
                          <>
                            <span>{getText(TEXT_KEY.FROM)}</span>
                            <span>
                              {getText(TEXT_KEY.LEVEL)} {data.UNLOCK_LEVEL}
                            </span>
                          </>
                        )}
                      </div>
                    )}
                    {!locked && userCanFavourite && !hideFavouriteIcon && (this.state.hover || isFavourite) && (
                      <div
                        alt="Favorite icon"
                        className="GameTileOverlay"
                        onMouseEnter={this._hoverHandler}
                        onMouseLeave={this._leaveHandler}
                        style={{
                          backgroundImage: isFavourite
                            ? `url(${assetSource(ASSET_KEY.HEART_ON)})`
                            : `url(${assetSource(ASSET_KEY.HEART_OFF)})`,
                        }}
                      >
                        <button
                          style={{
                            border: 'none',
                            boxShadow: 'none',
                            cursor: 'pointer',
                            outline: 'none',
                            backgroundColor: 'transparent',
                            height: '50%',
                            width: '35%',
                            bottom: 0,
                            left: 0,
                            position: 'absolute',
                          }}
                          onClick={event =>
                            this.handleHeartClick(event, isFavourite ? removeFavouriteGame : addFavouriteGame)
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        }
      />
    );
  }
}

GameTile.contextType = ThemeContext;

