import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getLevelUpDataQuery } from './queries';
export var levelUpApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getLevelUpData: builder.query(getLevelUpDataQuery)
    };
  }
});
levelUpApi.enhanceEndpoints({
  addTagTypes: ['LevelUp']
});
var getLevelUpData = levelUpApi.endpoints.getLevelUpData;
export { getLevelUpData };