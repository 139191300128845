import generateRender from 'shared/utils/generateRender';
import DefaultPurchaseHeader from '../../../../components/JSModal/components/DefaultHeader';
import HighlightedPurchaseHeader from '../../../../components/JSModal/components/HighlightedHeader';

const VipAccessHeader = generateRender({
  default: DefaultPurchaseHeader,
  millelacs: HighlightedPurchaseHeader,
  jamul: HighlightedPurchaseHeader,
});

export default VipAccessHeader;

