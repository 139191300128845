import styled, { css } from 'styled-components';

export const headerContainerStyle = css`
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

export const wrapperStyle = css`
  width: calc(100% + 2rem);
  left: -1rem;
  top: -1rem;
`;

export const titleStyle = css`
  font-size: 3vmin;
`;

export const headerTitleWrapperStyle = css`
  margin-bottom: 1vmin;
  margin-top: 1vmin;
  font-size: 4vmin;
  font-weight: 600;
`;

export const ModalWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  top: -1rem;
  @media screen and (max-width: 1400px) {
    top: -0.8rem;
  }
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3%;
  padding-right: 2%;
  padding-left: 2%;
  height: 70%;
  align-items: center;
`;

export const ErrorMessageText = styled.span`
  font-weight: 500;
  text-align: center;
`;

