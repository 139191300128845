import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Logger from '../../../../lib/analytics/logger';
import { EVENT_TYPES } from '../../../../lib/analytics/constants';
import store from '../../../../state/store';
import { setLastLoginInfo } from '../../../../state/actions/auth';
export function sendMemberAccountLinkedEvent(casinoId) {
  var trigger = Logger.getActionTrigger();
  var eventPayload = {
    member_id: casinoId,
    trigger: trigger || ''
  };
  Logger.sendEvent(EVENT_TYPES.MEMBER_ACCOUNT_LINKED, eventPayload);
}
export function postLoginActions(method, success) {
  if (success) {
    var eventPayload = {
      success: success
    };
    Logger.sendEvent(EVENT_TYPES.SUCCESFUL_LOGIN, eventPayload);
  }
  var lastLoginInfo = store.getState().token.lastLoginInfo;
  var updatedLoginInfo = _objectSpread(_objectSpread({}, lastLoginInfo), {}, {
    loginMethod: method
  });
  store.dispatch(setLastLoginInfo(updatedLoginInfo));
}