import { createSlice } from '@reduxjs/toolkit';
import { setStateData } from '../../../utils/state';
var sliceName = 'XP';
var initialState = {
  xp: 0,
  xpToNext: 0,
  xpBetMultiplier: 1,
  xpPerSpin: 20,
  xpRequired: 9999999
};
var xpInfoSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    addXp: function addXp(state, action) {
      state.xp = parseInt(state.xp) + action.payload;
    },
    setXpData: setStateData(initialState)
  }
});
var actions = xpInfoSlice.actions,
  reducer = xpInfoSlice.reducer;
var addXp = actions.addXp,
  setXpData = actions.setXpData;
export { addXp, setXpData };
export default reducer;