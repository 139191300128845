import generateRender from 'shared/utils/generateRender';
import DefaultContainer from './DefaultContainer';
import HighlightedContainer from './HighlightedContainer';

const SessionExpireContainer = generateRender({
  default: DefaultContainer,
  jamul: HighlightedContainer,
});

export default SessionExpireContainer;

