import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import ImageBoundsWrapper from '../../../../components/ImageBoundsWrapper';
import { styles } from '../../styles';
import LinkText from '../../../../components/LinkText/LinkText';

const OTPFormFooter = ({
  handleRegisterRewardCard,
  termsAndConditionsUrl,
  enableOrderCard,
  showOTPConsents,
  activeOTPCheckbox,
  handleOPTCheckbox,
  selectedPropertyObj,
}) => {
  return (
    <>
      {showOTPConsents ? (
        <div style={styles.otpConsentsContainer}>
          <ImageBoundsWrapper
            source={activeOTPCheckbox ? assetSource(ASSET_KEY.CHECKBOX) : assetSource(ASSET_KEY.CHECKBOX_DESELECTED)}
            ibw={require(asset`checkbox.ibw`)}
            style={styles.otpConsentsCheckbox}
            className="checkbox-image"
            onClick={handleOPTCheckbox}
          />
          <p style={styles.otpConsentsText}>
            {enableOrderCard && (
              <>
                {`${getText(TEXT_KEY.LINK_CARD_SIGNUP)}`}{' '}
                <LinkText onClick={handleRegisterRewardCard}>{getText(TEXT_KEY.LINK_CARD_SIGNUP_CTA)}</LinkText>{' '}
              </>
            )}
            {getText(TEXT_KEY.LINK_CARD_OTP_CONSENTS)}
            <br />
            <LinkText onClick={() => window.open(selectedPropertyObj.loyaltyTermsAndConditions)}>
              {getText(TEXT_KEY.TERMS_OF_SERVICE)}
            </LinkText>{' '}
            {getText(TEXT_KEY.AND)}{' '}
            <LinkText onClick={() => window.open(selectedPropertyObj.loyaltyPrivacyPolicy)}>
              {getText(TEXT_KEY.PRIVACY_POLICY)}
            </LinkText>{' '}
          </p>
        </div>
      ) : (
        <p style={styles.termsText}>
          {enableOrderCard && (
            <>
              {`${getText(TEXT_KEY.LINK_CARD_SIGNUP)}`}{' '}
              <LinkText customStyle={styles.footerLinkText} onClick={handleRegisterRewardCard}>
                {getText(TEXT_KEY.LINK_CARD_SIGNUP_CTA)}
              </LinkText>{' '}
            </>
          )}
          {getText(TEXT_KEY.TERMS_AGREE_MESSAGE)}{' '}
          <LinkText customStyle={styles.footerLinkText} onClick={() => window.open(termsAndConditionsUrl)}>
            {getText(TEXT_KEY.TERMS_OF_SERVICE)}
          </LinkText>
        </p>
      )}
    </>
  );
};

export default OTPFormFooter;

