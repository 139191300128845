import { Endpoints, ServerResponseKeys } from '../../../api/Constants';
export var getWatchAndEarnStartQuery = {
  query: function query() {
    return {
      url: Endpoints.WATCH_AND_EARN_START
    };
  },
  providesTags: ['WatchAndEarnStart'],
  keepUnusedDataFor: 0,
  transformResponse: function transformResponse(response) {
    return response[ServerResponseKeys.CALL_RESPONSE];
  }
};
export var getWatchAndEarnCompleteQuery = {
  query: function query() {
    return {
      url: Endpoints.WATCH_AND_EARN_COMPLETE
    };
  },
  providesTags: ['WatchAndEarnComplete'],
  keepUnusedDataFor: 0,
  transformResponse: function transformResponse(response) {
    return response[ServerResponseKeys.CALL_RESPONSE];
  }
};