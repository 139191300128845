import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { postRestartSessionMutation } from './queries';
export var restartSessionApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postRestartSession: builder.mutation(postRestartSessionMutation)
    };
  }
});
restartSessionApi.enhanceEndpoints({
  addTagTypes: ['RestartSession'],
  keepUnusedDataFor: 10
});
var usePostRestartSessionMutation = restartSessionApi.usePostRestartSessionMutation,
  postRestartSession = restartSessionApi.endpoints.postRestartSession;
export { usePostRestartSessionMutation, postRestartSession };