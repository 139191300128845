import React from 'react';
import theme from 'shared/assets/style/theme';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import styled from 'styled-components';

const MessageEmpty = () => {
  return (
    <Container>
      <MailImage src={assetSource(ASSET_KEY.MAIL_ICON_OPEN)} />
      <Title>{getText(TEXT_KEY.MESSAGES_DESCRIPTION_TITLE)}</Title>
      <Description>{getText(TEXT_KEY.MESSAGES_DESCRIPTION)}</Description>
    </Container>
  );
};

export default MessageEmpty;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MailImage = styled.img`
  width: 10rem;
  height: 7rem;
  object-fit: contain;
  margin-bottom: 1rem;
`;

const Title = styled.h4`
  background: linear-gradient(
    ${theme.palette.common[0]},
    ${theme.palette.common[1]} 20%,
    ${theme.palette.common[32]} 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 700;
  letter-spacing: -0.1rem;
  margin-top: 1rem;
`;

const Description = styled.p`
  color: white;
  margin-top: 0.5rem;
  font-size: 1.7rem;
`;

