import styled, { css } from 'styled-components';
import theme from 'shared/assets/style/theme';
import ImageBW from '../../../../../../components/ImageBoundsWrapper';

const MainContainer = styled.div`
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`;

const MessageContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const ContentWrapper = styled.div`
  top: 1%;
  bottom: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const DividerImage = styled(ImageBW)`
  height: 22%;
  width: 100%;
  margin-top: 2%;
`;

const IconWrapper = styled.div`
  position: relative;
  width: 100%;
  bottom: 3rem;
  margin-bottom: -2rem;
`;

const MessageIcon = styled.img`
  flex: 1;
  aspect-ratio: 2;
  height: 100px;
`;

const purchaseResponseStyles = {
  headerStyles: {
    height: '10%',
    fontSize: '22px',
    fontWeight: '500',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common[1],
    textShadowColor: theme.palette.common[25],
    textShadowOffset: { width: 0, height: '0.6px' },
    textShadowRadius: '2px',
    paddingVertical: '20px',
  },
  bodyImageStyles: {
    height: '100%',
    width: '100%',
    zIndex: 1,
  },
};

const modalStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6vh 20vw',
  zIndex: '20',
};

const successModalMainBodyStyle = {
  width: '100%',
  height: window.innerHeight < 700 ? '60vh' : '50vh',
  maxHeight: window.innerHeight < 700 ? '540px' : '440px',
  maxWidth: '760px',
};

const errorModalMainBodyStyle = {
  width: '100%',
  height: window.innerHeight < 700 ? '50vh' : '40vh',
  maxHeight: window.innerHeight < 700 ? '440px' : '340px',
  maxWidth: '660px',
};

const highlightedHeaderContainerStyle = css`
  left: 0px;
`;

const highlightedHeaderHeight = css`
  margin-bottom: 8%;
`;

export {
  highlightedHeaderContainerStyle,
  highlightedHeaderHeight,
  modalStyle,
  successModalMainBodyStyle,
  errorModalMainBodyStyle,
  purchaseResponseStyles,
  MainContainer,
  MessageContainer,
  ContentWrapper,
  DividerImage,
  IconWrapper,
  MessageIcon,
};

