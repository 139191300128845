import { useEffect, useRef } from 'react';

function useDragToScroll({ scrollBoxRef, isDraggableX, isDraggableY, isInitialized, dragSensitivity = 1.1 }) {
  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const startYRef = useRef(0);
  const scrollLeftRef = useRef(0);
  const scrollTopRef = useRef(0);

  const handleMouseDown = e => {
    const container = scrollBoxRef.current;
    if (container) {
      container.style.cursor = 'grabbing';
      isDraggingRef.current = true;
      startXRef.current = e.pageX - container.offsetLeft;
      startYRef.current = e.pageY - container.offsetTop;
      scrollLeftRef.current = container.scrollLeft;
      scrollTopRef.current = container.scrollTop;
    }
  };

  const handleMouseUp = () => {
    const container = scrollBoxRef.current;
    if (container) {
      container.style.cursor = 'auto';
      isDraggingRef.current = false;
    }
  };

  const handleMouseLeave = () => {
    const container = scrollBoxRef.current;
    if (container) {
      container.style.cursor = 'auto';
      isDraggingRef.current = false;
    }
  };

  const handleMouseMove = e => {
    const container = scrollBoxRef.current;
    if (isDraggingRef.current && container) {
      e.preventDefault();
      const x = e.pageX - container.offsetLeft;
      const y = e.pageY - container.offsetTop;
      const walkX = (x - startXRef.current) * dragSensitivity;
      const walkY = (y - startYRef.current) * dragSensitivity;
      if (isDraggableX) {
        container.scrollLeft = scrollLeftRef.current - walkX;
      }
      if (isDraggableY) {
        container.scrollTop = scrollTopRef.current - walkY;
      }
    }
  };

  useEffect(() => {
    if (!isDraggableX && !isDraggableY) return;
    if (!isInitialized) return;

    const container = scrollBoxRef.current;
    if (container) {
      container.addEventListener('mousedown', handleMouseDown);
      container.addEventListener('mouseup', handleMouseUp);
      container.addEventListener('mouseleave', handleMouseLeave);
      container.addEventListener('mousemove', handleMouseMove);

      return () => {
        container.removeEventListener('mousedown', handleMouseDown);
        container.removeEventListener('mouseup', handleMouseUp);
        container.removeEventListener('mouseleave', handleMouseLeave);
        container.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, [scrollBoxRef, isDraggableX, isDraggableY, isInitialized]);

  return {};
}

export default useDragToScroll;

