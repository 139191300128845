/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import SShop from 'shared/screens/Shop';
import { ShopTabs } from 'shared/screens/Shop/Constants';
import ImageBW from '../../components/ImageBoundsWrapper';
import coinItem from './CoinItem';
import vipItem from './VIPItem';
import Modal from '../../components/JSModal';
import GenericMessage from '../../screens/GenericMessage/GenericMessage';
import TimeRemainingText from '../../components/TimeRemainingText';
import Animated from 'animated/lib/targets/react-dom';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import Button from '../../components/Button/Button';
import ModalHeader from './components/ModalHeader';
import {
  ModalWrapper,
  headerTitleWrapperStyle,
  ErrorMessageWrapper,
  headerContainerStyle,
  ErrorMessageText,
  titleStyle,
} from './components/styledComponents';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
export default class Shop extends Component {
  constructor(props) {
    super(props);
    this.firstShow = false;
    this.state = {
      showBar: false,
    };
  }

  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-shop',
    };
  }

  /** @typedef {import('shared/api/Constants').CurrencyPackageItemKeys} CPIK */
  /** @typedef {import('shared/api/Constants').CurrencyPackageNewsListItemKeys} News */

  render() {
    const themeContext = this.context;

    return (
      <SShop
        navigation={this.props.navigation}
        dismissModal={this.props.dismissModal}
        render={
          /**
           * @param {Object} args
           * @param {News[]} args.newsItems
           * @param {BP[]} args.vipItems
           * @param {String} args.shopTab Current shop tab
           * @param {Function} args.setShopTabHandler Takes a ShopTab constant
           * @param {Function} args.buyHandle purchase handler
           * @param {Function} args.freeHandle claim free items
           * @param {Object} args.coinSale coin sale info
           * @param {String} args.coinSale.backgroundImage if not null, background image
           * @param {String} args.coinSale.headerImage if not null, header image
           */
          args => {
            const {
              coinItems,
              vipItems,
              shopTab,
              setShopTabHandler,
              buyHandle,
              freeHandle,
              coinSale,
              isSaleOn,
              unlimitedVipAccess,
              showPromoBoost,
              promoBoostDialogType,
              promoBoostDialogMessage,
              promoBoostDialogButtonHandle,
              assetsUrls,
              hasVIPPass,
            } = args;

            const modalInsets = {
              top: promoBoostDialogType === 'success' ? 110 : '23vmin',
              left: promoBoostDialogType === 'success' ? 220 : '22%',
              right: promoBoostDialogType === 'success' ? 220 : '22%',
              bottom: promoBoostDialogType === 'success' ? 110 : '23vmin',
            };

            if (!shopTab) {
              return <div></div>;
            }

            let currentTab = {
              image: assetSource(ASSET_KEY.COINS_TAB_ACTIVE),
              ibw: require(asset`images/coin-store-assets/coins-tab-active.ibw`),
            };
            if (shopTab === ShopTabs.VIP) {
              currentTab.image = assetSource(ASSET_KEY.VIP_TAB_ACTIVE);
              currentTab.ibw = require(asset`images/coin-store-assets/vip-tab-active.ibw`);
            }

            if (coinItem.length > 0 && !this.firstShow) {
              this.firstShow = true;
              setTimeout(() => {
                this.setState({ showBar: true });
              }, 100);
            }

            return (
              <>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <img
                    className="ShopBackground"
                    src={
                      coinSale.backgroundImage
                        ? assetsUrls.promotionImagesUrl + '/' + coinSale.backgroundImage
                        : assetSource(ASSET_KEY.DEFAULT_BACKGROUND)
                    }
                    onError={ev => (ev.target.src = assetSource(ASSET_KEY.DEFAULT_BACKGROUND))}
                  />
                </div>
                <ImageBW
                  className="PopupOverlay"
                  source={assetSource(ASSET_KEY.POPUP_FRAMING)}
                  ibw={require(asset`popup-framing.ibw`)}
                  manualAssetScale={3}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    zIndex: 11,
                  }}
                />
                <div style={themeContext.ShopScreen}>
                  <div style={themeContext.ShopScreen.ShopContainer}>
                    <div style={themeContext.ShopScreen.ShopContainer.ShopHeader}></div>
                    <div style={themeContext.ShopScreen.ShopContainer.PackagesContainer}>
                      <div style={themeContext.ShopScreen.ShopContainer.PackagesContainer.TabButtonsContainer}>
                        <button
                          style={themeContext.ShopScreen.ShopContainer.PackagesContainer.TabButtonsContainer.TabButton}
                          onClick={() => setShopTabHandler(ShopTabs.COINS)}
                        />
                        <button
                          style={themeContext.ShopScreen.ShopContainer.PackagesContainer.TabButtonsContainer.TabButton}
                          onClick={() => setShopTabHandler(ShopTabs.VIP)}
                        />
                      </div>
                      <ImageBW
                        ibw={currentTab.ibw}
                        source={currentTab.image}
                        style={{
                          flex: 1,
                          zIndex: 10,
                        }}
                        innerStyle={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          overflowY: 'auto',
                          overflowX: 'hidden',
                          paddingTop: '8%',
                          height: '100%',
                        }}
                      >
                        {this.state.showBar && (
                          <div style={themeContext.ShopScreen.ShopContainer.PackagesContainer.CoinItemContainer}>
                            {shopTab === ShopTabs.COINS &&
                              coinItems.map(item =>
                                coinItem(
                                  item,
                                  buyHandle,
                                  showPromoBoost,
                                  themeContext.ShopScreen.ShopContainer.PackagesContainer.CoinItem,
                                  themeContext.ShopScreen.ShopContainer.PackagesContainer.InnerItem,
                                  themeContext.ShopScreen.ShopContainer.PackagesContainer.InnerItem.Stack,
                                  themeContext.ShopScreen.ShopContainer.PackagesContainer.InnerItem.StackLoyaltyIcon,
                                  themeContext.ShopScreen.ShopContainer.PackagesContainer.InnerItem.StackAmount,
                                  themeContext.CoinItem.CoinAmount,
                                  themeContext.CoinItem.Extra,
                                  themeContext.CoinItem.BuyButtonText,
                                  assetsUrls
                                )
                              )}
                            {shopTab === ShopTabs.VIP && (
                              <div style={themeContext.ShopScreen.ShopContainer.PackagesContainer.VIPItemContainer}>
                                {vipItems.map((item, index) =>
                                  vipItem(
                                    item,
                                    index,
                                    buyHandle,
                                    freeHandle,
                                    hasVIPPass,
                                    unlimitedVipAccess,
                                    themeContext
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </ImageBW>
                    </div>
                  </div>
                  {isSaleOn && (
                    <ImageBW
                      source={assetSource(ASSET_KEY.COIN_GLOWING_CONTAINER)}
                      ibw={require(asset`images/coin-store-assets/glowing-container.ibw`)}
                      className="saleTimer"
                      style={themeContext.ShopScreen.SaleTimer}
                      widthScale={0.285}
                    >
                      <img src={assetSource(ASSET_KEY.CLOCK_ICON_GLOWING)} alt={'Clock Icon'} className="timerIcon" />
                      {coinSale.endDate > 0 && (
                        <>
                          <TimeRemainingText
                            className="timerLabel"
                            until={coinSale.endDate} // Assuming server sends the time remaining in seconds
                          />
                        </>
                      )}
                    </ImageBW>
                  )}
                  {promoBoostDialogType !== null && !!promoBoostDialogMessage && (
                    <Modal
                      isVisible={true}
                      onBackButtonPress={promoBoostDialogButtonHandle}
                      zIndex={9999}
                      hideCloseButton={true}
                      insets={modalInsets}
                      hideOverlay={true}
                      dialogClassName={'promo-link-applied'}
                      modalMainBodyStyle={{ minHeight: promoBoostDialogType === 'success' ? '32em' : '20vmin' }}
                    >
                      {promoBoostDialogType === 'success' ? (
                        <GenericMessage
                          message={promoBoostDialogMessage}
                          dismissModal={promoBoostDialogButtonHandle}
                          title={`${getText(TEXT_KEY.CONGRATULATIONS)}!`}
                          navigation={this.props.navigation}
                          dividerImage
                          centerImage={{
                            src: assetSource(ASSET_KEY.COIN_GRAPHIC_SALE),
                          }}
                          buyNowButton
                        />
                      ) : (
                        <>
                          <div className="ModalBackground hideBefore">
                            <ImageBW
                              className="ModalBackgroundAsset"
                              as={Animated.div}
                              source={assetSource(ASSET_KEY.MODAL_BACKING_9_SLICE_WEB)}
                              ibw={require(asset`modal-backing-9-slice.ibw`)}
                              widthScale={0.5}
                            />
                          </div>
                          <div className="ModalCloseButton" onClick={promoBoostDialogButtonHandle}>
                            <ImageBW
                              source={assetSource(ASSET_KEY.CLOSE_BUTTON)}
                              ibw={require(asset`close-button.ibw`)}
                              manualAssetScale={3}
                              style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                              innerStyle={{ cursor: 'pointer' }}
                              alt="Close"
                            />
                          </div>
                          <ModalWrapper>
                            <ModalHeader
                              titleStyle={titleStyle}
                              headerTitleWrapperStyle={headerTitleWrapperStyle}
                              containerStyle={headerContainerStyle}
                              title={getText(TEXT_KEY.SORRY)}
                            />
                            <ErrorMessageWrapper>
                              <img
                                style={{ width: '9%', zIndex: 1, margin: '1rem 0' }}
                                src={assetSource(ASSET_KEY.WARNING_SYMBOL)}
                              />
                              <ErrorMessageText>{getText(TEXT_KEY.ALREADY_CLAIMED_BONUS_OR_EXPIRED)}</ErrorMessageText>
                              <Button
                                imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                                imageStyle={{ width: '25%' }}
                                containerStyle={{ height: '100%' }}
                                onClick={promoBoostDialogButtonHandle}
                                label={getText(TEXT_KEY.OK)}
                              />
                            </ErrorMessageWrapper>
                          </ModalWrapper>
                        </>
                      )}
                    </Modal>
                  )}
                </div>
              </>
            );
          }
        }
      />
    );
  }
}

Shop.contextType = ThemeContext;

