import React, { useContext } from 'react';
import ThemeContext from 'shared/context/ThemeContext';
import Screens from 'shared/screens';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import {
  CardsImage,
  CardsImageContainer,
  Divider,
  SportsAccessButtons,
  SportsAccessContainer,
  SportsAccessHeader,
  SportsAccessInfo,
  StyledButton,
} from './styledComponents';

const SportsAccessFlow = () => {
  const themeContext = useContext(ThemeContext);

  const redeemSportsAccess = () => {
    QueryParamsProxy.setQueryParams({
      [RouteParamConstants.PARAM_MODAL]: Screens.LinkAccountForm,
    });
  };

  return (
    <SportsAccessContainer>
      <CardsImageContainer>
        <CardsImage alt="" src={assetSource(ASSET_KEY.CARDS_ACCESS_RESTRICTED)} />
      </CardsImageContainer>
      <SportsAccessHeader>
        {getText(TEXT_KEY.SIDE_BAR_SPORTS)} {getText(TEXT_KEY.SPORTS_ACCESS_NEEDED)}
      </SportsAccessHeader>
      <Divider alt="" src={assetSource(ASSET_KEY.DIVIDER_ACCESS_RESTRICTED)} />
      <SportsAccessInfo>
        {getText(TEXT_KEY.SPORTS_ACCESS_MEMBER)}
        <br />
        {getText(TEXT_KEY.SPORTS_ACCESS_LINK_CARD)}
      </SportsAccessInfo>
      <SportsAccessButtons>
        <StyledButton
          imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
          textStroke={BUTTON_STROKES.QUATERNARY}
          imageStyle={themeContext.SportsAccessFlow.SportsAccessButtonImage}
          label={getText(TEXT_KEY.REDEEM_ACCESS)}
          onClick={redeemSportsAccess}
          textStyle={themeContext.SportsAccessFlow.SportsAccessRedeemButtonText}
        />
      </SportsAccessButtons>
    </SportsAccessContainer>
  );
};

export default SportsAccessFlow;

