import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { postRegisterNewCardMutation } from './queries';
export var registerNewCardApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postRegisterNewCard: builder.mutation(postRegisterNewCardMutation)
    };
  }
});
registerNewCardApi.enhanceEndpoints({
  addTagTypes: ['RegisterNewCard']
});
var usePostRegisterNewCardMutation = registerNewCardApi.usePostRegisterNewCardMutation,
  postRegisterNewCard = registerNewCardApi.endpoints.postRegisterNewCard;
export { usePostRegisterNewCardMutation, postRegisterNewCard };