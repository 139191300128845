import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { postSendSupportMessageMutation } from './queries';
export var customerSupportApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postSendSupportMessage: builder.mutation(postSendSupportMessageMutation)
    };
  }
});
customerSupportApi.enhanceEndpoints({
  addTagTypes: ['CustomerSupport']
});
var postSendSupportMessage = customerSupportApi.endpoints.postSendSupportMessage;
export { postSendSupportMessage };