import _slicedToArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useCallback, useEffect, useState } from 'react';
import useFilterData from '../../../hooks/useFilterData';
import { FILTER_TYPES } from '../../../utils/filterData';
import NavigationConstants from '../../../components/LobbyOverlay/NavigationConstants';
import { sendMissionsEvent } from '../analytics/missionsLogger';
import { MISSIONS_EVENT_TYPES, MISSIONS_TYPES } from '../constants';
import { getTimestamp } from '../../../utils/TimestampManager';
import { hasSomeCompleteState } from '../utils/missionsPerformance';
import { useGetMissionsQuery } from '../../../state/query/missions';
import { useDispatch, useSelector } from 'react-redux';
import { setNavState } from '../../../state/actions/nav_state';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import { FE_MISSIONS } from '../../../utils/featureFlags/constants';
var useMissionsRequest = function useMissionsRequest() {
  var _useFeatureFlags = useFeatureFlags(),
    hasFeature = _useFeatureFlags.hasFeature;
  if (!hasFeature(FE_MISSIONS)) return missionsFeatureStateDefault;
  var dispatch = useDispatch();
  var _useSelector = useSelector(function (state) {
      return state.playerInfo;
    }),
    playerId = _useSelector.playerId;
  var _useState = useState(0),
    _useState2 = _slicedToArray(_useState, 2),
    pollingInterval = _useState2[0],
    setPollingInterval = _useState2[1];
  var _useSelector2 = useSelector(function (state) {
      return state.missions;
    }),
    data = _useSelector2.data,
    missionsFeature = _useSelector2.missionsFeature;
  useGetMissionsQuery({
    requestAssetsData: false,
    playerId: playerId
  }, {
    pollingInterval: pollingInterval
  });
  var handleRequestData = useCallback(function () {
    dispatch(setNavState(NavigationConstants.HIDE_BOTTOM));
    sendMissionsEvent(MISSIONS_EVENT_TYPES.ENTER_MISSIONS_SCREEN);
  }, [dispatch]);
  useEffect(function () {
    handleRequestData();
    return function () {
      dispatch(setNavState(NavigationConstants.NO_HIDE));
    };
  }, [dispatch, handleRequestData]);
  var handlePollingInterval = function handlePollingInterval() {
    var endtimestamp = [];
    (missionsFeature === null || missionsFeature === void 0 ? void 0 : missionsFeature.endTimestamp) && endtimestamp.push(missionsFeature === null || missionsFeature === void 0 ? void 0 : missionsFeature.endTimestamp);
    data === null || data === void 0 || data.forEach(function (item) {
      return !hasSomeCompleteState(item.missionStatus) && endtimestamp.push(item.endTimestamp);
    });
    setPollingInterval(getTimestamp(Math.min.apply(Math, endtimestamp)));
  };
  useEffect(handlePollingInterval, [data]);
  var _useState3 = useState(MISSIONS_TYPES.EXPLORER),
    _useState4 = _slicedToArray(_useState3, 2),
    filterData = _useState4[0],
    setFilterData = _useState4[1];
  var _useFilterData = useFilterData(data, filterData, FILTER_TYPES.MISSIONS),
    filteredValue = _useFilterData.filteredValue;
  return {
    filteredValue: filteredValue,
    setFilterData: setFilterData,
    filterData: filterData
  };
};
export default useMissionsRequest;
var missionsFeatureStateDefault = {
  filteredValue: [],
  setFilterData: null,
  filterData: null
};