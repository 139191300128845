import generateRender from 'shared/utils/generateRender';
import DefaultLevelUpText from './DefaultLevelUpText';
import HriLevelUpText from './HriLevelUpText';
import MillelacsLevelUpText from './MillelacsLevelUpText';

const LevelUpText = generateRender({
  default: DefaultLevelUpText,
  hri: HriLevelUpText,
  millelacs: MillelacsLevelUpText,
  jamul: MillelacsLevelUpText,
});

export default LevelUpText;

