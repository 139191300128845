import React, { useContext } from 'react';
import styled from 'styled-components';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ThemeContext from 'shared/context/ThemeContext';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import Button from '../../../../../../../components/Button/Button';

export default function ErrorHandlingWrapper(props) {
  const { buttonClickHandler, setModalState, error, buttonText } = props;
  const { ErrorHandlingWrapper } = useContext(ThemeContext);

  const supportCallBack = () => {
    setModalState(false);
    ENVIRONMENT_VARIABLES.ZENDESK_URL && window.open(ENVIRONMENT_VARIABLES.ZENDESK_URL, '_blank');
  };

  return (
    <div>
      <ServerError>{error}</ServerError>
      <Button
        imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
        imageStyle={{ width: '210px', cursor: 'pointer' }}
        containerStyle={{
          alignItems: 'center',
          alignSelf: 'center',
          justifyContent: 'center',
          display: 'flex',
          width: '100%',
        }}
        onClick={() => (buttonClickHandler ? buttonClickHandler() : supportCallBack())}
        label={buttonText}
        textStyle={ErrorHandlingWrapper.ButtonTextStyle}
      />
    </div>
  );
}

const ServerError = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
`;

