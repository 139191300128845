import React from 'react';
import ProfileCardFrame from './components/ProfileCardFrame';
import Button from '../../../../components/Button/Button';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import CopySelector from 'shared/utils/copySelector';
import theme from 'shared/assets/style/theme';
import styled from 'styled-components';
import { PROFILE_BALANCES_NAME } from 'shared/screens/RewardCenter/constants';
import LinkText from '../../../../components/LinkText/LinkText';
import CustomScrollBar from '../../../../components/CustomScrollBar';
import './scrollBarStyles.scss';

const RewardProfileTab = props => {
  const {
    copys,
    playerId,
    name,
    memberLevel,
    profileBalances,
    showBalances,
    handleLinkAccountForm,
    handleRegisterRewardCard,
    enableOrderCard,
    loyaltyExpiryDate,
    handleCheckBenefits,
    rewardCenterAssets,
    profile: { displayFreePlayBalance },
  } = props;

  const frameAsset = rewardCenterAssets?.REWARD_CENTER_PROFILE_FRAME_CARD;

  return (
    <CustomScrollBar customClassNames="custom-scrollbar-profile-tab">
      <div className="RewardCenterProfileDetail">
        {showBalances ? (
          <>
            <h3>
              {getText(TEXT_KEY.WELCOME)} {name} {playerId}
            </h3>
            <h6>
              {getText(TEXT_KEY.TIER)}: {memberLevel}
            </h6>
            <div className="RewardCenterProfileItems" style={!loyaltyExpiryDate ? { marginTop: '70px' } : null}>
              {profileBalances
                .filter(({ name }) => displayFreePlayBalance || name !== PROFILE_BALANCES_NAME.FREE_PLAY)
                .map(({ displayName, currency, balance }, index) => (
                  <ProfileCardFrame key={index} frameAsset={frameAsset} isLinked={showBalances}>
                    <h4>{displayName}</h4>
                    <h6>{getText(TEXT_KEY.AVAILABLE_BALANCE)}</h6>
                    {balance || balance == 0 ? (
                      <p>
                        <span style={{ fontSize: 18 }}>{currency === 'USD' ? '$' : ''}</span>
                        {balance}
                      </p>
                    ) : (
                      <p>{getText(TEXT_KEY.NA)}</p>
                    )}
                  </ProfileCardFrame>
                ))}
            </div>
          </>
        ) : (
          <>
            <div className="unlinkedProfileContainer">
              {enableOrderCard && (
                <ProfileCardFrame frameAsset={frameAsset} isLinked={showBalances}>
                  <h4>
                    {CopySelector.getCopy(CopySelector.COPY_TYPES.REWARD_CENTER_PROFILE, copys).register_card.title}
                  </h4>
                  <p>{CopySelector.getCopy(CopySelector.COPY_TYPES.REWARD_CENTER_PROFILE, copys).register_card.text}</p>
                  <Button
                    imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                    onClick={handleRegisterRewardCard}
                    label={
                      CopySelector.getCopy(CopySelector.COPY_TYPES.REWARD_CENTER_PROFILE, copys).register_card.button
                    }
                  />
                </ProfileCardFrame>
              )}
              <ProfileCardFrame frameAsset={frameAsset} isLinked={showBalances}>
                <h4>{CopySelector.getCopy(CopySelector.COPY_TYPES.REWARD_CENTER_PROFILE, copys).link_card.title}</h4>
                <p>{CopySelector.getCopy(CopySelector.COPY_TYPES.REWARD_CENTER_PROFILE, copys).link_card.text}</p>
                <Button
                  imageSource={rewardCenterAssets.REWARD_CENTER_PROFILE_LINK_BUTTON}
                  onClick={handleLinkAccountForm}
                  containerStyle={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '70%',
                    cursor: 'pointer',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                />
              </ProfileCardFrame>
            </div>
            <FooterContainer>
              <LinkText customStyle={FooterLinkTextStyle} onClick={handleCheckBenefits}>
                {getText(TEXT_KEY.LINK_OR_REGISTER_BENEFITS_CLICK_HERE)}
              </LinkText>
              <FooterText>{getText(TEXT_KEY.LINK_OR_REGISTER_BENEFITS_CHECK)}</FooterText>
            </FooterContainer>
          </>
        )}
      </div>
    </CustomScrollBar>
  );
};

const profileDetailTextStyle = `
  font-size: 1.5em;
  color: ${theme.palette.common[1]};
  text-shadow-color: ${theme.palette.common[25]};
  text-shadow-offset: 0px ${0.6}px;
  text-shadow-radius: 2px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterLinkTextStyle = {
  textAlign: 'center',
  marginRight: '0.3em',
  fontSize: '1.5em',
  textShadow: `${theme.palette.common[25]} 0px 0.6px 2px`,
};

const FooterText = styled.p`
  ${profileDetailTextStyle}
  text-align: center;
`;

export default RewardProfileTab;

