export default {
  type: 'object',
  properties: {
    0: {
      type: 'object',
      properties: {
        casinoDollarBalance: {
          type: 'integer'
        }
      },
      required: ['casinoDollarBalance']
    }
  },
  required: ['0']
};