import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import usePaymentInfoForm from '../hooks/usePaymentInfoForm';
import { paymentFormKeys, paymentFormPlaceHolders } from '../utils';
import getValuesArray from 'shared/utils/getValuesArray';
import './PaymentInfo.scss';
import { COUNTRY, STATES } from 'shared/utils/kyc/Constants';
import { KYC_STATUS } from 'shared/utils/kyc/Constants';
import { useSelector } from 'shared/node_modules/react-redux';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import Button from '../../../components/Button/Button';
import { getDefaultCountryValue } from 'shared/utils/kyc/getDefaultCountry';

export default function TiPaymentInfo(props) {
  const { KYCResult, KYCInfo } = useSelector(state => state.playerInfo);
  const { onSubmit, nextHandle, errorMessage } = props;
  const isKycPassed = KYCResult === KYC_STATUS.PASS;

  const submitFormHandler = formInputs => {
    onSubmit(nextHandle, formInputs);
  };

  const { values, handleSubmit, handleBlur, handleChange, isValid } = usePaymentInfoForm({
    isKycPassed,
    KYCInfo,
    submitFormHandler,
  });

  return (
    <Form className="form-container payment-info" onSubmit={handleSubmit}>
      <RowHolderCenterItem>
        <span className="message">{getText(TEXT_KEY.AGE_RESTRICTION)}</span>
      </RowHolderCenterItem>
      <Form.Group className="form-input-group">
        {!isKycPassed && (
          <>
            <RowHolder>
              <ColumnHolder>
                <div className="inputField">
                  <label className="inputLabel">
                    <Form.Control
                      className={'transparentInput'}
                      autoCapitalize="none"
                      onChange={handleChange(paymentFormKeys.FIRST_NAME)}
                      onBlur={handleBlur(paymentFormKeys.FIRST_NAME)}
                      value={values[paymentFormKeys.FIRST_NAME]}
                      placeholder={paymentFormPlaceHolders[paymentFormKeys.FIRST_NAME]}
                    />
                  </label>
                </div>
              </ColumnHolder>
              <ColumnHolder>
                <div className="inputField">
                  <label className="inputLabel">
                    <Form.Control
                      className={'transparentInput'}
                      autoCapitalize="none"
                      onChange={handleChange(paymentFormKeys.LAST_NAME)}
                      onBlur={handleBlur(paymentFormKeys.LAST_NAME)}
                      value={values[paymentFormKeys.LAST_NAME]}
                      placeholder={paymentFormPlaceHolders[paymentFormKeys.LAST_NAME]}
                    />
                  </label>
                </div>
              </ColumnHolder>
            </RowHolder>
            <DateOfBirthTitle>{getText(TEXT_KEY.DATE_OF_BIRTH)}</DateOfBirthTitle>
            <RowHolder>
              <ColumnHolder>
                <div className="inputField">
                  <label className="inputLabel">
                    <Form.Control
                      className={'transparentInput'}
                      autoCapitalize="none"
                      onChange={handleChange(paymentFormKeys.YEAR)}
                      onBlur={handleBlur(paymentFormKeys.YEAR)}
                      value={values[paymentFormKeys.YEAR]}
                      placeholder={paymentFormPlaceHolders[paymentFormKeys.YEAR]}
                    />
                  </label>
                </div>
              </ColumnHolder>
              <ColumnHolder>
                <div className="inputField">
                  <label className="inputLabel">
                    <div className="inputWithDropdown">
                      <Form.Control
                        className={'transparentInput'}
                        autoCapitalize="none"
                        as="select"
                        onChange={handleChange(paymentFormKeys.MONTH)}
                        onBlur={handleBlur(paymentFormKeys.MONTH)}
                        value={values[paymentFormKeys.MONTH]}
                      >
                        <option disable hidden value="">
                          {paymentFormPlaceHolders[paymentFormKeys.MONTH]}
                        </option>
                        {getValuesArray(12).map(option => {
                          return (
                            <option key={option.value} className="form-input-options" value={option.value}>
                              {option.label}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <DropDownCaret />
                    </div>
                  </label>
                </div>
              </ColumnHolder>
              <ColumnHolder>
                <div className="inputField">
                  <label className="inputLabel">
                    <div className="inputWithDropdown">
                      <Form.Control
                        className={'transparentInput'}
                        autoCapitalize="none"
                        as="select"
                        onChange={handleChange(paymentFormKeys.DAY)}
                        onBlur={handleBlur(paymentFormKeys.DAY)}
                        value={values[paymentFormKeys.DAY]}
                      >
                        <option disable hidden value="">
                          {paymentFormPlaceHolders[paymentFormKeys.DAY]}
                        </option>
                        {getValuesArray(31).map(option => {
                          return (
                            <option key={option.value} className="form-input-options" value={option.value}>
                              {option.label}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <DropDownCaret />
                    </div>
                  </label>
                </div>
              </ColumnHolder>
            </RowHolder>
          </>
        )}
        <RowHolder>
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <Form.Control
                  className={'transparentInput'}
                  autoCapitalize="none"
                  onChange={handleChange(paymentFormKeys.ADDRESS_LINE_1)}
                  onBlur={handleBlur(paymentFormKeys.ADDRESS_LINE_1)}
                  value={values[paymentFormKeys.ADDRESS_LINE_1]}
                  placeholder={paymentFormPlaceHolders[paymentFormKeys.ADDRESS_LINE_1]}
                />
              </label>
            </div>
          </ColumnHolder>
        </RowHolder>
        <RowHolder>
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <Form.Control
                  className={'transparentInput'}
                  autoCapitalize="none"
                  onChange={handleChange(paymentFormKeys.ADDRESS_LINE_2)}
                  onBlur={handleBlur(paymentFormKeys.ADDRESS_LINE_2)}
                  value={values[paymentFormKeys.ADDRESS_LINE_2]}
                  placeholder={paymentFormPlaceHolders[paymentFormKeys.ADDRESS_LINE_2]}
                />
              </label>
            </div>
          </ColumnHolder>
        </RowHolder>
        <LabelHolder>{getText(TEXT_KEY.COUNTRY)}</LabelHolder>
        <RowHolderWithLabel>
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <div className="inputWithDropdown">
                  <Form.Control
                    className={'transparentInput'}
                    autoCapitalize="none"
                    as="select"
                    onChange={handleChange(paymentFormKeys.COUNTRY)}
                    onBlur={handleBlur(paymentFormKeys.COUNTRY)}
                    value={values[paymentFormKeys.COUNTRY]}
                  >
                    {COUNTRY().map(option => {
                      return (
                        <option key={option.value} className="form-input-options" value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <DropDownCaret />
                </div>
              </label>
            </div>
          </ColumnHolder>
        </RowHolderWithLabel>
        <RowHolder>
          <ColumnHolder>
            <div className="inputField">
              <label className="inputLabel">
                <Form.Control
                  className={'transparentInput'}
                  autoCapitalize="none"
                  onChange={handleChange(paymentFormKeys.CITY)}
                  onBlur={handleBlur(paymentFormKeys.CITY)}
                  value={values[paymentFormKeys.CITY]}
                  placeholder={paymentFormPlaceHolders[paymentFormKeys.CITY]}
                />
              </label>
            </div>
          </ColumnHolder>
        </RowHolder>
        <LabelHolder>{getText(TEXT_KEY.STATE_PROVINCE)}</LabelHolder>
        <RowHolderWithLabel>
          <ColumnHolder>
            <div className="inputField">
              <label className="halfInputField">
                <div className="inputWithDropdown">
                  <Form.Control
                    className={'transparentInput'}
                    autoCapitalize="none"
                    as="select"
                    onChange={handleChange(paymentFormKeys.STATE)}
                    onBlur={handleBlur(paymentFormKeys.STATE)}
                    value={values[paymentFormKeys.STATE]}
                  >
                    {STATES[values[paymentFormKeys.COUNTRY] || getDefaultCountryValue]().map(option => {
                      return (
                        <option
                          selected={option.value === values[paymentFormKeys.STATE]}
                          key={option.value}
                          className="form-input-options"
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <DropDownCaret />
                </div>
              </label>
            </div>
          </ColumnHolder>
          <ColumnHolder>
            <div className="inputField">
              <label className="halfInputField">
                <Form.Control
                  className={'transparentInput'}
                  autoCapitalize="none"
                  onChange={handleChange(paymentFormKeys.ZIP)}
                  onBlur={handleBlur(paymentFormKeys.ZIP)}
                  value={values[paymentFormKeys.ZIP]}
                  placeholder={paymentFormPlaceHolders[paymentFormKeys.ZIP]}
                />
              </label>
            </div>
          </ColumnHolder>
        </RowHolderWithLabel>
        <MessageHolder>{errorMessage && <div className="message">{errorMessage}</div>}</MessageHolder>
        <Button
          imageSource={
            isValid ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY) : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
          }
          label={getText(TEXT_KEY.CONTINUE_BUTTON)}
          onClick={handleSubmit}
          disabled={!isValid}
          containerStyle={styles.buttonContainer}
          textStroke={BUTTON_STROKES.PRIMARY}
        />
      </Form.Group>
    </Form>
  );
}

const DateOfBirthTitle = styled.p`
  margin-top: -5px;
  margin-bottom: -5px;
  margin-left: 25px;
  color: #d4b8be;
`;

const LabelHolder = styled(DateOfBirthTitle)`
  margin-bottom: -10px;
`;

const ColumnHolder = styled(Col)`
  padding: 0.1rem 0.5rem;
`;

const RowHolder = styled(Row)`
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 5px;
`;

const RowHolderCenterItem = styled(RowHolder)`
  justify-content: center;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
`;

const RowHolderWithLabel = styled(RowHolder)`
  margin-top: 0.5em;
`;

const MessageHolder = styled(RowHolder)`
  justify-content: center;
  color: red;
  text-align: center;
`;

const DropDownCaret = styled.img.attrs(() => ({
  src: assetSource(ASSET_KEY.CARET_DOWN),
  className: 'downIcon',
  alt: 'caret_down',
}))`
  pointer-events: none;
`;

const styles = {
  buttonContainer: {
    margin: '10px auto',
  },
};

