import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_IS_DOWNLOADING, CLEAR_IS_DOWNLOADING, SET_MAX_DOWNLOADED_GAMES, SET_DOWNLOADED_GAMES, SET_DOWNLOAD_PROGRESS, CLEAR_DOWNLOAD_PROGRESS, REMOVE_DOWNLOADED_GAME, SET_DOWNLOAD_ERROR_MESSAGE, CLEAR_DOWNLOAD_ERROR_MESSAGE, SET_DOWNLOAD_FAILED_GAMES } from '../actions/types';
var initialState = {
  isDownloading: '',
  maxDownloadedGames: '',
  downloadedGames: [],
  downloadProgress: 0,
  downloadErrorMessage: '',
  downloadFailedGames: []
};
var gameDownloadReducer = function gameDownloadReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_IS_DOWNLOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDownloading: action.payload
      });
    case CLEAR_IS_DOWNLOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDownloading: ''
      });
    case SET_MAX_DOWNLOADED_GAMES:
      return _objectSpread(_objectSpread({}, state), {}, {
        maxDownloadedGames: action.payload
      });
    case SET_DOWNLOADED_GAMES:
      return _objectSpread(_objectSpread({}, state), {}, {
        downloadedGames: _objectSpread(_objectSpread({}, state.downloadedGames), {}, _defineProperty({}, action.payload.gameId, action.payload))
      });
    case REMOVE_DOWNLOADED_GAME:
      var downloadedGames = _objectSpread({}, state.downloadedGames);
      delete downloadedGames[action.payload];
      return _objectSpread(_objectSpread({}, state), {}, {
        downloadedGames: downloadedGames
      });
    case SET_DOWNLOAD_PROGRESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        downloadProgress: action.payload
      });
    case CLEAR_DOWNLOAD_PROGRESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        downloadProgress: 0
      });
    case SET_DOWNLOAD_ERROR_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        downloadErrorMessage: action.payload
      });
    case CLEAR_DOWNLOAD_ERROR_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        downloadErrorMessage: ''
      });
    case SET_DOWNLOAD_FAILED_GAMES:
      return _objectSpread(_objectSpread({}, state), {}, {
        downloadFailedGames: _objectSpread(_objectSpread({}, state.downloadFailedGames), {}, _defineProperty({}, action.payload, action.payload))
      });
    default:
      return state;
  }
};
export default gameDownloadReducer;