import React from 'react';
import { TextInput } from '../../../components/TextInput';
import useThemeContext from 'shared/context/hooks/useThemeContext';
function FormInput(props) {
  const { SignUp } = useThemeContext();
  return <TextInput theme={SignUp.FormInputTheme} {...props} />;
}

export default FormInput;

