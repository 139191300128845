import AppBackgroundType from 'shared/utils/AppBackgroundType';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export const getAppBackgroundImage = (type, backgroundPromo, promotionImagesUrl) => {
  let background = backgroundPromo ? `${promotionImagesUrl}/${backgroundPromo}` : '';
  switch (type) {
    case AppBackgroundType.VIP_GAMES:
      return background || assetSource(ASSET_KEY.VIP_BACKGROUND);
    case AppBackgroundType.NORMAL:
    case AppBackgroundType.TABLE_GAMES:
    case AppBackgroundType.FAVOURITES:
    default:
      return background || assetSource(ASSET_KEY.LOBBY_BACKGROUND);
  }
};
