import styled from 'styled-components';
import Button from '../../components/Button/Button';

const SportsAccessContainer = styled.div`
  height: 50vh;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
  justify-content: stretch;
`;

const CardsImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 86%;
  bottom: 2%;
  display: flex;
  justify-content: center;
`;

const CardsImage = styled.img`
  position: absolute;
  height: 100%;
  bottom: 0;
`;

const Divider = styled.img`
  position: absolute;
  top: 4rem;
`;

const SportsAccessHeader = styled.span`
  font-size: 2.5rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
`;

const SportsAccessInfo = styled.p`
  position: relative;
  font-size: 1.4rem;
  text-align: center;
  font-weight: 700;
  margin-top: 3vh;
  margin-bottom: 0.5rem;
  top: -15px;
`;

const SportsAccessButtons = styled.div`
  position: absolute;
  bottom: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  width: 28%;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
`;

export {
  SportsAccessContainer,
  CardsImageContainer,
  CardsImage,
  Divider,
  SportsAccessHeader,
  SportsAccessInfo,
  SportsAccessButtons,
  StyledButton,
};
