import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { useEffect } from 'react';
import QueryParamsProxy from '../../../utils/queryParamProxy';
import RouteParamConstants from '../../../utils/routeParamConstants';
function useCheckEventsQueryParam(eventsData) {
  var selectedCardId = QueryParamsProxy.findParam(RouteParamConstants.PARAM_EVENT_CARD_ID, false);
  var selectedCardIndex = eventsData === null || eventsData === void 0 ? void 0 : eventsData.findIndex(function (item) {
    var _item$eventId;
    return ((_item$eventId = item.eventId) === null || _item$eventId === void 0 ? void 0 : _item$eventId.toString()) === (selectedCardId === null || selectedCardId === void 0 ? void 0 : selectedCardId.toString());
  });
  useEffect(function () {
    return function () {
      return QueryParamsProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_EVENT_CARD_ID, undefined));
    };
  }, []);
  return {
    selectedCardId: selectedCardId,
    selectedCardIndex: selectedCardIndex
  };
}
export default useCheckEventsQueryParam;