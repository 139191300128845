import _slicedToArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _excluded = ["year", "month", "day"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import * as yup from '../../../node_modules/yup';
import Validators from '../../../components/Form/InputValidator';
import { getText, TEXT_KEY } from '../../../utils/localization';
import QueryParamProxy from '../../../utils/queryParamProxy';
import RouteParamConstants from '../../../utils/routeParamConstants';
import { getDefaultCountryValue } from '../../../utils/kyc/getDefaultCountry';
export var registerCardsFormKeys = {
  FIRST_NAME: 'firstname',
  LAST_NAME: 'lastname',
  EMAIL: 'email',
  PHONE: 'phone',
  PIN: 'pin',
  YEAR: 'year',
  MONTH: 'month',
  DAY: 'day',
  ADDRESS_LINE_1: 'address1',
  ADDRESS_LINE_2: 'address2',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip',
  COUNTRY: 'country',
  PROPERTY: 'propertyId',
  TOKEN: 'token',
  IS_PROPERTY_SELECTED: 'isPropertySelected'
};
var phoneRegExp = /^\d{10}$/;
var pinRegExp = /^\d{4}$/;
var dateRegExp = /^\d{4}-\d{2}-\d{2}$/;
var checkRequired = function checkRequired(fieldsObject, field, value) {
  var _fieldsObject$field;
  if ((_fieldsObject$field = fieldsObject[field]) !== null && _fieldsObject$field !== void 0 && _fieldsObject$field.required) {
    if (value) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
export var validationSchema = function validationSchema(isWeb, property, formFields) {
  var _yup$object;
  return yup.object((_yup$object = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_yup$object, registerCardsFormKeys.FIRST_NAME, yup.string().required('Firstname is required')), registerCardsFormKeys.LAST_NAME, yup.string().required('Lastname is required')), registerCardsFormKeys.EMAIL, yup.string().email('Invalid email').required('Email is required')), registerCardsFormKeys.PHONE, yup.string().matches(phoneRegExp, 'Invalid phone number').test('Address is required', 'Must be String', function (value) {
    return checkRequired(formFields, registerCardsFormKeys.PHONE, value);
  })), registerCardsFormKeys.MONTH, yup.string().required('Month is required')), registerCardsFormKeys.DAY, yup.string().required('Day is required')), registerCardsFormKeys.YEAR, yup.string().test('Date of Birth is required', 'Age must be ove 18', function (value) {
    var dob = "".concat(value, "-").concat(addZeroPrefixToValue(this.parent[registerCardsFormKeys.MONTH]), "-").concat(addZeroPrefixToValue(this.parent[registerCardsFormKeys.DAY]));
    if (!dateRegExp.test(dob)) return false;
    return Validators.ADULT_ONLY(dob);
  })), registerCardsFormKeys.TOKEN, !isWeb ? yup.string() : yup.string().required('Captcha is required')), registerCardsFormKeys.PROPERTY, !property ? yup.string() : yup.string().required('You need to select a property')), registerCardsFormKeys.ADDRESS_LINE_1, yup.string().test('Address is required', 'Must be String', function (value) {
    return checkRequired(formFields, registerCardsFormKeys.ADDRESS_LINE_1, value);
  })), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_yup$object, registerCardsFormKeys.ADDRESS_LINE_2, yup.string().test('Address is required', 'Must be String', function (value) {
    return checkRequired(formFields, registerCardsFormKeys.ADDRESS_LINE_2, value);
  })), registerCardsFormKeys.COUNTRY, yup.string().test('Country is required', 'Must be Selected', function (value) {
    return checkRequired(formFields, registerCardsFormKeys.COUNTRY, value);
  })), registerCardsFormKeys.CITY, yup.string().test('City is required', 'Must be String', function (value) {
    return checkRequired(formFields, registerCardsFormKeys.CITY, value);
  })), registerCardsFormKeys.STATE, yup.string().test('State is required', 'Must be Selected', function (value) {
    return checkRequired(formFields, registerCardsFormKeys.STATE, value);
  })), registerCardsFormKeys.ZIP, yup.string().test('Zip is required', 'Must be String', function (value) {
    return checkRequired(formFields, registerCardsFormKeys.ZIP, value);
  })), registerCardsFormKeys.PIN, yup.string().matches(pinRegExp, 'Invalid pin number').test('Pin Number is required', 'Must be String', function (value) {
    return checkRequired(formFields, registerCardsFormKeys.PIN, value);
  }))));
};
export var addZeroPrefixToValue = function addZeroPrefixToValue(value) {
  var newValue = value;
  if (parseInt(newValue, 10) < 10) {
    newValue = "0".concat(parseInt(newValue, 10));
  }
  return newValue;
};
export var generateReqObject = function generateReqObject(dataObject) {
  var year = dataObject.year,
    month = dataObject.month,
    day = dataObject.day,
    restData = _objectWithoutProperties(dataObject, _excluded);
  var reqData = _objectSpread(_objectSpread({}, restData), {}, {
    dob: "".concat(year, "-").concat(addZeroPrefixToValue(month), "-").concat(addZeroPrefixToValue(day))
  });
  return reqData;
};
export var getValuesArray = function getValuesArray(amount) {
  var numbersArray = Array.from(Array(amount).keys());
  return numbersArray.map(function (num) {
    var label = "".concat(num + 1);
    if ((num + 1).length === 1) {
      label = "0".concat(label);
    }
    return {
      value: num + 1,
      label: label
    };
  });
};
export var setPropertyId = function setPropertyId(userProperty, propertyId) {
  return userProperty === 1 ? propertyId : userProperty;
};
export var checkResponseData = function checkResponseData(data, error) {
  var _data$;
  if (data === undefined && error === undefined) {
    return null;
  }
  if (data !== null && data !== void 0 && (_data$ = data[0]) !== null && _data$ !== void 0 && _data$.success) {
    var _data$2;
    return {
      success: true,
      message: ((_data$2 = data[0]) === null || _data$2 === void 0 ? void 0 : _data$2.msg) || getText(TEXT_KEY.REGISTER_REWARD_CARD_SUCCESS)
    };
  } else {
    var _data$3;
    return {
      success: false,
      message: (data === null || data === void 0 || (_data$3 = data[0]) === null || _data$3 === void 0 ? void 0 : _data$3.error) || (error === null || error === void 0 ? void 0 : error.error) || getText(TEXT_KEY.ONE_TIME_PASSWORD_AUTENTICATION_ERROR)
    };
  }
};
export var closeModal = function closeModal() {
  return QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, undefined));
};
export var getFieldsObject = function getFieldsObject(fieldsArray) {
  if (!fieldsArray) {
    return {};
  }
  var object = fieldsArray.reduce(function (acc, elem) {
    acc[elem['name']] = elem;
    return acc;
  }, {});
  return object;
};
export var generateInitialValues = function generateInitialValues(fieldsArray) {
  var isWeb = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var userProperty = arguments.length > 2 ? arguments[2] : undefined;
  if (!fieldsArray) {
    return {};
  }
  var object = fieldsArray.reduce(function (acc, elem) {
    acc[elem['name']] = elem['name'] === registerCardsFormKeys.COUNTRY ? getDefaultCountryValue : '';
    return acc;
  }, {});
  if (isWeb) {
    object.token = '';
  }
  if (userProperty > 0) {
    object.propertyId = userProperty;
  }
  object.isPropertySelected = false;
  return object;
};
export var checkUserProperty = function checkUserProperty(propertySelector, propertyId) {
  return propertySelector.find(function (property) {
    return property.propertyId === Number(propertyId);
  });
};
export var checkIfAllFiledsHasValue = function checkIfAllFiledsHasValue(values, propertyRequiredFields) {
  var isWeb = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var filteredRequiredData = Object.fromEntries(Object.entries(propertyRequiredFields).filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    return value.required;
  }));
  var requiredFiledsArray = Object.keys(filteredRequiredData);
  if (isWeb) requiredFiledsArray.push(registerCardsFormKeys.TOKEN);
  for (var _i = 0, _requiredFiledsArray = requiredFiledsArray; _i < _requiredFiledsArray.length; _i++) {
    var key = _requiredFiledsArray[_i];
    if (!values[key]) {
      return false;
    }
  }
  return true;
};
export var checkOnInputPhoneNumber = function checkOnInputPhoneNumber(inputValue) {
  var _newValue;
  if (!inputValue) return '';
  var newValue = '';
  newValue = inputValue.replace(/[^0-9]/g, '');
  if (((_newValue = newValue) === null || _newValue === void 0 ? void 0 : _newValue.toString().length) > 10) newValue = newValue.replace(/.$/, '');
  return newValue;
};