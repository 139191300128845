import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { TermsText } from './styledComponents';
import LinkText from '../../../../components/LinkText/LinkText';

const DefaultFormFooter = ({ termsAndConditionsUrl }) => {
  return (
    <TermsText>
      {getText(TEXT_KEY.REGISTER_REWARD_TERMS_AGREE_MESSAGE)}{' '}
      <LinkText onClick={() => window.open(termsAndConditionsUrl)}>{getText(TEXT_KEY.TERMS_OF_SERVICE)}</LinkText>
    </TermsText>
  );
};

export default DefaultFormFooter;

