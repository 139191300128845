import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import ImageBoundsWrapper from '../../../../components/ImageBoundsWrapper';
import { Styles, OTPWrapper, OTPTextWrapper, TermsText } from './styledComponents';
import LinkText from '../../../../components/LinkText/LinkText';

const OTPFormFooter = ({ showOTPConsents, activeOTPCheckbox, handleOPTCheckbox, selectedPropertyObj }) => {
  return (
    <>
      {showOTPConsents ? (
        <OTPWrapper>
          <ImageBoundsWrapper
            source={activeOTPCheckbox ? assetSource(ASSET_KEY.CHECKBOX) : assetSource(ASSET_KEY.CHECKBOX_DESELECTED)}
            ibw={require(asset`checkbox.ibw`)}
            style={Styles.otpConsentsCheckbox}
            className="checkbox-image"
            onClick={handleOPTCheckbox}
          />
          <OTPTextWrapper>
            {getText(TEXT_KEY.REGISTER_CARD_OTP_CONSENTS)}{' '}
            <LinkText
              customStyle={{ fontWeight: 'bold' }}
              onClick={() => window.open(selectedPropertyObj.loyaltyTermsAndConditions)}
            >
              {getText(TEXT_KEY.TERMS_OF_SERVICE)}
            </LinkText>{' '}
            {getText(TEXT_KEY.AND)}{' '}
            <LinkText
              customStyle={{ fontWeight: 'bold' }}
              onClick={() => window.open(selectedPropertyObj.loyaltyPrivacyPolicy)}
            >
              {getText(TEXT_KEY.PRIVACY_POLICY)}
            </LinkText>{' '}
          </OTPTextWrapper>
        </OTPWrapper>
      ) : (
        <TermsText>
          {getText(TEXT_KEY.REGISTER_REWARD_TERMS_AGREE_MESSAGE)}{' '}
          <LinkText
            customStyle={{ fontWeight: 'bold' }}
            onClick={() => window.open(selectedPropertyObj.loyaltyTermsAndConditions)}
          >
            {getText(TEXT_KEY.TERMS_OF_SERVICE)}
          </LinkText>
        </TermsText>
      )}
    </>
  );
};

export default OTPFormFooter;

