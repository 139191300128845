import React, { Component } from 'react';
import { casinoIdStatus } from 'shared/api/PlayerInfoConstants';
import { REG_EXP } from 'shared/constants';
import SCustomerSupport from 'shared/screens/Settings/components/CustomerSupport';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import AutoForm from '../../../components/Form';
import ImageBW from '../../../components/ImageBoundsWrapper';
import Button from '../../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import LinkText from '../../../components/LinkText/LinkText';

const CustomerSupportForm = require(asset`forms/customerSupport.form`);

export default class CustomerSupport extends Component {
  render() {
    const success = (
      <p className="popup-message-text">
        {getText(TEXT_KEY.MESSAGE_SUCCESSFULLY_SENT)}
        <br />
        {getText(TEXT_KEY.WE_WILL_GET_BACK_TO_YOU)}
      </p>
    );

    const error = (
      <p className="popup-message-text">
        {getText(TEXT_KEY.SOMETHING_WENT_WRONG)}
        <br />
        {getText(TEXT_KEY.TRY_AGAIN_OR_CONTACT_SUPPORT)}
      </p>
    );

    const guestMessage = (
      <p className="popup-message-text">
        {getText(TEXT_KEY.PLEASE_SUBMIT_A_REQUEST)}
        <LinkText href={ENVIRONMENT_VARIABLES.ZENDESK_URL}>{getText(TEXT_KEY.SUPPORT_PORTAL)}</LinkText>
      </p>
    );

    return (
      <SCustomerSupport
        {...this.props}
        navigation={this.props.navigation}
        CustomerSupportForm={CustomerSupportForm}
        render={args => {
          const { layout, messageResponse, responseSuccess, submitSupportMessage, propsCasinoIdStatus, isGuest } = args;

          const { goBackHandler, onShowPopUp, showPopUp, screensEnum, email } = this.props;

          const showPopUpHandler = success => {
            onShowPopUp(success ? screensEnum.messageSent : screensEnum.messageSentError);
          };

          const hasTestEmail = REG_EXP.validTestEmail.test(email);

          return isGuest || propsCasinoIdStatus === casinoIdStatus.GUEST || hasTestEmail ? (
            <div className="popup-content-container" style={{ paddingBottom: '10vmin' }}>
              <div>{guestMessage}</div>
            </div>
          ) : (
            <>
              {!showPopUp ? (
                <AutoForm
                  layout={layout}
                  prev={goBackHandler}
                  next={() => {
                    submitSupportMessage(showPopUpHandler);
                  }}
                  serverResponse={messageResponse}
                  customStyle={{
                    position: 'absolute',
                    bottom: '2.5rem',
                    margin: 'auto',
                    left: 0,
                    right: 0,
                  }}
                  formCustomContent
                  customConfirmButton
                  hideTopBar
                />
              ) : (
                <div className="popup-content-container" style={{ paddingBottom: '10vmin' }}>
                  <div>
                    <div className="sent-message-image-container" onClick={goBackHandler}>
                      <ImageBW
                        source={assetSource(ASSET_KEY.SEND_MESSAGE_ICON)}
                        ibw={require(asset`./images/settings/send-message-icon.ibw`)}
                        style={{ objectFit: 'contain' }}
                        className="sent-message-image"
                        innerStyle={{
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                    {responseSuccess ? success : error}
                  </div>
                  <Button
                    textStroke={BUTTON_STROKES.PRIMARY}
                    imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                    onClick={goBackHandler}
                    label={getText(TEXT_KEY.DONE)}
                    containerStyle={{
                      position: 'absolute',
                      bottom: '3.5rem',
                    }}
                    objectFit={'contain'}
                  />
                </div>
              )}
            </>
          );
        }}
      />
    );
  }
}

