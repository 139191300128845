import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getUrlsQuery } from './queries';
export var urlsApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getUrls: builder.query(getUrlsQuery)
    };
  }
});
urlsApi.enhanceEndpoints({
  addTagTypes: ['Urls'],
  keepUnusedDataFor: 60
});
var useLazyGetUrlsQuery = urlsApi.useLazyGetUrlsQuery,
  getUrls = urlsApi.endpoints.getUrls;
export { useLazyGetUrlsQuery, getUrls };