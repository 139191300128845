import { SET_TOKEN, SET_RESTART, SET_AUTHORIZED, SET_LAST_ACTIVE, CLEAR_TOKEN, SET_FB_TOKEN, UPDATE_LAST_LOGIN_INFO, CLEAR_LOGIN_INFO, SET_GOOGLE_TOKEN, CLEAR_GOOGLE_TOKEN } from './types';
export var setFBToken = function setFBToken(fbToken) {
  return {
    type: SET_FB_TOKEN,
    payload: fbToken
  };
};
export var setGoogleToken = function setGoogleToken(googleToken) {
  return {
    type: SET_GOOGLE_TOKEN,
    payload: googleToken
  };
};
export var setToken = function setToken(token) {
  return {
    type: SET_TOKEN,
    payload: token
  };
};
export var setRestart = function setRestart(token) {
  return {
    type: SET_RESTART,
    payload: token
  };
};
export var setAuthorized = function setAuthorized(auth) {
  return {
    type: SET_AUTHORIZED,
    payload: auth
  };
};
export var setLastActive = function setLastActive(timestamp) {
  return {
    type: SET_LAST_ACTIVE,
    payload: timestamp
  };
};
export var clearToken = function clearToken(clearPendingMessages) {
  return {
    type: CLEAR_TOKEN,
    payload: clearPendingMessages
  };
};
export var clearGoogleToken = function clearGoogleToken() {
  return {
    type: CLEAR_GOOGLE_TOKEN
  };
};
export var setLastLoginInfo = function setLastLoginInfo(loginInfo) {
  return {
    type: UPDATE_LAST_LOGIN_INFO,
    payload: loginInfo
  };
};
export var clearLastLoginInfo = function clearLastLoginInfo() {
  return {
    type: CLEAR_LOGIN_INFO
  };
};