import React from 'react';
import Button from '../../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import { MessageContainerWrapper, MessageTextWrapper, MessageText, ErrorButtonsWrapper } from './styledComponents';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

function MessageContainer({ errorMessage, closeModal, response, error, message, getText, textKeys: TEXT_KEY }) {
  return (
    <MessageContainerWrapper>
      <MessageTextWrapper>
        <MessageText error={error || errorMessage}>
          {!response || error ? errorMessage || message : message}
        </MessageText>
      </MessageTextWrapper>
      <ErrorButtonsWrapper>
        {response && !error ? (
          <Button
            imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
            label={getText(TEXT_KEY.THANKS)}
            onClick={closeModal}
            textStroke={BUTTON_STROKES.PRIMARY}
          />
        ) : (
          <Button
            imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_SECONDARY)}
            label={getText(TEXT_KEY.OK)}
            onClick={closeModal}
            textStroke={BUTTON_STROKES.SECONDARY}
          />
        )}
      </ErrorButtonsWrapper>
    </MessageContainerWrapper>
  );
}

export default MessageContainer;

