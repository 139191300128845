import generateRender from 'shared/utils/generateRender';
import DefaultHeader from './DefaultHeader';
import MillelacsHeader from './MillelacsHeader';

const ModalHeader = generateRender({
  default: DefaultHeader,
  millelacs: MillelacsHeader,
  jamul: MillelacsHeader,
});

export default ModalHeader;

