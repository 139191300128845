import React, { Component } from 'react';
import SLevelUp from 'shared/components/LevelUp';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ImageBW from '../../components/ImageBoundsWrapper';
import Tooltip from '../../components/Tooltip';
import CdnPath from 'shared/utils/cdnPath';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default class LevelUp extends Component {
  _style = {
    content: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    titleContainer: {
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '0.03rem',
    },
    title: {
      fontSize: '1rem',
      fontWeight: '400',
    },
    divider: {
      width: '12rem',
      height: '0.2rem',
      zIndex: 5000,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginTop: '0.03rem',
      width: '100%',
    },
    icon: {
      width: '1.5rem',
      height: '1.5rem',
      objectFit: 'contain',
      marginRight: '0.25rem',
    },
    amount: {
      fontSize: '1.3rem',
      fontWeight: '600',
    },
    maxBetImage: {
      width: '1.7rem',
      height: '1.7rem',
      objectFit: 'contain',
      marginRight: '0.1rem',
    },
    maxBet: {
      fontSize: '1.2rem',
      fontWeight: '400',
      marginLeft: '0.1rem',
    },
    left: {
      flexDirection: 'column',
      width: '30%',
      paddingLeft: '0.7rem',
      justifyContent: 'center',
    },
    right: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '70%',
      paddingRight: '0.5rem',
    },
    gameImg: {
      width: '6rem',
      height: '6rem',
    },
  };

  checkBoxSizeBasedOnLength = text => {
    let textLen = text.length;
    if (textLen < 20) {
      return 360;
    } else if (textLen < 25) {
      return 470;
    } else {
      return 530;
    }
  };

  render() {
    return (
      <SLevelUp
        dismissModal={this.props.dismissModal}
        navigation={this.props.navigation}
        render={({ coins, maxBet, tooltip, animation, gameUnlock, launchGame }) => {
          if (tooltip === SLevelUp.TOOLTIP_STATES.REWARD) {
            return (
              <Tooltip
                visible
                animationClassName={
                  animation === SLevelUp.TOOLTIP_ANIMATION_STATES.IN ? 'AnimationSlideFromTop' : 'AnimationSlideToTop'
                }
              >
                <div style={this._style.content}>
                  <div style={this._style.titleContainer}>
                    <span
                      className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                      style={this._style.title}
                      data-label="LEVEL-UP"
                    >
                      {getText(TEXT_KEY.LEVEL_UP_MESSAGE)}
                    </span>
                    <ImageBW
                      style={this._style.divider}
                      source={assetSource(ASSET_KEY.LEVEL_UP_LIGHT_DIVIDER)}
                      ibw={require(asset`./images/level-up/light-divider.ibw`)}
                      widthScale={0.5}
                    />
                  </div>
                  <div style={this._style.row}>
                    <div>
                      <ImageBW
                        source={assetSource(ASSET_KEY.COIN_ICON)}
                        ibw={require(asset`./images/level-up/icon.ibw`)}
                        style={this._style.icon}
                      />
                      <span
                        className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                        style={this._style.amount}
                        data-label={coins}
                      >
                        {coins}
                      </span>
                    </div>
                    <div>
                      <ImageBW
                        source={assetSource(ASSET_KEY.MAX_BET)}
                        ibw={require(asset`./images/level-up/icon.ibw`)}
                        style={this._style.icon}
                      />
                      <span
                        className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                        style={this._style.amount}
                        data-label={maxBet}
                      >
                        {maxBet}
                      </span>
                    </div>
                  </div>
                </div>
              </Tooltip>
            );
          } else if (tooltip === SLevelUp.TOOLTIP_STATES.GAME_UNLOCK && gameUnlock) {
            return (
              <Tooltip
                visible
                animationClassName={
                  animation === SLevelUp.TOOLTIP_ANIMATION_STATES.IN ? 'AnimationSlideFromTop' : 'AnimationSlideToTop'
                }
                style={{
                  width: this.checkBoxSizeBasedOnLength(gameUnlock ? gameUnlock.GAME_NAME : '-'),
                }}
              >
                <div className="TooltipGameUnlock" onClick={launchGame}>
                  <div style={this._style.left}>
                    {gameUnlock && gameUnlock.GAME_ID && (
                      <img
                        alt={gameUnlock.GAME_NAME}
                        src={`${CdnPath.SLOT_ICON_WEB}${gameUnlock.GAME_ID}.png`}
                        style={this._style.gameImg}
                      />
                    )}
                  </div>
                  <div style={this._style.right}>
                    <span
                      className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                      style={this._style.title}
                      data-label="NEW GAME UNLOCKED"
                    >
                      {getText(TEXT_KEY.NEW_GAME)}
                    </span>
                    <span
                      className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                      style={this._style.amount}
                      data-label={gameUnlock.GAME_NAME}
                    >
                      {(!!gameUnlock && !!gameUnlock.GAME_NAME && gameUnlock.GAME_NAME.toUpperCase()) || '-'}
                    </span>
                  </div>
                </div>
              </Tooltip>
            );
          } else {
            return null;
          }
        }}
      />
    );
  }
}

LevelUp.hideOverlay = true;

