import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getEventsQuery, postEventsMutation } from './queries';
export var eventsApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getEvents: builder.query(getEventsQuery),
      postEvents: builder.mutation(postEventsMutation)
    };
  }
});
eventsApi.enhanceEndpoints({
  addTagTypes: ['Events']
});
var useGetEventsQuery = eventsApi.useGetEventsQuery,
  usePostEventsMutation = eventsApi.usePostEventsMutation,
  _eventsApi$endpoints = eventsApi.endpoints,
  getEvents = _eventsApi$endpoints.getEvents,
  postEvents = _eventsApi$endpoints.postEvents;
export { useGetEventsQuery, usePostEventsMutation, getEvents, postEvents };