import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { postSetLanguageMutation } from './queries';
export var languageApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postSetLanguage: builder.mutation(postSetLanguageMutation)
    };
  }
});
languageApi.enhanceEndpoints({
  addTagTypes: ['Language'],
  keepUnusedDataFor: 5
});
var usePostSetLanguageMutation = languageApi.usePostSetLanguageMutation;
export { usePostSetLanguageMutation };