import React from 'react';
import styled from 'styled-components';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

function DefaultContainer({ expireSessionHandler }) {
  return (
    <Container onClick={expireSessionHandler}>
      <MainContent />
    </Container>
  );
}

export default DefaultContainer;

const Container = styled.div`
  height: 60%;
`;

const MainContent = styled.img.attrs({
  src: assetSource(ASSET_KEY.SESSION_EXPIRED_IMAGE),
  alt: 'SESSION_EXPIRED',
})`
  width: 70%;
`;

