import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { LOGIN_ERROR_TYPE } from 'shared/utils/authFlow/constants';
import TermsModalWrapper from './TermsModalWrapper';
import {
  StarTermsContainer,
  SingInModalContainer,
  ModalButtonWrapper,
  ModalFooter,
  ModalFooterTextContainer,
  TermsContainer,
} from '../styledComponents';
import ImageBW from '../../../components/ImageBoundsWrapper';
import Styles from '../styles';

export const StarTermsModal = props => {
  const {
    isVisible,
    onFacebookActivate,
    onGoogleActivate,
    onAccountActivate,
    mark,
    error,
    socialError,
    closeModal,
    socialErrorType,
    statusMessage,
    closeRetryModal,
  } = props;

  return (
    <>
      <TermsModalWrapper
        isVisible={isVisible}
        closeModal={closeModal}
        title={getText(TEXT_KEY.GENERIC_LOGIN_ERROR_TITLE)}
        zIndex={101}
        modalStyle={Styles.modalStyle}
        modalContainerStyle={Styles.modalContainerStyle}
        titleStyle={Styles.titleStyle}
      >
        <StarTermsContainer />
        <SingInModalContainer>
          <p className="verified-text">{getText(TEXT_KEY.GENERIC_LOGIN_ERROR_MESSAGE)}</p>
          <ModalButtonWrapper
            onClick={() => {
              closeModal();
              onAccountActivate();
            }}
          >
            <ImageBW
              source={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
              ibw={require(asset`continue-button.ibw`)}
              style={{ objectFit: 'contain' }}
              className="login-button"
              innerStyle={{
                cursor: 'pointer',
              }}
            >
              <div className="continue-button-content">
                <p className="login-button-text">{getText(TEXT_KEY.LOG_IN)}</p>
              </div>
            </ImageBW>
          </ModalButtonWrapper>
          <ModalFooter>
            <ModalFooterTextContainer>
              <ImageBW source={mark} ibw={require(asset`mark.ibw`)} style={Styles.markImage} />
              <p className="footer-text">{getText(TEXT_KEY.CANT_FIND_MEMBER_ID)}</p>
            </ModalFooterTextContainer>
            <div>
              <p className="footer-small-text">{getText(TEXT_KEY.CHECK_WELCOME_EMAIL)}</p>
            </div>
          </ModalFooter>
        </SingInModalContainer>
      </TermsModalWrapper>
      <TermsModalWrapper
        isVisible={!!socialError && !error}
        closeModal={closeRetryModal}
        title={
          socialErrorType === LOGIN_ERROR_TYPE.FACEBOOK ? getText(TEXT_KEY.SHARE_EMAIL) : getText(TEXT_KEY.TRY_AGAIN)
        }
        zIndex={101}
        modalStyle={Styles.socialModalStyle}
        modalContainerStyle={Styles.SocialModalContainerStyle}
        titleStyle={Styles.titleStyle}
        modalMainBodyStyle={Styles.modalMainBodyStyle}
        disableClose
      >
        <TermsContainer>
          <SingInModalContainer>
            <p className="error-text">{typeof statusMessage === 'string' && statusMessage}</p>
            <ModalButtonWrapper
              onClick={() => {
                closeRetryModal();
                socialErrorType === LOGIN_ERROR_TYPE.GOOGLE && onGoogleActivate();
                socialErrorType === LOGIN_ERROR_TYPE.FACEBOOK && onFacebookActivate();
              }}
            >
              <ImageBW
                source={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                ibw={require(asset`continue-button.ibw`)}
                style={{ objectFit: 'contain' }}
                className="continue-button"
                innerStyle={{
                  cursor: 'pointer',
                }}
              >
                <div className="continue-button-content">
                  <p className="continue-button-text">{getText(TEXT_KEY.RETRY_BUTTON)}</p>
                </div>
              </ImageBW>
            </ModalButtonWrapper>
            <ModalFooter />
          </SingInModalContainer>
        </TermsContainer>
      </TermsModalWrapper>
    </>
  );
};

