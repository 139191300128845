import React, { Component } from 'react';
import SPasswordInput from 'shared/components/PasswordInput';
import styles from './styles';
import { TextInputBootstrap } from '../TextInput';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default class PasswordInput extends Component {
  render() {
    const { navigation, customPeekerStyle } = this.props;
    return (
      <SPasswordInput
        navigation={navigation}
        render={args => {
          const { showPassword, showPasswordToggleHandle } = args;
          return (
            <>
              <TextInputBootstrap type={!showPassword ? 'password' : 'text'} {...this.props} />
              <div style={customPeekerStyle ? customPeekerStyle : styles.peeker} onClick={showPasswordToggleHandle}>
                {!showPassword && (
                  <img style={styles.peekerImage} src={assetSource(ASSET_KEY.ICON_SHOW)} alt="icon-show" />
                )}
                {showPassword && (
                  <img style={styles.peekerImage} src={assetSource(ASSET_KEY.ICON_HIDE)} alt="icon-hide" />
                )}
              </div>
            </>
          );
        }}
      />
    );
  }
}
