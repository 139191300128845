export default {
  type: 'object',
  properties: {
    0: {
      type: 'object',
      properties: {
        success: {
          type: 'boolean'
        },
        timeLeft: {
          type: 'string',
          pattern: '^[0-9]+$'
        },
        timeInterval: {
          type: 'string',
          pattern: '^[0-9]+$'
        },
        rewardAmount: {
          type: 'integer'
        },
        rewardCurrency: {
          type: 'string'
        }
      },
      required: ['success', 'timeLeft', 'timeInterval', 'rewardAmount', 'rewardCurrency']
    }
  },
  required: ['0']
};