import theme from 'shared/assets/style/theme';
import defaultTheme from './defaultTheme';
import responsiveFontSize from '../lib/responsiveFontSize';

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const AdmesTheme = {
  ...defaultTheme,
  Start: {
    ...defaultTheme.Start,
    AdmiralBottomBarContainer: {
      width: '650px',
    },
  },
  CreditCardDetails: {
    ...defaultTheme.CreditCardDetails,
    CheckBoxBackgroundImage: {
      ...defaultTheme.CreditCardDetails.CheckBoxBackgroundImage,
      width: '40%',
    },
    BuyNowButton: {
      ...defaultTheme.CreditCardDetails.BuyNowButton,
      fontSize: '1vw',
    },
    CvvTooltip: {
      ...defaultTheme.CreditCardDetails.CvvTooltip,
      height: '17rem',
    },
    CvvTooltipArrowOver: {
      ...defaultTheme.CreditCardDetails.CvvTooltipArrowOver,
      bottom: -27,
    },
  },
  DefaultTooltip: {
    ...defaultTheme.DefaultTooltip,
    Border: {
      ...defaultTheme.DefaultTooltip.Border,
      stopColor1: '#5d8df2',
      stopColor2: '#5d8df2',
    },
    Background: {
      ...defaultTheme.DefaultTooltip.Background,
      stopColor1: '#0f184f',
      stopColor2: '#0f184f',
    },
  },
  FtueTooltip: {
    ...defaultTheme.FtueTooltip,
    TooltipTitleStyle: {
      ...defaultTheme.FtueTooltip.TooltipTitleStyle,
      fontSize: '1.1rem',
      fontWeight: '400',
    },
    TooltipRewardStyle: {
      ...defaultTheme.FtueTooltip.TooltipRewardStyle,
      fontSize: '1.2rem',
      fontWeight: '500',
    },
    BiggerBetsStyle: {
      ...defaultTheme.FtueTooltip.BiggerBetsStyle,
      fontSize: '1.1rem',
      fontWeight: '400',
    },
  },
  VipAccessFlow: {
    ...defaultTheme.VipAccessFlow,
    VipAccessImage: {
      ...defaultTheme.VipAccessFlow.VipAccessImage,
      width: '70%',
    },
  },
  BottomBar: {
    ...defaultTheme.BottomBar,
    Divider: {
      ...defaultTheme.BottomBar.Divider,
      width: 4,
    },
    BottomBarBackground: {
      ...defaultTheme.BottomBar.BottomBarBackground,
      height: '8rem',
      backgroundPosition: 'top',
    },
    BottomBarContainer: {
      ...defaultTheme.BottomBar.BottomBarContainer,
      maxWidth: '90%',
    },
    VIPIcon: {
      ...defaultTheme.BottomBar.VIPIcon,
      height: '8em',
      marginBottom: '0.7em',
    },
    RewardCenterIcon: {
      ...defaultTheme.BottomBar.RewardCenterIcon,
      height: '9em',
    },
    BarItemIcon: {
      ...defaultTheme.BottomBar.BarItemIcon,
      height: '7em',
    },
    NotificationMail: {
      ...defaultTheme.BottomBar.NotificationMail,
      top: '1vh',
      left: windowWidth > 2200 ? '5.2em' : windowWidth > 1800 ? '3.2em' : windowWidth > 1400 ? '2.2em' : '1.5em',
    },
    NotificationWatchAndEarn: {
      ...defaultTheme.BottomBar.NotificationWatchAndEarn,
      top: '1vh',
      left: windowWidth > 2200 ? '5.5em' : windowWidth > 1800 ? '3.5em' : windowWidth > 1400 ? '2.5em' : '2em',
    },
    NotificationMission: {
      ...defaultTheme.BottomBar.NotificationMission,
      top: '0.8vh',
      left: windowWidth > 2200 ? '5.5em' : windowWidth > 1800 ? '3.8em' : windowWidth > 1400 ? '2.8em' : '2em',
    },
    NotificationEvents: {
      ...defaultTheme.BottomBar.NotificationEvents,
      top: windowHeight > 1100 ? '1vh' : '1.2vh',
      left: windowWidth > 2200 ? '5.2em' : windowWidth > 1800 ? '2.9em' : windowWidth > 1400 ? '1.7em' : '1.5em',
    },
    MissionsIconText: {
      ...defaultTheme.BottomBar.MissionsIconText,
      height: '1.3em',
    },
    MissionsIcon: {
      ...defaultTheme.BottomBar.MissionsIcon,
      height: '5.5em',
    },
    TimerLabel: {
      ...defaultTheme.BottomBar.TimerLabel,
      position: 'absolute',
      width: null,
      textAlign: 'center',
      fontWeight: 600,
      fontSize: '0.8em',
      lineHeight: 1,
      bottom: null,
      zIndex: 2,
    },
    BarItem: {
      ...defaultTheme.BottomBar.BarItem,
      paddingBottom: '0.5rem',
    },
    TimerWrapper: {
      ...defaultTheme.BottomBar.TimerWrapper,
      height: null,
      justifyContent: 'center',
    },
    TimerBackground: {
      ...defaultTheme.BottomBar.TimerBackground,
      position: 'relative',
      bottom: null,
      width: '13rem',
    },
    WatchAndEarnIcon: {
      ...defaultTheme.BottomBar.WatchAndEarnIcon,
      height: '7em',
    },
    MailIcon: {
      ...defaultTheme.BottomBar.MailIcon,
      height: '7em',
    },
  },
  DailySpin: {
    ...defaultTheme.DailySpin,
    MilestoneLoyaltyValue: {
      ...defaultTheme.DailySpin.MilestoneLoyaltyValue,
      marginLeft: -3,
    },
    SpinButtonText: {
      ...defaultTheme.DailySpin.SpinButtonText,
      fontSize: '2.4rem',
    },
    BottomBarLeftContainerNoCard: {
      ...defaultTheme.DailySpin.BottomBarLeftContainerNoCard,
      maxWidth: '190px',
      width: '190px',
    },
    BottomBarLeftContainerWithCard: {
      ...defaultTheme.DailySpin.BottomBarLeftContainerWithCard,
      maxWidth: '170px',
      width: '170px',
    },
    BottomBarLeftContainerWithCardText: {
      ...defaultTheme.DailySpin.BottomBarLeftContainerWithCardText,
      fontSize: '0.7rem',
    },
    BottomBarBottomText: {
      ...defaultTheme.DailySpin.BottomBarBottomText,
      height: '40px',
      marginBottom: windowHeight < 900 ? -15 : -20,
      objectFit: 'contain',
    },
    StreakDaysRowNumber: {
      ...defaultTheme.DailySpin.StreakDaysRowNumber,
      paddingRight: 3,
      paddingBottom: 3,
    },
  },
  GameTile: {
    ...defaultTheme.GameTile,
    LivePanelItem: {
      ...defaultTheme.GameTile.LivePanelItem,
      height: '44%',
    },
  },
  Chest: {
    ...defaultTheme.Chest,
    ChestAnimation: {
      ...defaultTheme.Chest.ChestAnimation,
      height: 146.78,
      width: 155,
    },
    ChestAnimationEnd: {
      ...defaultTheme.Chest.ChestAnimationEnd,
      height: 166,
      width: 175,
    },
  },
  VideoAds: {
    ...defaultTheme.VideoAds,
    CloseText: {
      ...defaultTheme.VideoAds.CloseText,
      fontSize: '17px',
      fontWeight: 900,
    },
    KeepWatchingText: {
      ...defaultTheme.VideoAds.KeepWatchingText,
      width: '100%',
      lineHeight: '1.2',
      position: 'relative',
      paddingBottom: '0px !important',
      fontWeight: 900,
    },
  },
  RewardListItem: {
    ...defaultTheme.RewardListItem,
    RewardImagePlaceholderContainer: {
      ...defaultTheme.RewardListItem.RewardImagePlaceholderContainer,
      borderRadius: '30px',
    },
    OfferCost: {
      ...defaultTheme.RewardListItem.OfferCost,
      height: '11%',
      top: '-28.5%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4px',
      paddingRight: '4%',
      marginBottom: '0.5em',
    },
    LoyaltyPointsText: {
      ...defaultTheme.RewardListItem.LoyaltyPointsText,
      marginLeft: -5,
    },
  },
  WelcomeBonus: {
    ...defaultTheme.WelcomeBonus,
    CoinIcon: {
      ...defaultTheme.WelcomeBonus.CoinIcon,
      width: '3%',
    },
    BonusAmount: {
      ...defaultTheme.WelcomeBonus.BonusAmount,
      fontSize: responsiveFontSize(30, 28),
    },
    OfferDetails: {
      ...defaultTheme.WelcomeBonus.OfferDetails,
      bottom: '6vw',
    },
  },
  BonusLinkMessage: {
    ...defaultTheme.BonusLinkMessage,
    LPBonusContainer: {
      ...defaultTheme.BonusLinkMessage.LPBonusContainer,
      paddingTop: 0,
      bottom: 0,
    },
    CoinsBonusContainer: {
      ...defaultTheme.BonusLinkMessage.CoinsBonusContainer,
      bottom: '-3rem',
    },
    BonusInfoContainer: {
      ...defaultTheme.BonusLinkMessage.BonusInfoContainer,
      alignItems: 'center',
      gap: '1rem',
    },
    CoinImage: {
      ...defaultTheme.BonusLinkMessage.CoinImage,
      width: '2.5vh',
      height: '2.5vh',
      marginBottom: '0.5rem',
      marginRight: 0,
    },
  },
  Login: {
    ...defaultTheme.Login,
    LoginButtonText: {
      ...defaultTheme.Login.LoginButtonText,
      fontSize: '20px',
    },
  },
  NavigationBar: {
    ...defaultTheme.NavigationBar,
    SplittedLeftButton: {
      ...defaultTheme.NavigationBar.SplittedLeftButton,
      fontSize: '1.3rem',
      fontWeight: 900,
      lineHeight: 3,
      left: 6,
    },
    SplittedRightButton: {
      fontSize: '1.4rem',
      fontWeight: 900,
      marginTop: '10px !important',
      paddingRight: '10px',
    },
  },
  CoinItem: {
    ...defaultTheme.CoinItem,
    CoinAmount: {
      ...defaultTheme.CoinItem.CoinAmount,
      fontSize: '22px',
    },
    Extra: {
      ...defaultTheme.CoinItem.Extra,
      fontSize: '1.2vmin',
      marginTop: 0,
    },
    BuyButtonText: {
      ...defaultTheme.CoinItem.BuyButtonText,
      fontSize: '18px',
    },
  },
  Tier: {
    ...defaultTheme.Tier,
    Card: {
      ...defaultTheme.Tier.Card,
      height: 50,
      width: 70,
    },
    TierWrapper: {
      ...defaultTheme.Tier.TierWrapper,
      display: 'flex',
      alignItems: 'center',
      fontWeight: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
      justifyContent: 'center',
    },
  },
  OutOfCoin: {
    ...defaultTheme.OutOfCoin,
    VisitStoreText: {
      ...defaultTheme.OutOfCoin.VisitStoreText,
      fontSize: 20,
      fontWeight: 800,
    },
    QuickBuyOfferDetails: {
      ...defaultTheme.OutOfCoin.QuickBuyOfferDetails,
      bottom: '17%',
    },
  },
  TermsModal: {
    ...defaultTheme.TermsModal,
    ContactUsButtonTextStyle: {
      ...defaultTheme.TermsModal.ContactUsButtonTextStyle,
      fontSize: 22,
    },
  },
  ShopScreen: {
    ...defaultTheme.ShopScreen,
    ShopContainer: {
      ...defaultTheme.ShopScreen.ShopContainer,
      PackagesContainer: {
        ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer,
        CoinItemContainer: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.CoinItemContainer,
          marginTop: windowWidth < 1300 && windowHeight > 850 ? '30px' : '8px',
        },
        CoinItem: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.CoinItem,
          left: windowWidth < 1400 ? '1.8%' : windowWidth > 1800 ? '2.2%' : '20px',
          width: '95%',
        },
      },
    },
  },
  LimitedTimeOffer: {
    ...defaultTheme.LimitedTimeOffer,
    LTOWrapper: {
      ...defaultTheme.LimitedTimeOffer.LTOWrapper,
      inset: '-8.5% 0 0',
    },
    MainContainer: {
      ...defaultTheme.LimitedTimeOffer.MainContainer,
      justifyContent: 'flex-end',
    },
    ButtonContainer: {
      top: '82%',
    },
    BuyButtonTextStyle: {
      ...defaultTheme.LimitedTimeOffer.BuyButtonTextStyle,
      fontSize: '1.8em',
      fontWeight: 750,
      letterSpacing: '0.5px',
    },
  },
  Settings: {
    ...defaultTheme.Settings,
    SignoutButtonText: {
      ...defaultTheme.Settings.SignoutButtonText,
      fontSize: '1.3rem',
      fontWeight: 800,
    },
  },
  OnboardingLayer: {
    ...defaultTheme.OnboardingLayer,
    MessageTitleTooltip: {
      ...defaultTheme.OnboardingLayer.MessageTitleTooltip,
      fontSize: '1.2rem',
    },
  },
  TimeRemaining: {
    ...defaultTheme.TimeRemaining,
    TimerLabel: {
      ...defaultTheme.TimeRemaining.TimerLabel,
      color: theme.palette.common[1],
    },
    VIPTimer: {
      ...defaultTheme.TimeRemaining.VIPTimer,
      top: '5.5rem',
    },
  },
  JSModal: {
    ...defaultTheme.JSModal,
    ModalBackgroundAsset: {
      ...defaultTheme.JSModal.ModalBackgroundAsset,
      widthScale: 0.5,
    },
  },
  TermsModalWrapper: {
    ...defaultTheme.TermsModalWrapper,
    Modal: {
      ...defaultTheme.TermsModalWrapper.Modal,
      padding: windowWidth < 700 ? '0 4vmin !important' : '0 20vmin !important',
    },
  },
  DynamicPopupsButtons: {
    ...defaultTheme.DynamicPopupsButtons,
    ButtonsContainer: {
      ...defaultTheme.DynamicPopupsButtons.ButtonsContainer,
      bottom: '5%',
    },
    RowWrapperLink: {
      ...defaultTheme.DynamicPopupsButtons.RowWrapperLink,
      width: '95%',
    },
  },
  LinkAccountForm: {
    ...defaultTheme.LinkAccountForm,
    MemberLinkAccountLoginButton: {
      ...defaultTheme.LinkAccountForm.MemberLinkAccountLoginButtonText,
      fontSize: '18px',
    },
  },
  OfferDetail: {
    ...defaultTheme.OfferDetail,
    CarouselIndicator: {
      ...defaultTheme.OfferDetail.CarouselIndicator,
      backgroundColor: theme.palette.stroke[1],
      border: 0,
    },
    CarouselIndicatorActive: {
      ...defaultTheme.OfferDetail.CarouselIndicatorActive,
      background: `linear-gradient(
        to bottom, 
        ${theme.palette.primary[24]} 0%,  
        ${theme.palette.primary[15]} 35%, 
        ${theme.palette.primary[15]} 65%, 
        ${theme.palette.primary[24]} 100%
      )`,
      border: 0,
    },
    OfferImageLimitedDescription: {
      ...defaultTheme.OfferDetail.OfferImageLimitedDescription,
      bottom: '9%',
    },
    CoinsValue: {
      ...defaultTheme.OfferDetail.CoinsValue,
      width: '70%',
    },
  },
  SideBar: {
    ...defaultTheme.SideBar,
    MenuContainer: {
      ...defaultTheme.SideBar.MenuContainer,
      border: '5px solid #2460e1',
      background: 'linear-gradient(to bottom, #092870 0%, #0A2E80 25%, #0D3AA2 50%, #0A2E80 75%, #092870 100%)',
    },
    MenuItemText: {
      ...defaultTheme.SideBar.MenuItemText,
      fontSize: '2.9vw',
    },
  },
  Bar: {
    ...defaultTheme.Bar,
    BarContainer: {
      ...defaultTheme.Bar.BarContainer,
      background: 'rgb(142, 142, 142)',
    },
    BarProgress: {
      ...defaultTheme.Bar.BarProgress,
      background: 'rgb(0, 31, 71)',
    },
  },
  CurrencyDisplay: {
    ...defaultTheme.CurrencyDisplay,
    Icons: {
      ...defaultTheme.CurrencyDisplay.Icons,
      loyalty: {
        ...defaultTheme.CurrencyDisplay.Icons.loyalty,
        left: -25,
      },
    },
    LoyaltyLabel: {
      ...defaultTheme.CurrencyDisplay.LoyaltyLabel,
      width: '100%',
      left: 0,
    },
  },
  VIPItem: {
    ...defaultTheme.VIPItem,
    LogoImage: {
      opacity: 0,
      height: '31%',
    },
    DurationFriendlyFull: {
      ...defaultTheme.VIPItem.DurationFriendlyFull,
      paddingTop: '5%',
    },
  },
  PurchaseFlow: {
    ...defaultTheme.PurchaseFlow,
    ContactUsButtonTextStyle: {
      ...defaultTheme.PurchaseFlow.ContactUsButtonTextStyle,
      fontSize: 20,
    },
  },
};

export default AdmesTheme;

