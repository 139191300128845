import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { ACTION_COMMANDS_BUTTON_TYPE } from '../../../api/ActionCommandsInterface/constants';
import { EVENT_EMITTER_TYPE } from '../../../constants';
import Emitter from '../../../services/EmitterService';
import QueryParamProxy from '../../../utils/queryParamProxy';
import routeParamConstants from '../../../utils/routeParamConstants';
import { ASSET_KEY, assetSource } from '../../../utils/assetSource';
var generateImageSource = _defineProperty(_defineProperty({}, ACTION_COMMANDS_BUTTON_TYPE.ACCEPT, assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)), "default", null);
var generateActionHandler = function generateActionHandler(modal, onClickAction) {
  Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
    actionType: onClickAction.actionType,
    data: {
      modal: modal,
      onClickAction: onClickAction
    }
  });
  QueryParamProxy.setQueryParams(_defineProperty({}, routeParamConstants.PARAM_MODAL, undefined));
};
export var generateButtonsRendering = function generateButtonsRendering(modal) {
  var buttons = modal.buttons;
  var buttonsRendering = [];
  buttons && Object.values(buttons).forEach(function (button) {
    var buttonType = button.buttonType,
      label = button.label,
      onClickAction = button.onClickAction;
    var buttonRendering = {
      label: label,
      imageSource: generateImageSource[buttonType] || generateImageSource['default'],
      action: function action() {
        return generateActionHandler(modal, onClickAction);
      }
    };
    buttonsRendering.push(buttonRendering);
  });
  return buttonsRendering;
};