import React, { Component } from 'react';
import SNavigationBar from 'shared/components/NavigationBar';
import Screens from 'shared/screens';
import LevelBar from '../LevelBar';
import CurrencyDisplay from '../CurrencyDisplay';
import ImageBW from '../ImageBoundsWrapper';
import TimeRemainingText from '../TimeRemainingText';
import Tier from '../Tier';
import { TooltipTypes } from 'shared/utils/tooltipConstants';
import Utils from 'shared/utils/index';
import MissionsProgressTopBar from '../MissionsProgress';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import Button from '../Button/Button';
import { FTUE_STEPS_KEYS } from 'shared/constants';
import theme from 'shared/assets/style/theme';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import styled from 'styled-components';
import generateRender from 'shared/utils/generateRender';
import { assetSource, ASSET_KEY } from 'shared/utils/assetSource';
export default class NavigationBar extends Component {
  _getNavStateAnimationClassName = shouldShowCoinBalance => {
    return shouldShowCoinBalance ? 'AnimationGoLobby' : 'AnimationGoInGame';
  };

  _getCurrencyContainerWidth = currency => {
    const currencyDigits = currency?.toString()?.length;
    if (!currencyDigits) return 200;
    return currencyDigits < 8 ? currencyDigits * 32 : currencyDigits < 15 ? currencyDigits * 29 : currencyDigits * 24;
  };

  render() {
    const themeContext = this.context;

    return (
      <SNavigationBar
        {...this.props}
        render={
          /**
            @param {Object} args
            @param {import('shared/api/Constants').DTS} args.dts
           */
          args => {
            const {
              IapButtonState,
              limitedTimeOffer,
              coins,
              loyalty,
              showModal,
              navigateBack,
              showCoinBalance,
              isHome,
              isMissions,
              showActiveTracker,
              changeTooltipType,
              tooltipType,
              ftueStep,
              showLinkLoyaltyCardSetting,
              showHomeButton,
              tooltipDetails,
              shouldShowTooltip,
              showMissionsProgressBar,
              rewardCenterMiddleware,
              isBlockedForMissionsTutorial,
            } = args;

            // If IapButtonState is 2 (NoSaleDeal) or 3 (SaleDeal), use a split button
            const splitButton = IapButtonState === 2 || IapButtonState === 3;
            let leftButton = null;
            let rightButton = null;
            if (splitButton) {
              // 2 (NoSaleDeal)
              if (IapButtonState === 2) {
                leftButton = assetSource(ASSET_KEY.IAP_SPLIT_GREEN);
                rightButton = assetSource(ASSET_KEY.IAP_SPLIT_PINK);
              }
              // 3 (SaleDeal)
              else if (IapButtonState === 3) {
                leftButton = assetSource(ASSET_KEY.IAP_SPLIT_SALE);
                rightButton = assetSource(ASSET_KEY.IAP_SPLIT_PINK);
              }
            } else {
              // 0 (NoSale)
              if (IapButtonState === 0) {
                leftButton = assetSource(ASSET_KEY.IAP_WHOLE);
              }
              // 1 (Sale)
              else if (IapButtonState === 1) {
                leftButton = assetSource(ASSET_KEY.IAP_WHOLE_SALE);
              }
            }

            const checkLoyaltyBarWidth = () => {
              if (showLinkLoyaltyCardSetting) {
                return showActiveTracker ? '7vmin' : 200;
              } else {
                return showActiveTracker ? '7vmin' : 1000;
              }
            };

            return (
              <div
                className="navBar"
                style={{
                  ...themeContext.NavigationBar.TopBar,
                  pointerEvents: isBlockedForMissionsTutorial ? 'none' : 'auto',
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${assetSource(ASSET_KEY.TOP_BAR_ASSETS_BACKING)})`,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top: 0,
                    zIndex: -100,
                    backgroundSize: '100% 100%',
                  }}
                />

                <div style={themeContext.NavigationBar.BarSection}>
                  {/* left */}
                  <div
                    className="flexBarSection"
                    style={{
                      flex: 1,
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingLeft: '1rem',
                      paddingRight: '1rem',
                    }}
                  >
                    <div
                      onClick={showHomeButton() ? navigateBack : () => {}}
                      className={`HomeIcon ${
                        isHome || ftueStep > FTUE_STEPS_KEYS.HIGHLIGHT_A_GAME || !showHomeButton() ? 'disable' : ''
                      } ${this._getNavStateAnimationClassName(!showHomeButton())}`}
                      style={{ margin: 0 }}
                    >
                      <img
                        src={assetSource(ASSET_KEY.HOME_BUTTON)}
                        alt="home"
                        style={{
                          height: 50,
                          width: 50,
                        }}
                      />
                    </div>
                    <div style={{ display: showCoinBalance ? 'flex' : 'none', width: '60%' }}>
                      <CurrencyDisplay
                        source={assetSource(ASSET_KEY.COIN_ICON)}
                        ibw={require(asset`images/top-bar-assets/coin-icon.ibw`)}
                        manualAssetScale={3}
                        style={{
                          paddingRight: '1rem',
                          maxWidth: null,
                          flex: '1 1 50%',
                        }}
                        imgStyle={{
                          height: 55,
                          width: 65,
                          left: -27,
                          top: -14,
                        }}
                        value={coins}
                        iconType="coins"
                        changeTooltipType={changeTooltipType}
                        tooltipType={tooltipType}
                        currencyType={TooltipTypes.COINS}
                        containerWidth={this._getCurrencyContainerWidth(coins)}
                        containerHeight={90}
                        additionalWrapperClassNames={this._getNavStateAnimationClassName(showCoinBalance || isMissions)}
                        isCoinsGlowAnimationStopped={this.props.isCoinsGlowAnimationStopped}
                        isCoinsGlowAnimationEnabled={this.props.isCoinsGlowAnimationEnabled}
                        showLinkLoyaltyCardSetting={showLinkLoyaltyCardSetting}
                      />
                    </div>
                    <div
                      className={`LevelBarWrapper ${this._getNavStateAnimationClassName(showCoinBalance)}`}
                      style={{
                        flex: '1 1 50%',
                        height: '100%',
                        flexDirection: 'row',
                        paddingHorizontal: '2%',
                      }}
                    >
                      <LevelBar
                        style={{
                          position: 'relative',
                          flex: 1,
                          justifyContent: Utils.ieVersion() !== 0 ? 'flex-start' : 'center',
                          maxWidth: '100%',
                        }}
                        navigation={this.props.navigation}
                        changeTooltipType={changeTooltipType}
                        tooltipType={tooltipType}
                        shouldShowCoinBalance={showCoinBalance}
                      />
                    </div>
                  </div>
                  {/* middle */}
                  <MiddleSection>
                    <ImageBW
                      style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        height: '6rem',
                        width: '100%',
                      }}
                      source={assetSource(ASSET_KEY.IAP_BACKING)}
                      ibw={require(asset`images/top-bar-assets/iap-backing.ibw`)}
                      manualAssetScale={3}
                      innerStyle={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        textStroke={BUTTON_STROKES.GREEN}
                        imageSource={leftButton}
                        onClick={() => showModal(Screens.Shop)}
                        containerStyle={{
                          flex: 1,
                          justifyContent: 'center',
                          pointerEvents: 'all',
                          cursor: 'pointer',
                          height: '100%',
                          alignItems: 'center',
                        }}
                        imageStyle={{ height: '100%', pointerEvents: 'all', cursor: 'pointer', width: '100%' }}
                        label={
                          IapButtonState === 0 || IapButtonState === 2 ? getText(TEXT_KEY.BUY) : getText(TEXT_KEY.SALE)
                        }
                        textStyle={{
                          paddingLeft: splitButton ? '5%' : 0,
                          ...themeContext.NavigationBar.BuyDealText,
                          ...(splitButton
                            ? themeContext.NavigationBar.SplittedLeftButton
                            : themeContext.NavigationBar.FullButton),
                        }}
                      />
                      {splitButton && (
                        <>
                          <Button
                            imageSource={rightButton}
                            onClick={() => showModal(Screens.LimitedTimeOffer)}
                            containerStyle={{
                              flex: 1,
                              justifyContent: 'center',
                              alignItems: 'flex-start',
                              pointerEvents: 'all',
                              cursor: 'pointer',
                            }}
                            imageStyle={{
                              flex: 1,
                              height: '100%',
                              pointerEvents: 'all',
                              cursor: 'pointer',
                            }}
                            label={getText(TEXT_KEY.DEAL)}
                            textStyle={themeContext.NavigationBar.SplittedRightButton}
                            textStroke={BUTTON_STROKES.PINK}
                          >
                            <div style={{ zIndex: 1 }}>
                              <TimeRemainingText
                                style={{
                                  color: theme.palette.common[0],
                                  fontWeight: 600,
                                  transform: 'translate(0px, -8px)',
                                  paddingRight: 15,
                                  position: 'relative',
                                  top: '-0.25rem',
                                }}
                                until={limitedTimeOffer.OFFER_END_TIME}
                              />
                            </div>
                          </Button>
                        </>
                      )}
                    </ImageBW>
                  </MiddleSection>
                  {/* right */}
                  <div
                    className="flexBarSection"
                    style={{
                      flex: 1,
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingLeft: '1rem',
                      paddingRight: '1rem',
                    }}
                  >
                    <CurrencyDisplay
                      source={assetSource(ASSET_KEY.LOYALTY_ICON)}
                      ibw={require(asset`images/top-bar-assets/loyalty-icon.ibw`)}
                      manualAssetScale={3}
                      style={{
                        maxWidth: checkLoyaltyBarWidth(),
                        ...(showMissionsProgressBar && {
                          ...(showLinkLoyaltyCardSetting
                            ? { ...themeContext.NavigationBar.LoyaltyCurrencyWrapperStyle }
                            : { ...themeContext.NavigationBar.LoyaltyCurrencyWrapperStyleWithoutCard }),
                        }),
                        ...(showActiveTracker && !showMissionsProgressBar && { left: 25 }),
                      }}
                      imgStyle={{
                        ...themeContext.NavigationBar.LoyaltyCurrencyIconStyle,
                        ...(showMissionsProgressBar
                          ? themeContext.NavigationBar.LoyaltyCurrencyIconStyleWithMissionsbar
                          : {}),
                      }}
                      value={loyalty}
                      iconType="loyalty"
                      enableOnClick={true}
                      onClick={rewardCenterMiddleware}
                      imageOnly={showMissionsProgressBar}
                      changeTooltipType={changeTooltipType}
                      tooltipType={tooltipType}
                      currencyType={TooltipTypes.LOYALTY}
                      containerWidth={this._getCurrencyContainerWidth(loyalty)}
                      containerHeight={90}
                      innerStyle={{ paddingLeft: 15 }}
                      showLinkLoyaltyCardSetting={showLinkLoyaltyCardSetting}
                    />

                    {showMissionsProgressBar && (
                      <MissionsProgressTopBar
                        showLinkLoyaltyCardSetting={showLinkLoyaltyCardSetting}
                        tooltipDetails={tooltipDetails}
                        shouldShowTooltip={shouldShowTooltip}
                        navigation={this.props.navigation}
                        isMissionsScreen={isMissions}
                      />
                    )}

                    {showLinkLoyaltyCardSetting && (
                      <Tier
                        navigation={this.props.navigation}
                        showActiveTracker={showActiveTracker}
                        changeTooltipType={changeTooltipType}
                        tooltipType={tooltipType}
                      />
                    )}
                    <button
                      className="proto"
                      onClick={() => showModal(Screens.Settings)}
                      style={{
                        padding: 0,
                        marginLeft: '1rem',
                        background: 'none',
                        border: 'none',
                        maxWidth: '25%',
                      }}
                    >
                      <ImageBW
                        source={assetSource(ASSET_KEY.MENU_HAMBURGER)}
                        ibw={require(asset`images/top-bar-assets/menu-hamburger.ibw`)}
                        style={{ height: 50, width: 50 }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            );
          }
        }
      />
    );
  }
}

NavigationBar.contextType = ThemeContext;

const DefaultMiddleSection = styled.div`
  position: relative;
  font-weight: 700;
  width: 344px;
`;

const TiMiddleSection = styled(DefaultMiddleSection)`
  position: relative;
  @media (min-height: 850px) and (min-width: 1300px) {
    margin: 0 3rem;
  }
`;

const MiddleSection = generateRender({
  default: DefaultMiddleSection,
  ti: TiMiddleSection,
});

