import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getAssetsPackageListQuery, getAssetsPackageQuery } from './queries';
export var assetsPackageApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getAssetsPackage: builder.query(getAssetsPackageQuery),
      getAssetsPackageList: builder.query(getAssetsPackageListQuery)
    };
  }
});
assetsPackageApi.enhanceEndpoints({
  addTagTypes: ['AssetsPackage'],
  keepUnusedDataFor: 10
});
var _assetsPackageApi$end = assetsPackageApi.endpoints,
  getAssetsPackage = _assetsPackageApi$end.getAssetsPackage,
  getAssetsPackageList = _assetsPackageApi$end.getAssetsPackageList;
export { getAssetsPackage, getAssetsPackageList };