import React, { useCallback, useState } from 'react';
import NotificationContext from 'shared/context/NotificationContext';
import NotificationModal from '../components/Notifications/NotificationModal';

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);

  const notificationContextValue = {
    notification,
    addNotification: useCallback(
      ({
        message,
        title,
        button = null,
        buttonAction = null,
        hideCloseButton = false,
        hideHelpButton = false,
        hasCancelButton = false,
        customMessageStyle = {},
      }) =>
        setNotification({
          message,
          title,
          button,
          buttonAction,
          hideCloseButton,
          hideHelpButton,
          hasCancelButton,
          customMessageStyle,
        }),
      []
    ),
    clearNotification: useCallback(() => setNotification(null), []),
  };

  return (
    <NotificationContext.Provider value={notificationContextValue}>
      {notification && <NotificationModal />}
      {children}
    </NotificationContext.Provider>
  );
};

