import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { postOTPAuthenticateMutation, postOTPSendEmailMutation } from './queries';
export var oneTimePasswordApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postOTPSendEmail: builder.mutation(postOTPSendEmailMutation),
      postOTPAuthenticate: builder.mutation(postOTPAuthenticateMutation)
    };
  }
});
oneTimePasswordApi.enhanceEndpoints({
  addTagTypes: ['OneTimePassword'],
  keepUnusedDataFor: 5
});
var usePostOTPSendEmailMutation = oneTimePasswordApi.usePostOTPSendEmailMutation,
  usePostOTPAuthenticateMutation = oneTimePasswordApi.usePostOTPAuthenticateMutation,
  postOTPSendEmail = oneTimePasswordApi.endpoints.postOTPSendEmail;
export { usePostOTPSendEmailMutation, usePostOTPAuthenticateMutation, postOTPSendEmail };