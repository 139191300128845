import React, { Component } from 'react';
import styled from 'styled-components';
import SLinkAccount from 'shared/screens/LinkAccount';
import { SSOLinksResponseKeys } from 'shared/api/Constants';
import './LinkAccount.scss';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { AUTH_TYPES } from 'shared/utils/authFlow/constants';
import store from 'shared/state/store';
import Button from '../../components/Button/Button';
import ModalHeader from './components/LinkAccountHeader';
import Logger from 'shared/lib/analytics/logger';
import { EVENT_TYPES } from 'shared/lib/analytics/constants';
import ERROR_CODE from 'shared/utils/errorCodes';
import { getSSOLinks } from 'shared/state/query/ssoLinks';

export default class UnityLinkAccount extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-link-account',
      hideBeforeSelector: ENVIRONMENT_VARIABLES.APPLICATION_TARGET === 'hri',
    };
  }

  handleLinkAccountClick = parentComponentMethod => async () => {
    if (ENVIRONMENT_VARIABLES.SSO_ENABLED.toString() !== 'true') {
      parentComponentMethod();
      return false;
    }
    try {
      const ssoLinks = await store.dispatch(getSSOLinks.initiate()).unwrap();

      store.dispatch(this.props.setAuthCallbackIntent(AUTH_TYPES.LINK));
      let destinationUrl = ssoLinks[SSOLinksResponseKeys.LOGIN_URL];

      if (ENVIRONMENT_VARIABLES.SSO_DEV && ENVIRONMENT_VARIABLES.SSO_DEV.toString() === 'true') {
        destinationUrl = destinationUrl.replace(
          new RegExp(`(redirect_uri=)(.*)(/${ENVIRONMENT_VARIABLES.SSO_CALLBACK_PATH})`),
          `$1${document.location.origin}$3`
        );
      }
      document.location = destinationUrl;
    } catch (e) {
      Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
        error: JSON.stringify(e),
        code: ERROR_CODE.SSO_LINKS_ERROR_CODE,
      });
    }
  };

  render() {
    const themeContext = this.context;

    return (
      <SLinkAccount
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { linkAccount, handleLinkAccountForm, hideLinkButton } = args;

          return (
            <div style={themeContext.LinkAccount.LinkAccountContainer}>
              <ModalHeader
                theme={themeContext.LinkAccount.LinkAccountTitle}
                title={getText(TEXT_KEY.UNITY_LINK_ACCOUNT_INFO_TITLE)}
                subTitle={getText(TEXT_KEY.UNITY_LINK_ACCOUNT_INFO_SUBTITLE)}
              />
              <MainImageWrapper>
                <MainImage src={linkAccount.icon} />
              </MainImageWrapper>
              {!hideLinkButton && (
                <ButtonWrapper>
                  <Button
                    imageSource={assetSource(ASSET_KEY.LINK_NOW_BUTTON)}
                    onClick={this.handleLinkAccountClick(handleLinkAccountForm)}
                    containerStyle={Styles.ButtonContainerStyle}
                    objectFit="contain"
                    imageStyle={Styles.ButtonImageStyle}
                  />
                </ButtonWrapper>
              )}
            </div>
          );
        }}
      />
    );
  }
}

UnityLinkAccount.hideOverlay = true;
UnityLinkAccount.contextType = ThemeContext;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 4rem;
  align-items: center;
  justify-content: center;
`;

const MainImage = styled.img`
  object-fit: contain;
  height: 130%;
  width: 100%;
`;

const MainImageWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 1rem;
  height: auto;
`;

const Styles = {
  ButtonContainerStyle: {
    width: '25%',
    height: '4rem',
  },
  ButtonImageStyle: {
    width: '25%',
  },
};

