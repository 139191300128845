import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ACTION_COMMANDS_EVENT_TYPE, ACTION_COMMANDS_FEATURE_GROUP, ACTION_COMMANDS_TYPE } from '../../../api/ActionCommandsInterface/constants';
import { EVENT_EMITTER_TYPE } from '../../../constants';
import Emitter from '../../../services/EmitterService';
import QueryParamProxy from '../../../utils/queryParamProxy';
import RouteParamConstants from '../../../utils/routeParamConstants';
import screens from '../..';
export default function useDailySpinOnboarding() {
  var actionCommands = useSelector(function (state) {
    return state.actionCommands.actions.find(function (action) {
      var _action$payload;
      return (action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.screen) === screens.Home;
    });
  });
  useEffect(function () {
    return function () {
      var _actionCommands$modal, _actionCommands$modal2, _actionCommands$modal3, _actionCommands$paylo;
      Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
        actionType: ACTION_COMMANDS_TYPE.NOTIFY_TRIGGER_PROCESSED_REQUEST,
        data: {
          event: ACTION_COMMANDS_EVENT_TYPE.ACCEPT_APP_MODAL,
          modalType: actionCommands === null || actionCommands === void 0 || (_actionCommands$modal = actionCommands.modal) === null || _actionCommands$modal === void 0 ? void 0 : _actionCommands$modal.modalType,
          triggerExecution: actionCommands === null || actionCommands === void 0 || (_actionCommands$modal2 = actionCommands.modal) === null || _actionCommands$modal2 === void 0 || (_actionCommands$modal2 = _actionCommands$modal2.onDisplayAction) === null || _actionCommands$modal2 === void 0 ? void 0 : _actionCommands$modal2.triggerExecution,
          payload: actionCommands === null || actionCommands === void 0 || (_actionCommands$modal3 = actionCommands.modal) === null || _actionCommands$modal3 === void 0 || (_actionCommands$modal3 = _actionCommands$modal3.onDisplayAction) === null || _actionCommands$modal3 === void 0 ? void 0 : _actionCommands$modal3.payload,
          isFtueFlow: (actionCommands === null || actionCommands === void 0 || (_actionCommands$paylo = actionCommands.payload) === null || _actionCommands$paylo === void 0 ? void 0 : _actionCommands$paylo.featureGroup) === ACTION_COMMANDS_FEATURE_GROUP.FTUE
        }
      });
      Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
        actionType: ACTION_COMMANDS_TYPE.CLEAR_ACTION_COMMAND,
        data: {
          uuid: actionCommands === null || actionCommands === void 0 ? void 0 : actionCommands.uuid
        }
      });
    };
  }, [actionCommands.modal.modalType, actionCommands.modal.onDisplayAction.payload, actionCommands.modal.onDisplayAction.triggerExecution, actionCommands.payload.featureGroup]);
  var clickHandler = function clickHandler() {
    QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, undefined));
    QueryParamProxy.modalPromiseAccept();
  };
  return {
    clickHandler: clickHandler
  };
}