import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { trimInputString } from 'shared/utils/string';
import FormInput from '../../FormInput';
import BoxyLayoutTextInputsWrapper from '../../../../../components/TextInput/BoxyLayoutTextInputsWrapper';
import {
  FormInputContainer,
  PeekerBoxyStyle,
  NewUserForm,
  LeftInputContainer,
  RightInputContainer,
  InputPasswordPeeker,
} from '../../../common/styledComponents';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

function BoxyLayout({
  email,
  password,
  confirmPassword,
  showPassword,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  showPasswordToggleHandle,
  signUpTheme,
}) {
  return (
    <>
      <FormInputContainer>
        <BoxyLayoutTextInputsWrapper
          icon={assetSource(ASSET_KEY.INPUT_EMAIL_ICON_REGULAR)}
          iconError={assetSource(ASSET_KEY.INPUT_EMAIL_ICON_ERROR)}
          error={errors?.email}
        >
          <FormInput
            icon
            boxErrorLayout
            error={!!errors?.email}
            type="text"
            maxLength="100"
            value={email}
            onChange={e => setFieldValue('email', trimInputString(e.target.value))}
            onBlur={handleBlur('email')}
            placeholder={getText(TEXT_KEY.EMAIL)}
          />
        </BoxyLayoutTextInputsWrapper>
      </FormInputContainer>

      <NewUserForm>
        <LeftInputContainer theme={signUpTheme.MultipleInputInRowWrapper}>
          <FormInputContainer>
            <BoxyLayoutTextInputsWrapper
              checkErrorLength
              icon={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_REGULAR)}
              iconError={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_ERROR)}
              error={errors?.password}
            >
              <FormInput
                icon
                boxErrorLayout
                error={!!errors?.password}
                type={!showPassword ? 'password' : 'text'}
                maxLength="50"
                value={password}
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                placeholder={getText(TEXT_KEY.PASSWORD)}
              />
              <PeekerBoxyStyle onClick={showPasswordToggleHandle}>
                {!showPassword && <InputPasswordPeeker src={assetSource(ASSET_KEY.ICON_SHOW)} />}
                {showPassword && <InputPasswordPeeker src={assetSource(ASSET_KEY.ICON_HIDE)} />}
              </PeekerBoxyStyle>
            </BoxyLayoutTextInputsWrapper>
          </FormInputContainer>
        </LeftInputContainer>
        <RightInputContainer theme={signUpTheme.MultipleInputInRowWrapper}>
          <FormInputContainer>
            <BoxyLayoutTextInputsWrapper
              checkErrorLength
              icon={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_REGULAR)}
              iconError={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_ERROR)}
              error={errors?.confirmPassword}
            >
              <FormInput
                icon
                boxErrorLayout
                error={!!errors?.confirmPassword}
                type={!showPassword ? 'password' : 'text'}
                maxLength="50"
                value={confirmPassword}
                onChange={handleChange('confirmPassword')}
                onBlur={handleBlur('confirmPassword')}
                placeholder={getText(TEXT_KEY.CONFIRM_PASSWORD)}
              />
            </BoxyLayoutTextInputsWrapper>
          </FormInputContainer>
        </RightInputContainer>
      </NewUserForm>
    </>
  );
}

export default BoxyLayout;

