import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getActiveConsumables } from './queries';
export var shopApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getActiveConsumables: builder.query(getActiveConsumables)
    };
  }
});
shopApi.enhanceEndpoints({
  addTagTypes: ['Shop']
});
var useGetActiveConsumablesQuery = shopApi.useGetActiveConsumablesQuery;
export { useGetActiveConsumablesQuery };