import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import WidePreloader from './WidePreloader';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import SplashScreenFooter from './components/SplashScreenFooter';
import SplashScreenLoader from './components/SplashScreenLoader';

const HriPreloader = () => {
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogo(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [showLogo]);

  return (
    <>
      <SplashScreenLoader showLogo={showLogo} />
      <WidePreloader>
        <Container>
          <Description>
            {getText(TEXT_KEY.SPLASH_SCREEEN_PRELOADER_DESCRIPTION)
              .split('/')
              .map((text, index) => (
                <TextColor key={index} color={index % 2 === 0 ? '#f3da57' : '#fffad4'}>
                  {text}
                </TextColor>
              ))}
          </Description>
        </Container>
      </WidePreloader>
      <SplashScreenFooter />
    </>
  );
};

export default HriPreloader;

const Container = styled.div`
  margin-top: 15rem;
  width: 41rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  top: 0;
`;

const Description = styled.h4`
  font-size: 2.55rem;
  text-transform: uppercase;
  font-weight: 700;
  text-shadow: 0px 3px 1px #1d062b;
`;

const TextColor = styled.span`
  color: ${({ color }) => color};
`;

