import styled from 'styled-components';
import theme from 'shared/assets/style/theme';
import ImageBW from '../ImageBoundsWrapper';

const SocialModalStyle = {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  padding: '0 0',
  width: '100%',
};

const ModalMainBodyStyle = {
  width: '700px',
};

const ModalContainer = styled.div`
  height: 40vh;
  margin-bottom: -1vw;
  margin-top: -1vw;
  width: 100%;
`;

const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  padding: 12% 0 6% 0;
  width: 100%;
`;

const HeaderContainer = styled(ImageBW)`
  ${({ theme }) => theme};
  height: 4rem;
`;

const HeaderContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5vmin;
  top: 1em;
`;

const TitleContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 0 5vmin;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
`;

const Title = styled.p`
  color: ${theme.palette.common[1]};
  font-size: 1.9rem;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
`;

const HelpTextContainer = styled.div`
  cursor: pointer;
  z-index: 1;
`;

const HelpText = styled.p`
  color: ${theme.palette.common[1]};
  font-size: 1.2rem;
  margin-top: 0.2rem;
  text-align: right;
  margin: 0;
`;

const Message = styled.p`
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  padding-bottom: 1rem;
  width: 90%;
  text-align: center;
  margin: 0;
  ${({ customStyle }) => customStyle};
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 80%;
`;

export {
  ActionsContainer,
  HeaderContainer,
  HeaderContent,
  HelpText,
  Message,
  ModalContainer,
  ModalContent,
  ModalMainBodyStyle,
  SocialModalStyle,
  Title,
  TitleContainer,
  HelpTextContainer,
};

