import React, { Component } from 'react';
import SLinkAccount from 'shared/screens/LinkAccount';
import { SSOLinksResponseKeys } from 'shared/api/Constants';
import './LinkAccount.scss';
import ImageBW from '../../components/ImageBoundsWrapper';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { AUTH_TYPES } from 'shared/utils/authFlow/constants';
import { numberWithCommas } from 'shared/utils/number';
import store from 'shared/state/store';
import Button from '../../components/Button/Button';
import { getSSOLinks } from 'shared/state/query/ssoLinks';
import Logger from 'shared/lib/analytics/logger';
import { EVENT_TYPES } from 'shared/lib/analytics/constants';
import ERROR_CODE from 'shared/utils/errorCodes';

export default class DefaultLinkAccount extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-link-account',
      hideBeforeSelector: ENVIRONMENT_VARIABLES.APPLICATION_TARGET === 'hri',
    };
  }

  handleLinkAccountClick = parentComponentMethod => async () => {
    if (ENVIRONMENT_VARIABLES.SSO_ENABLED.toString() !== 'true') {
      parentComponentMethod();
      return false;
    }

    try {
      const ssoLinks = await store.dispatch(getSSOLinks.initiate()).unwrap();
      store.dispatch(this.props.setAuthCallbackIntent(AUTH_TYPES.LINK));
      let destinationUrl = ssoLinks[SSOLinksResponseKeys.LOGIN_URL];

      if (ENVIRONMENT_VARIABLES.SSO_DEV && ENVIRONMENT_VARIABLES.SSO_DEV.toString() === 'true') {
        destinationUrl = destinationUrl.replace(
          new RegExp(`(redirect_uri=)(.*)(/${ENVIRONMENT_VARIABLES.SSO_CALLBACK_PATH})`),
          `$1${document.location.origin}$3`
        );
      }
      document.location = destinationUrl;
    } catch (e) {
      Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
        error: JSON.stringify(e),
        code: ERROR_CODE.SSO_LINKS_ERROR_CODE,
      });
    }
  };

  render() {
    const themeContext = this.context;

    return (
      <SLinkAccount
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { linkAccount, handleLinkAccountForm, linkedLoyaltyCardBonus } = args;

          return (
            <div style={themeContext.LinkAccount.LinkAccountContainer}>
              <span style={themeContext.LinkAccount.LinkAccountTitle}>{linkAccount.linkAccountTitle}</span>
              <img
                style={themeContext.LinkAccount.LightDividerUp}
                src={assetSource(ASSET_KEY.LIGHT_DIVIDER)}
                alt="Light Divider"
              />
              <div style={themeContext.LinkAccount.AvailableCards}>
                <ImageBW
                  source={linkAccount.icon}
                  ibw={require(asset`close-button.ibw`)}
                  manualAssetScale={3}
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%',
                  }}
                  innerStyle={{
                    cursor: 'pointer',
                  }}
                  alt="Close"
                />
              </div>
              <img
                style={themeContext.LinkAccount.LightDividerDown}
                src={assetSource(ASSET_KEY.LIGHT_DIVIDER)}
                alt="Light Divider"
              />
              <p style={{ width: '100%', textAlign: 'center', marginBottom: '14px', fontWeight: 'bold' }}>
                {getText(TEXT_KEY.LINK_YOUR_ACCOUNT_TODAY_AND_EARN)} {numberWithCommas(linkedLoyaltyCardBonus.amount)}{' '}
                {linkedLoyaltyCardBonus.currency} {getText(TEXT_KEY.FREE_COINS_AND_BENEFITS)}
              </p>
              <ul
                style={{
                  ...themeContext.LinkAccount.Description,
                }}
              >
                {linkAccount.description.map(element => (
                  <li>{element}</li>
                ))}
              </ul>
              <Button
                imageSource={assetSource(ASSET_KEY.CLICK_HERE_BUTTON)}
                onClick={this.handleLinkAccountClick(handleLinkAccountForm)}
                containerStyle={themeContext.LinkAccount.LinkAccountButton}
                objectFit={'contain'}
                imageStyle={{ width: '25%' }}
              />
            </div>
          );
        }}
      />
    );
  }
}

DefaultLinkAccount.hideOverlay = true;
DefaultLinkAccount.contextType = ThemeContext;

