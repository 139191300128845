import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, SEND_PUSH_NOTIFICATION, REMOVE_PUSH_NOTIFICATION, UPDATE_MAILBOX_UNREAED_MESSAGE } from '../actions/types';
var initialState = {
  unreadMessagesCount: 0,
  notificationsSent: {},
  toasts: []
};
var toastCounter = 0;
export var notificationReducer = function notificationReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ADD_NOTIFICATION:
      toastCounter++;
      return _objectSpread(_objectSpread({}, state), {}, {
        toasts: [_objectSpread(_objectSpread({}, state.toasts), {}, {
          id: toastCounter
        }, action.notif)]
      });
    case SEND_PUSH_NOTIFICATION:
      var playerNotifications = state.notificationsSent[action.payload.playerId] || [];
      playerNotifications.push(action.payload.notifiedFeature);
      return _objectSpread(_objectSpread({}, state), {}, {
        notificationsSent: _objectSpread(_objectSpread({}, state.notificationsSent), {}, _defineProperty({}, action.payload.playerId, playerNotifications))
      });
    case REMOVE_PUSH_NOTIFICATION:
      var availableNotifications = state.notificationsSent[action.payload.playerId];
      if (availableNotifications) {
        return _objectSpread(_objectSpread({}, state), {}, {
          notificationsSent: _objectSpread(_objectSpread({}, state.notificationsSent), {}, _defineProperty({}, action.payload.playerId, availableNotifications.filter(function (item) {
            return item !== action.payload.notifiedFeature;
          })))
        });
      }
      return state;
    case REMOVE_NOTIFICATION:
      return state.toasts.filter(function (item) {
        return item.id !== action.id;
      });
    case UPDATE_MAILBOX_UNREAED_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        unreadMessagesCount: action.payload
      });
    default:
      return state;
  }
};
export default notificationReducer;