import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getDailyWheelBonusQuery, getDailyWheelDataQuery } from './queries';
export var dailyWheelApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getDailyWheelBonus: builder.query(getDailyWheelBonusQuery),
      getDailyWheelData: builder.query(getDailyWheelDataQuery)
    };
  }
});
dailyWheelApi.enhanceEndpoints({
  addTagTypes: ['DailyWheel']
});
var useLazyGetDailyWheelBonusQuery = dailyWheelApi.useLazyGetDailyWheelBonusQuery,
  _dailyWheelApi$endpoi = dailyWheelApi.endpoints,
  getDailyWheelBonus = _dailyWheelApi$endpoi.getDailyWheelBonus,
  getDailyWheelData = _dailyWheelApi$endpoi.getDailyWheelData;
export { useLazyGetDailyWheelBonusQuery, getDailyWheelBonus, getDailyWheelData };