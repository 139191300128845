import React, { Component } from 'react';
import SLinkCardOnboarding from 'shared/screens/RewardCenter/LinkCardOnboarding';
import theme from 'shared/assets/style/theme';
import ImageBW from '../../components/ImageBoundsWrapper';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default class LinkCardOnboarding extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-link-card-onboarding',
    };
  }

  render() {
    return (
      <SLinkCardOnboarding
        navigation={this.props.navigation}
        render={args => {
          const { showUpgradeFlow, dismissLinkAccountPopUp, linkedLoyaltyCardBonus } = args;
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '94%',
              }}
            >
              <div className="ModalCloseButton" onClick={dismissLinkAccountPopUp}>
                <ImageBW
                  source={assetSource(ASSET_KEY.CLOSE_BUTTON)}
                  ibw={require(asset`close-button.ibw`)}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  resizeMode="contain"
                />
              </div>
              <span
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1em',
                  fontWeight: 600,
                  fontSize: 'calc(18px + (26 - 18) * ((100vw - 800px) / (1600 - 800)))',
                  textShadow: `2px 2px 4px ${theme.palette.common[0]}`,
                  textTransform: 'uppercase',
                }}
                className="rewardCenterGradientText"
                data-label={'member of the star club?'}
              >
                {getText(TEXT_KEY.LINK_ACCOUNT_ONBOARDING_TITLE)}
              </span>
              <ImageBW
                source={assetSource(ASSET_KEY.SHOP_VIP_DIVIDER_GLOWS)}
                ibw={require(asset`shop-vip-divider-glows.ibw`)}
                manualAssetScale={3}
                style={{ height: '7vmin', width: '100%', marginTop: '1vmin' }}
                innerStyle={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  paddingLeft: '1vmin',
                  paddingRight: '1vmin',
                }}
              ></ImageBW>
              <span
                style={{
                  position: 'relative',
                  width: '40%',
                  height: '45%',
                  bottom: '1em',
                }}
              >
                <img
                  alt=""
                  src={assetSource(ASSET_KEY.TSC_CARDS)}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </span>
              <div className="RewardCenterLinkAccountMessage">
                {`${getText(TEXT_KEY.LINK_ACCOUNT_ONBOARDING_SUBTITLE)} ${linkedLoyaltyCardBonus.amount} ${
                  linkedLoyaltyCardBonus.currency
                }`}
              </div>
              <div className="RewardCenterLinkAccountAction" onClick={showUpgradeFlow}>
                <img
                  alt=""
                  src={assetSource(ASSET_KEY.LINK_BUTTON)}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
            </div>
          );
        }}
      />
    );
  }
}

LinkCardOnboarding.hideCloseButton = true;

