import React from 'react';
import { Spinner } from 'react-bootstrap';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import {
  CheckBoxContainer,
  FormBody,
  FormInputContainer,
  Header,
  HeaderContent,
  HeaderImage,
  NeedHelpStyle,
  HelpTextContainer,
  LeftInputContainer,
  MessageContainer,
  ModalFormBody,
  NewUserForm,
  Peeker,
  RegisterButtonContainer,
  RightInputContainer,
  Title,
  TitleHolder,
  SuccessAccountMessage,
  SuccessAccountWrapper,
} from '../common/styledComponents';
import CheckBoxes from './CheckBoxes';
import FormInput from './FormInput';
import SignUpButton from './FormButton';
import theme from 'shared/assets/style/theme';
import useThemeContext from 'shared/context/hooks/useThemeContext';
import ValidationPartInputs from './components/ValidationPartInputsLayout';
import LinkText from '../../../components/LinkText/LinkText';

export const SignUpForm = ({
  values: { firstName, lastName, email, password, confirmPassword, acceptedTerms, emailOptIn },
  errors,
  status,
  handleSubmit,
  setFieldValue,
  handleBlur,
  handleChange,
  handleCheckBoxToggle,
  getStatusMessage,
  title,
  termsAndConditionsUrl,
  privacyPolicyUrl,
  onPressHelp,
  showPassword,
  showPasswordToggleHandle,
  isModal,
  isLandingPage,
  requireName,
  clientText,
  clientFurtherInfoText,
  isLoading,
  postSignupData,
}) => {
  const BodyComponent = isModal ? ModalFormBody : FormBody;
  const { SignUp } = useThemeContext();

  const disabled = !acceptedTerms || isLoading;

  return (
    <>
      {isModal && (
        <Header>
          <HeaderImage
            source={assetSource(ASSET_KEY.FULL_MODAL_HEADER)}
            ibw={require(asset`full-modal-header.ibw`)}
            style={{ position: 'absolute' }}
          >
            <HeaderContent>
              <TitleHolder>
                <Title data-label={(!!title && title) || ''}>{(!!title && title) || ''}</Title>
              </TitleHolder>
              <HelpTextContainer onClick={onPressHelp}>
                <LinkText customStyle={NeedHelpStyle} underline={false}>
                  {getText(TEXT_KEY.NEED_HELP)}
                </LinkText>
              </HelpTextContainer>
            </HeaderContent>
          </HeaderImage>
        </Header>
      )}
      {!postSignupData?.success ? (
        <BodyComponent>
          {requireName && (
            <NewUserForm>
              <LeftInputContainer theme={SignUp.MultipleInputInRowWrapper}>
                <FormInputContainer>
                  <FormInput
                    error={!!errors.firstName}
                    type="text"
                    value={firstName}
                    maxLength="50"
                    onChange={handleChange('firstName')}
                    onBlur={handleBlur('firstName')}
                    placeholder={getText(TEXT_KEY.FIRST_NAME)}
                  />
                </FormInputContainer>
              </LeftInputContainer>
              <RightInputContainer theme={SignUp.MultipleInputInRowWrapper}>
                <FormInputContainer>
                  <FormInput
                    error={!!errors.lastName}
                    type="text"
                    value={lastName}
                    maxLength="50"
                    onChange={handleChange('lastName')}
                    onBlur={handleBlur('lastName')}
                    placeholder={getText(TEXT_KEY.LAST_NAME)}
                  />
                </FormInputContainer>
              </RightInputContainer>
            </NewUserForm>
          )}

          <ValidationPartInputs
            showPasswordToggleHandle={showPasswordToggleHandle}
            setFieldValue={setFieldValue}
            email={email}
            confirmPassword={confirmPassword}
            password={password}
            errors={errors}
            handleBlur={handleBlur}
            showPassword={showPassword}
            handleChange={handleChange}
            signUpTheme={SignUp}
          />

          <CheckBoxContainer>
            <CheckBoxes
              handleCheckBoxToggle={handleCheckBoxToggle}
              acceptedTerms={acceptedTerms}
              privacyPolicyUrl={privacyPolicyUrl}
              termsAndConditionsUrl={termsAndConditionsUrl}
              clientText={clientText}
              clientFurtherInfoText={clientFurtherInfoText}
              emailOptIn={emailOptIn}
            />
          </CheckBoxContainer>
          {getStatusMessage() && <MessageContainer success={status?.success}>{getStatusMessage()}</MessageContainer>}

          <RegisterButtonContainer>
            <SignUpButton
              isLandingPage={isLandingPage}
              isDebounce={true}
              disabled={disabled}
              onClick={handleSubmit}
              textStroke={theme.palette.success[2]}
            />
          </RegisterButtonContainer>
          {isLoading && (
            <Spinner
              style={{
                position: 'absolute',
                top: '25%',
                left: '32.5%',
              }}
              animation="grow"
              className="custom-spinner"
            />
          )}
        </BodyComponent>
      ) : (
        <SuccessAccountWrapper>
          <SuccessAccountMessage>{postSignupData.msg}</SuccessAccountMessage>
        </SuccessAccountWrapper>
      )}
    </>
  );
};

