import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import AppBackgroundType from '../../utils/AppBackgroundType';
import { setAppBackground } from '../../state/actions/app';
import { isEmpty } from 'lodash';
import { GAME_ROOMS } from '../../state/query/games/utils';
import TimestampManager from '../../utils/TimestampManager';
import { setFirstTimeVisitVipRoom } from '../../state/actions/ftue';
import Screens from '..';
import QueryParamsProxy from '../../utils/queryParamProxy';
import routeParamConstants from '../../utils/routeParamConstants';
export var PAGE_SIZE = 8;
var useSportGames = function useSportGames() {
  var dispatch = useDispatch();
  var _useSelector = useSelector(function (state) {
      var _state$sportsGames, _state$consumables$ac, _state$consumables$ac2;
      return {
        gameRooms: state.sportsGames,
        lobbyPromotions: state.promotions.lobbyNews,
        hasSportsRoomVip: ((_state$sportsGames = state.sportsGames) === null || _state$sportsGames === void 0 || (_state$sportsGames = _state$sportsGames.gameList[0]) === null || _state$sportsGames === void 0 ? void 0 : _state$sportsGames.source) === GAME_ROOMS.CONFIGURABLE_VIP,
        activeConsumablesVipRoomSecconds: (_state$consumables$ac = (_state$consumables$ac2 = state.consumables.active) === null || _state$consumables$ac2 === void 0 || (_state$consumables$ac2 = _state$consumables$ac2.vip_room_access) === null || _state$consumables$ac2 === void 0 ? void 0 : _state$consumables$ac2.seconds_until_expiry) !== null && _state$consumables$ac !== void 0 ? _state$consumables$ac : 0,
        showVipAccessModal: state.consumables.showVipAccessModal,
        isVipRoomUnlocked: state.consumables.isVipRoomUnlocked,
        isAccountLinked: state.playerInfo.isAccountLinked === 'yes'
      };
    }, shallowEqual),
    gameRooms = _useSelector.gameRooms,
    lobbyPromotions = _useSelector.lobbyPromotions,
    hasSportsRoomVip = _useSelector.hasSportsRoomVip,
    activeConsumablesVipRoomSecconds = _useSelector.activeConsumablesVipRoomSecconds,
    showVipAccessModal = _useSelector.showVipAccessModal,
    isVipRoomUnlocked = _useSelector.isVipRoomUnlocked,
    isAccountLinked = _useSelector.isAccountLinked;
  var _useState = useState({
      timeRemaining: 0,
      canEnterRoom: true,
      games: [],
      featuredGames: [],
      panelList: [],
      numColumn: 0,
      pagedGames: []
    }),
    _useState2 = _slicedToArray(_useState, 2),
    sportGames = _useState2[0],
    setSportGames = _useState2[1];
  var timer = useRef(null);
  var deactivateTimer = useRef(null);
  var panelList = useMemo(function () {
    var _gameRooms$gameList$;
    return ((gameRooms === null || gameRooms === void 0 || (_gameRooms$gameList$ = gameRooms.gameList[0]) === null || _gameRooms$gameList$ === void 0 ? void 0 : _gameRooms$gameList$.games) || []).filter(function (game) {
      return !game.IS_HIDDEN;
    });
  }, [gameRooms]);
  var getLivePanelList = useCallback(function () {
    var pagedGames = Array.from({
      length: Math.ceil(panelList.length / PAGE_SIZE)
    }, function (_, index) {
      return panelList.slice(index * PAGE_SIZE, (index + 1) * PAGE_SIZE);
    });
    setSportGames(function (sportGames) {
      return _objectSpread(_objectSpread({}, sportGames), {}, {
        panelList: panelList,
        numColumn: 1,
        pagedGames: pagedGames,
        pageNumber: 0
      });
    });
  }, [panelList]);
  var registerTimer = useCallback(function () {
    if (!timer.current) timer.current = TimestampManager.register(activeConsumablesVipRoomSecconds * 1000, function () {
      setSportGames(function (sportGames) {
        return _objectSpread(_objectSpread({}, sportGames), {}, {
          timeRemaining: 0,
          canEnterRoom: false
        });
      });
    });
  }, [activeConsumablesVipRoomSecconds, timer]);
  var handleTimeRemaining = useCallback(function () {
    var newTimeRemaining = Date.now() + activeConsumablesVipRoomSecconds * 1000;
    if (newTimeRemaining > 0) {
      setSportGames(function (sportGames) {
        return _objectSpread(_objectSpread({}, sportGames), {}, {
          timeRemaining: newTimeRemaining
        });
      });
      dispatch(setFirstTimeVisitVipRoom(false));
      registerTimer();
    } else if (hasSportsRoomVip && showVipAccessModal && !isVipRoomUnlocked) {
      deactivateTimer.current = window.setTimeout(function () {
        QueryParamsProxy.setQueryParams(_defineProperty({}, routeParamConstants.PARAM_MODAL, Screens.VipAccessFlow));
      }, 800);
    }
  }, [activeConsumablesVipRoomSecconds, dispatch, hasSportsRoomVip, isVipRoomUnlocked, registerTimer, showVipAccessModal]);
  useEffect(function () {
    getLivePanelList();
    handleTimeRemaining();
    dispatch(setAppBackground(hasSportsRoomVip ? AppBackgroundType.VIP_GAMES : AppBackgroundType.NORMAL));
    return function () {
      dispatch(setAppBackground(AppBackgroundType.NORMAL));
      TimestampManager.unregister(timer.current);
      window.clearTimeout(deactivateTimer.current);
    };
  }, [dispatch, getLivePanelList, handleTimeRemaining, hasSportsRoomVip, timer]);
  useEffect(function () {
    if (!isAccountLinked) {
      setSportGames(function (sportGames) {
        return _objectSpread(_objectSpread({}, sportGames), {}, {
          canEnterRoom: false
        });
      });
      QueryParamsProxy.setQueryParams(_defineProperty({}, routeParamConstants.PARAM_MODAL, Screens.SportsAccessFlow));
    }
  }, [isAccountLinked]);
  return _objectSpread(_objectSpread({}, sportGames), {}, {
    hasPromotions: !isEmpty(lobbyPromotions),
    hasGames: !isEmpty(sportGames.pagedGames),
    hasSportsRoomVip: hasSportsRoomVip,
    isAccountLinked: isAccountLinked
  });
};
export default useSportGames;