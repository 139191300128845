import React from 'react';
import {
  OfferButtonWrapper,
  OfferDescWrapper,
  OfferSubTitleHolder,
  OfferTermsWrapper,
  OfferTitleWrapper,
  ScrollViewStyle,
  offerDetailStyles,
} from './styledComponents';
import Button from '../../../../../../components/Button/Button';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { numberWithCommas } from 'shared/utils/number';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import useThemeContext from 'shared/context/hooks/useThemeContext';

const OfferDetailsDescription = props => {
  const {
    rewardname,
    subtitle,
    rdesc,
    pointsneeded,
    termsText,
    termsUrl,
    handlePurchase,
    isPurchaseButtonDisabled,
    onDisabledButtonClick,
    rewardCenterAssets,
  } = props;

  const { OfferDetailDescription } = useThemeContext();

  return (
    <ScrollViewStyle>
      <OfferTitleWrapper>
        <div className="rewardCenterGradientText OfferDetailName" data-label={rewardname}>
          {rewardname}
        </div>
      </OfferTitleWrapper>
      <OfferSubTitleHolder>
        <div className="OfferDetailSubtitle">{subtitle}</div>
      </OfferSubTitleHolder>
      <div className="offerDetailsActionContainer">
        <OfferButtonWrapper>
          <Button
            imageSource={
              isPurchaseButtonDisabled
                ? assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
                : assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)
            }
            imageStyle={offerDetailStyles.offerButtonImageStyle}
            containerStyle={offerDetailStyles.offerButtonContainerStyle}
            offerButtonWrapperStyle={offerDetailStyles.offerButtonWrapperStyle}
            onClick={isPurchaseButtonDisabled ? onDisabledButtonClick : handlePurchase}
            buttonIconStyle={OfferDetailDescription.ButtonIcon}
            buttonIcon={
              isPurchaseButtonDisabled
                ? rewardCenterAssets.REWARD_CENTER_LOYALTY_ICON_DISABLED
                : rewardCenterAssets.REWARD_CENTER_LOYALTY_ICON
            }
            textStroke={isPurchaseButtonDisabled ? BUTTON_STROKES.DISABLED : BUTTON_STROKES.PRIMARY}
            label={numberWithCommas(pointsneeded)}
            textStyle={OfferDetailDescription.ButtonText}
          />
        </OfferButtonWrapper>
      </div>
      <OfferDescWrapper>
        <div className="OfferDetailDescription">{rdesc}</div>
      </OfferDescWrapper>
      <OfferTermsWrapper>
        <div className="OfferDetailTermTitle">{getText(TEXT_KEY.PURCHASE_AND_USAGE_LIMITATIONS)}</div>
        <div className="OfferDetailTerm">
          {termsText}
          <a href={termsUrl} target="_blank" rel="noopener noreferrer">
            {termsUrl}
          </a>
        </div>
      </OfferTermsWrapper>
    </ScrollViewStyle>
  );
};

export default OfferDetailsDescription;

