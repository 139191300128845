import { SET_PRE_PURCHASE_ROUTE, SET_PRE_PURCHASE_ROUTE_PARAMS, SET_CARD_NICKNAME, SET_PROMO_CODE, CLEAR_SENSITIVE_INFO, SET_PURCHASE_TYPE, IS_OUT_OF_COINS_PURCHASE, IS_LTO_PURCHASE, SET_FORM_URL, SET_PURCHASE_PROFILE, SET_PRE_PURCHASE_DATA } from './types';
export var setPrePurchaseRoute = function setPrePurchaseRoute(route) {
  return {
    type: SET_PRE_PURCHASE_ROUTE,
    payload: route
  };
};
export var setPrePurchaseRouteParams = function setPrePurchaseRouteParams(params) {
  return {
    type: SET_PRE_PURCHASE_ROUTE_PARAMS,
    payload: params
  };
};
export var setCardNickname = function setCardNickname(nickname) {
  return {
    type: SET_CARD_NICKNAME,
    payload: nickname
  };
};
export var setPromoCode = function setPromoCode(code) {
  return {
    type: SET_PROMO_CODE,
    payload: code
  };
};
export var setPurchaseType = function setPurchaseType(type) {
  return {
    type: SET_PURCHASE_TYPE,
    payload: type
  };
};
export var isOutOfCoinsPurchase = function isOutOfCoinsPurchase(value) {
  return {
    type: IS_OUT_OF_COINS_PURCHASE,
    payload: value
  };
};
export var isLTOPurchase = function isLTOPurchase(value) {
  return {
    type: IS_LTO_PURCHASE,
    payload: value
  };
};
export var clearSensitiveInfo = function clearSensitiveInfo() {
  return {
    type: CLEAR_SENSITIVE_INFO
  };
};
export var setFormUrl = function setFormUrl(value) {
  return {
    type: SET_FORM_URL,
    payload: value
  };
};
export var setPurchaseProfile = function setPurchaseProfile(value) {
  return {
    type: SET_PURCHASE_PROFILE,
    payload: value
  };
};
export var setPrePurchaseData = function setPrePurchaseData(value) {
  return {
    type: SET_PRE_PURCHASE_DATA,
    payload: value
  };
};