import styled from 'styled-components';

const ModalStyles = {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  padding: '0 0',
  width: '100%',
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RewardCenterMainContentHolder = styled.div`
  ${({ theme }) => theme}
  height: 48vh;
  min-height: 340px;
  max-height: 545px;

  @media only screen and (max-height: 790px) {
    min-height: auto;
  }
`;

const ActivityIndicatorContainer = styled.div`
  height: 65vh;
  max-height: 630px;
`;

export { ModalStyles, MainContainer, RewardCenterMainContentHolder, ActivityIndicatorContainer };

