import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { postSignupMutation, postSSOSignupMutation } from './queries';
export var signupApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postSignup: builder.mutation(postSignupMutation),
      postSSOSignup: builder.mutation(postSSOSignupMutation)
    };
  }
});
signupApi.enhanceEndpoints({
  addTagTypes: ['Signup']
});
var usePostSignupMutation = signupApi.usePostSignupMutation,
  usePostSSOSignupMutation = signupApi.usePostSSOSignupMutation;
export { usePostSignupMutation, usePostSSOSignupMutation };