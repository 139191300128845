import { createSlice } from '@reduxjs/toolkit';
import { setStateData } from '../../../utils/state';
var sliceName = 'LEVEL';
var initialState = {
  level: 0,
  max: 0,
  maxBet: 0,
  nextLevelMaxBet: 0,
  previousLevelBonusCoins: 0,
  currentLevelBonusCoins: 0,
  nextLevelBonusCoins: 0,
  nextBoostLevel: 0,
  nextBoostPercent: 0
};
var levelInfoSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setLevelData: setStateData(initialState)
  }
});
var actions = levelInfoSlice.actions,
  reducer = levelInfoSlice.reducer;
var setLevelData = actions.setLevelData;
export { setLevelData };
export default reducer;