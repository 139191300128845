import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useCallback, useState, useEffect } from 'react';
import { OTP_TRANSACTION_DATA_KEY } from '../utils';
import { usePostOTPSendEmailMutation, usePostOTPAuthenticateMutation } from '../../../state/query/oneTimePassword';
import QueryParamsProxy from '../../../utils/queryParamProxy';
import RouteParamConstants from '../../../utils/routeParamConstants';
import { ServerResponseKeys } from '../../../api/Constants';
import { getText, TEXT_KEY } from '../../../utils/localization';
export var REQUEST_STATE = {
  INITIAL: 0,
  SUCCESS: 1,
  ERROR: 2,
  AUTHENTICATE: 3
};
export default function useOTPrequestHandler(OneTimePassword) {
  var _useState = useState({
      success: REQUEST_STATE.INITIAL,
      message: ''
    }),
    _useState2 = _slicedToArray(_useState, 2),
    requestResponse = _useState2[0],
    setRequestResponse = _useState2[1];
  var clearRequestResponse = function clearRequestResponse() {
    return setRequestResponse({
      success: REQUEST_STATE.INITIAL,
      message: ''
    });
  };
  var findQueryParams = function findQueryParams(param) {
    return QueryParamsProxy.findParam(param, null);
  };
  var _useState3 = useState(_objectSpread(_objectSpread({
      otpTransactionId: findQueryParams(RouteParamConstants.PARAM_OTP_TRANSACTION_ID),
      otpUserId: findQueryParams(RouteParamConstants.PARAM_OTP_USER_ID)
    }, findQueryParams(RouteParamConstants.PARAM_OTP_PROPERTY_ID) && {
      propertyId: findQueryParams(RouteParamConstants.PARAM_OTP_PROPERTY_ID)
    }), findQueryParams(RouteParamConstants.PARAM_OTP_CARD_NUMBER) && {
      cardnum: findQueryParams(RouteParamConstants.PARAM_OTP_CARD_NUMBER)
    })),
    _useState4 = _slicedToArray(_useState3, 2),
    otpRequestData = _useState4[0],
    setOtpRequestData = _useState4[1];
  var propertyId = otpRequestData.propertyId,
    cardnum = otpRequestData.cardnum;
  useEffect(function () {
    clearRequestResponse();
    return function () {
      return clearScreenParams();
    };
  }, []);
  var _usePostOTPSendEmailM = usePostOTPSendEmailMutation(),
    _usePostOTPSendEmailM2 = _slicedToArray(_usePostOTPSendEmailM, 2),
    postOTPSendEmail = _usePostOTPSendEmailM2[0],
    isSendingRequest = _usePostOTPSendEmailM2[1].isLoading;
  var _usePostOTPAuthentica = usePostOTPAuthenticateMutation(),
    _usePostOTPAuthentica2 = _slicedToArray(_usePostOTPAuthentica, 2),
    postOTPAuthenticate = _usePostOTPAuthentica2[0],
    isTryingToAuthenticate = _usePostOTPAuthentica2[1].isLoading;
  var modalDismissHandler = function modalDismissHandler() {
    var dismmisHandler = OneTimePassword.DismissHandler.call();
    QueryParamsProxy.modalPromiseAccept(dismmisHandler);
  };
  var sendOTPRequest = useCallback(function () {
    var otpLinkCardFormInfo = _objectSpread(_objectSpread(_objectSpread({}, propertyId && {
      propertyId: propertyId
    }), cardnum && {
      cardnum: cardnum
    }), findQueryParams(RouteParamConstants.PARAM_OTP_DOB) && {
      dob: findQueryParams(RouteParamConstants.PARAM_OTP_DOB)
    });
    postOTPSendEmail(otpLinkCardFormInfo).unwrap().then(function (data) {
      var _data$ServerResponseK;
      var otpTransactionData = (_data$ServerResponseK = data[ServerResponseKeys.CALL_RESPONSE]) === null || _data$ServerResponseK === void 0 ? void 0 : _data$ServerResponseK[OTP_TRANSACTION_DATA_KEY];
      if (otpTransactionData) {
        setOtpRequestData(function (otpInfo) {
          return _objectSpread(_objectSpread({}, otpInfo), {}, {
            otpTransactionId: otpTransactionData.transactionId,
            otpUserId: otpTransactionData.userId
          });
        });
      }
      setRequestResponse({
        success: REQUEST_STATE.SUCCESS,
        message: getText(TEXT_KEY.ONE_TIME_PASSWORD_MESSAGE)
      });
    }).catch(function (error) {
      var _error$data;
      setRequestResponse({
        success: REQUEST_STATE.ERROR,
        message: (error === null || error === void 0 || (_error$data = error.data) === null || _error$data === void 0 ? void 0 : _error$data.message) || getText(TEXT_KEY.ONE_TIME_PASSWORD_SENDING_REQUEST_ERROR)
      });
    });
  }, [cardnum, propertyId, postOTPSendEmail]);
  var handleResend = function handleResend() {
    clearRequestResponse();
    sendOTPRequest();
  };
  var handleSubmit = function handleSubmit(value) {
    clearRequestResponse();
    postOTPAuthenticate(_objectSpread(_objectSpread({}, otpRequestData), {}, {
      otpValue: value
    })).unwrap().then(function () {
      setRequestResponse({
        success: REQUEST_STATE.AUTHENTICATE,
        message: getText(TEXT_KEY.ONE_TIME_PASSWORD_AUTENTICATED)
      });
      modalDismissHandler();
    }).catch(function (error) {
      var _error$data2;
      setRequestResponse({
        success: REQUEST_STATE.ERROR,
        message: (error === null || error === void 0 || (_error$data2 = error.data) === null || _error$data2 === void 0 ? void 0 : _error$data2.message) || getText(TEXT_KEY.ONE_TIME_PASSWORD_AUTENTICATION_ERROR)
      });
    });
  };
  var clearScreenParams = function clearScreenParams() {
    return QueryParamsProxy.setQueryParams(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, RouteParamConstants.PARAM_MODAL, undefined), RouteParamConstants.PARAM_OTP_PROPERTY_ID, undefined), RouteParamConstants.PARAM_OTP_CARD_NUMBER, undefined), RouteParamConstants.PARAM_OTP_TRANSACTION_ID, undefined), RouteParamConstants.PARAM_OTP_USER_ID, undefined), RouteParamConstants.PARAM_OTP_DOB, undefined));
  };
  return {
    requestResponse: requestResponse,
    isSendingRequest: isSendingRequest || isTryingToAuthenticate,
    handleSubmit: handleSubmit,
    handleResend: handleResend
  };
}