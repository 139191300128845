import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { Spinner } from 'react-bootstrap';
import Screens from 'shared/screens';
import ImageBW from '../../components/ImageBoundsWrapper';
import SVideoAds from 'shared/screens/VideoAds';
import audio from 'shared/utils/audio';
import Utils from 'shared/utils/index';
import Button from '../../components/Button/Button';
import ThemeContext from 'shared/context/ThemeContext';
import theme from 'shared/assets/style/theme';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import ModalHeader from './components/WatchAndEarnHeader';
import { DialogheaderContainerStyle, DialogHeaderHeight } from './components/styledComponents';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

var pad = require('underscore.string/pad');

export default class VideoAds extends Component {
  state = {
    startedPlaying: false,
    videoProgressPercent: 0,
    videoDuration: 0,
    videoPaused: false,
    showWarningPopUp: false,
  };

  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-video-ad',
    };
  }

  handleWarning = (value, error = false) => {
    if (error) {
      this.props.dismissModal();
      return;
    }
    audio.onClick();
    const { startedPlaying } = this.state;

    this.setState({ showWarningPopUp: value });

    if (startedPlaying) {
      this.setState({ videoPaused: value });
    }
  };

  handleVideoStarted = () => {
    const { showWarningPopUp } = this.state;

    this.setState({
      startedPlaying: true,
      videoPaused: showWarningPopUp,
    });
  };

  onDuration(duration) {
    if (this.state.videoDuration !== duration) {
      this.setState({ videoDuration: duration });
    }
  }

  onProgress(evt) {
    if (this.state.paused || evt.played === undefined || evt.played === null) {
      return;
    }

    this.setState({ videoProgressPercent: evt.played });
  }

  getTimeString(time) {
    return parseInt(time / 60) + ':' + pad(parseInt(time % 60), 2, '0');
  }

  render() {
    const themeContext = this.context;
    return (
      <SVideoAds
        navigation={this.props.navigation}
        render={props => {
          const { url, reward, completed, error, setCompleted, showModal, errorMessage } = props;
          const { startedPlaying } = this.state;
          const barWidth = this.state.videoProgressPercent >= 0.99 ? 1 : this.state.videoProgressPercent;
          return (
            <div
              style={{
                width: '100%',
                height: '100%',
                margin: 0,
                zIndex: 2,
              }}
            >
              <div
                onClick={() => this.handleWarning(true, error)}
                style={{
                  position: 'absolute',
                  top: '-1.75rem',
                  right: '-2rem',
                  padding: 0,
                  height: '4rem',
                  width: '4rem',
                  zIndex: 9,
                  cursor: 'pointer',
                  pointerEvents: 'auto',
                }}
              >
                <ImageBW
                  source={assetSource(ASSET_KEY.CLOSE_BUTTON)}
                  ibw={require(asset`close-button.ibw`)}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  resizeMode="contain"
                />
              </div>
              <div className="videoAdsContainer">
                <div className="videoWrapper">
                  <div className="wrapper">
                    <ReactPlayer
                      url={url}
                      playing={!this.state.videoPaused}
                      onStart={this.handleVideoStarted}
                      onEnded={setCompleted}
                      onDuration={this.onDuration.bind(this)}
                      onProgress={this.onProgress.bind(this)}
                      allowFullScreen={false}
                      width="100%"
                      height="100%"
                      className="player"
                      style={this.state.videoProgressPercent !== 0 ? {} : { visibility: 'hidden' }}
                    />
                  </div>
                </div>
                <div className="progressArea">
                  <div className="timeArea">
                    <span className="videoTime">
                      {this.getTimeString(this.state.videoDuration * this.state.videoProgressPercent) +
                        ' / ' +
                        this.getTimeString(this.state.videoDuration)}
                    </span>
                  </div>
                  <div className="barArea">
                    <div className="backerContainer">
                      <div className="barBacker">
                        <div className="barFill" style={{ width: barWidth * 100 + '%' }}></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '11rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '3vmin',
                  }}
                >
                  {error === false && (
                    <div>
                      {(reward.amount === 0 || !startedPlaying || this.state.videoProgressPercent === 0) && (
                        <Spinner animation="border" style={{ position: 'absolute', top: '40%', left: '50%' }} />
                      )}
                    </div>
                  )}
                </div>
                {completed && showModal(Screens.Congrats)}
                {this.state.showWarningPopUp && (
                  <div className="warningOverlay">
                    <div className="warning">
                      <ImageBW
                        style={{
                          position: 'relative',
                          width: '100%',
                          height: '100%',
                        }}
                        source={assetSource(ASSET_KEY.DIALOG)}
                        ibw={require(asset`dialog.ibw`)}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            width: '100%',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                          }}
                        >
                          <ModalHeader
                            headerHeightStyle={DialogHeaderHeight}
                            containerStyle={DialogheaderContainerStyle}
                            title={getText(TEXT_KEY.ARE_YOU_SURE)}
                          />
                          <div className={'warningMessage' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
                            {getText(TEXT_KEY.WILL_NOT_RECEIVE_COINS)}
                          </div>
                          <div className={'warningButton' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
                            <Button
                              imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_DANGER)}
                              containerStyle={{
                                position: 'relative',
                                textAlign: 'center',
                                cursor: 'pointer',
                                pointerEvents: 'auto',
                                alignSelf: 'center',
                                height: '3em',
                                width: '11em',
                              }}
                              imageStyle={{
                                width: '100%',
                                height: '100%',
                              }}
                              textStyle={themeContext.VideoAds.CloseText}
                              label={getText(TEXT_KEY.CLOSE_VIDEO_MODAL)}
                              onClick={this.props.dismissModal}
                              textStroke={BUTTON_STROKES.DANGER}
                            />
                            <Button
                              imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                              imageStyle={{ width: '100%', height: '100%' }}
                              containerStyle={{
                                position: 'relative',
                                textAlign: 'center',
                                cursor: 'pointer',
                                pointerEvents: 'auto',
                                alignSelf: 'center',
                                height: '3em',
                                width: '11em',
                              }}
                              textStroke={BUTTON_STROKES.PRIMARY}
                              textStyle={themeContext.VideoAds.KeepWatchingText}
                              onClick={() => this.handleWarning(false)}
                              label={getText(TEXT_KEY.KEEP_WATCHING)}
                            />
                          </div>
                        </div>
                      </ImageBW>
                    </div>
                  </div>
                )}
                {error && (
                  <div className="warningOverlay">
                    <div className="warning">
                      <ImageBW
                        style={{
                          position: 'relative',
                          width: '100%',
                          height: '100%',
                        }}
                        source={assetSource(ASSET_KEY.DIALOG)}
                        ibw={require(asset`dialog.ibw`)}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            width: '100%',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                          }}
                        >
                          <ModalHeader
                            headerHeightStyle={DialogHeaderHeight}
                            containerStyle={DialogheaderContainerStyle}
                            title={getText(TEXT_KEY.VIDEO_ERROR)}
                          />
                          <div className={'warningMessage' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
                            {errorMessage || getText(TEXT_KEY.DEFAULT_ERROR_MESSAGE)}
                          </div>
                          <div className={'warningButton' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
                            <Button
                              imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_DANGER)}
                              containerStyle={{
                                position: 'relative',
                                textAlign: 'center',
                                cursor: 'pointer',
                                pointerEvents: 'auto',
                                alignSelf: 'center',
                                height: '3em',
                                width: '11em',
                              }}
                              imageStyle={{
                                width: '100%',
                                height: '100%',
                              }}
                              label={getText(TEXT_KEY.CLOSE_VIDEO_MODAL)}
                              onClick={this.props.dismissModal}
                              textStroke={theme.palette.secondary[34]}
                            />
                          </div>
                        </div>
                      </ImageBW>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        }}
      />
    );
  }
}

VideoAds.hideCloseButton = true;
VideoAds.contextType = ThemeContext;

