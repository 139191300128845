import BottomBar from './BottomBar';
import { connect } from 'shared/node_modules/react-redux';
import { setTutorialStep } from 'shared/state/slices/tutorial/tutorial';

const mapStateToProps = state => {
  return {
    onboardingsSeen: state.ftue.onboardingsSeen[state.playerInfo.playerId] || [],
    missionsData: state.missions.data,
    missionsAssets: state.assetsPackage.assets.MISSIONS_FEATURE,
    activeTutorial: state.tutorial.activeTutorial,
    tutorialStep: state.tutorial.tutorialStep,
    blockLobby: state.tutorial.blockLobby,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setTutorialStep: value => {
      dispatch(setTutorialStep(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomBar);

