import React from 'react';
import styled from 'styled-components';
import SFormSubtitle from 'shared/screens/LinkAccountForm/components/FormSubtitle';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { numberWithCommas } from 'shared/utils/number';

const TiFormSubtitle = () => {
  return (
    <SFormSubtitle
      render={args => {
        const { linkedLoyaltyCardBonus } = args;
        return (
          <SubtitleWrapper>
            <Subtitle>
              {getText(TEXT_KEY.LINK_CARD_SUBTITLE)} {getText(TEXT_KEY.AND)}
              <AmountText>
                {' '}
                {getText(TEXT_KEY.EARN)} {numberWithCommas(linkedLoyaltyCardBonus.amount)}{' '}
                {linkedLoyaltyCardBonus.currency}
              </AmountText>
              <CoinImage src={assetSource(ASSET_KEY.COIN_ICON)} alt="Coin Icon" />
            </Subtitle>
          </SubtitleWrapper>
        );
      }}
    />
  );
};

export default TiFormSubtitle;

const CoinImage = styled.img`
  pointer-events: none;
  object-fit: contain;
  height: 15px;
  width: 15px;
  margin-left: 5px;
`;

const SubtitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Subtitle = styled.p`
  text-align: center;
  padding: 0px 10px;
`;

const AmountText = styled.span`
  font-weight: bold;
`;

