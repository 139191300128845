import React from 'react';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { HeaderTitleWrapper, HeaderLightDivider } from './styledComponents';

function DefaultHeader({
  title,
  headerTitleWrapperStyle = {},
  showDivider = true,
  dividerAsset = null,
  dividerStyle = {},
}) {
  return (
    <>
      <HeaderTitleWrapper theme={headerTitleWrapperStyle}>
        <span data-label={title} className={`form-title ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}>
          {title}
        </span>
        {showDivider && (
          <HeaderLightDivider dividerAsset={dividerAsset} theme={dividerStyle} alt="HeaderLightDivider" />
        )}
      </HeaderTitleWrapper>
    </>
  );
}

export default DefaultHeader;

