export default {
  type: 'object',
  properties: {
    0: {
      type: 'object',
      properties: {
        success: {
          type: 'boolean'
        },
        error: {
          type: 'string',
          nullable: true
        },
        error_code: {
          type: 'string',
          nullable: true
        },
        msg: {
          type: 'string',
          nullable: true
        }
      },
      required: ['success']
    }
  },
  required: ['0']
};