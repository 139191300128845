import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { postLinkLoyaltyCardMutation } from './queries';
export var linkLoyaltyCardApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postLinkLoyaltyCard: builder.mutation(postLinkLoyaltyCardMutation)
    };
  }
});
linkLoyaltyCardApi.enhanceEndpoints({
  addTagTypes: ['LinkLoyaltyCard']
});
var postLinkLoyaltyCard = linkLoyaltyCardApi.endpoints.postLinkLoyaltyCard;
export { postLinkLoyaltyCard };