import React from 'react';
import ImageBW from '../../../../components/ImageBoundsWrapper';
import generateRender from 'shared/utils/generateRender';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const DefaultHeader = ({ source, ibw, style, children }) => (
  <ImageBW source={source} ibw={ibw} style={style}>
    {children}
  </ImageBW>
);

const SgaHeader = ({ style, dividerStyle, children }) => (
  <>
    <div style={style}>{children}</div>
    <img style={dividerStyle} alt="" src={assetSource(ASSET_KEY.LIGHT_DIVIDER)} />
  </>
);

export const FormHeader = generateRender({
  default: DefaultHeader,
  sga: SgaHeader,
});

