export default {
  type: 'object',
  properties: {
    0: {
      type: 'object',
      properties: {
        success: {
          type: 'boolean'
        }
      },
      required: ['success']
    }
  },
  required: ['0']
};