import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getWebTrackingUrlDataQuery, postCheckIfEmailExistsMutation, postGuestLoginMutation, postLoginMutation, postSSOLoginMutation, postSsoInitLinkLoyaltyCardQuery, postSsoLinkLoyaltyCardMutation, postSsoUpgradeMutation } from './queries';
export var loginApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postLogin: builder.mutation(postLoginMutation),
      postSSOLogin: builder.mutation(postSSOLoginMutation),
      postGuestLogin: builder.mutation(postGuestLoginMutation),
      postCheckIfEmailExists: builder.mutation(postCheckIfEmailExistsMutation),
      postSsoUpgrade: builder.mutation(postSsoUpgradeMutation),
      postSsoInitLinkLoyaltyCard: builder.query(postSsoInitLinkLoyaltyCardQuery),
      postSsoLinkLoyaltyCard: builder.mutation(postSsoLinkLoyaltyCardMutation),
      getWebTrackingUrlData: builder.query(getWebTrackingUrlDataQuery)
    };
  }
});
loginApi.enhanceEndpoints({
  addTagTypes: ['Login'],
  keepUnusedDataFor: 10
});
var usePostLoginMutation = loginApi.usePostLoginMutation,
  usePostSSOLoginMutation = loginApi.usePostSSOLoginMutation,
  usePostCheckIfEmailExistsMutation = loginApi.usePostCheckIfEmailExistsMutation,
  useGetWebTrackingUrlDataQuery = loginApi.useGetWebTrackingUrlDataQuery,
  _loginApi$endpoints = loginApi.endpoints,
  postLogin = _loginApi$endpoints.postLogin,
  postGuestLogin = _loginApi$endpoints.postGuestLogin,
  postSSOLogin = _loginApi$endpoints.postSSOLogin;
export { usePostLoginMutation, usePostSSOLoginMutation, usePostCheckIfEmailExistsMutation, useGetWebTrackingUrlDataQuery, postLogin, postGuestLogin, postSSOLogin };