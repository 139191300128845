import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getTimedBonusDataQuery, postBonusableLinkMutation, postCollectWelcomeBonusMutation } from './queries';
export var bonusApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getTimedBonus: builder.query(getTimedBonusDataQuery),
      postBonusableLink: builder.mutation(postBonusableLinkMutation),
      postCollectWelcomeBonus: builder.mutation(postCollectWelcomeBonusMutation)
    };
  }
});
bonusApi.enhanceEndpoints({
  addTagTypes: ['TimedBonus'],
  keepUnusedDataFor: 0
});
var _bonusApi$endpoints = bonusApi.endpoints,
  getTimedBonus = _bonusApi$endpoints.getTimedBonus,
  postBonusableLink = _bonusApi$endpoints.postBonusableLink,
  postCollectWelcomeBonus = _bonusApi$endpoints.postCollectWelcomeBonus;
export { getTimedBonus, postBonusableLink, postCollectWelcomeBonus };