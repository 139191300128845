import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { trimInputString } from 'shared/utils/string';
import FormInput from '../../FormInput';
import { FormInputContainer, Peeker, InputPasswordPeeker } from '../../../common/styledComponents';

function DefaultLayout({
  email,
  password,
  confirmPassword,
  showPassword,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  showPasswordToggleHandle,
}) {
  return (
    <>
      <FormInputContainer>
        <FormInput
          error={!!errors?.email}
          type="text"
          maxLength="100"
          value={email}
          onChange={e => setFieldValue('email', trimInputString(e.target.value))}
          onBlur={handleBlur('email')}
          placeholder={getText(TEXT_KEY.EMAIL)}
        />
      </FormInputContainer>
      <FormInputContainer>
        <FormInput
          error={!!errors?.password}
          type={!showPassword ? 'password' : 'text'}
          maxLength="50"
          value={password}
          onChange={handleChange('password')}
          onBlur={handleBlur('password')}
          placeholder={getText(TEXT_KEY.PASSWORD)}
        />
        <Peeker onClick={showPasswordToggleHandle}>
          {!showPassword && <InputPasswordPeeker src={assetSource(ASSET_KEY.ICON_SHOW)} />}
          {showPassword && <InputPasswordPeeker src={assetSource(ASSET_KEY.ICON_HIDE)} />}
        </Peeker>
      </FormInputContainer>
      <FormInputContainer>
        <FormInput
          error={!!errors?.confirmPassword}
          type={!showPassword ? 'password' : 'text'}
          maxLength="50"
          value={confirmPassword}
          onChange={handleChange('confirmPassword')}
          onBlur={handleBlur('confirmPassword')}
          placeholder={getText(TEXT_KEY.CONFIRM_PASSWORD)}
        />
      </FormInputContainer>
    </>
  );
}

export default DefaultLayout;

