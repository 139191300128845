import generateRender from 'shared/utils/generateRender';
import DefaultMessageItem from './DefaultMessageItem';
import JamulMessageItem from './JamulMessageItem';

const MessageItem = generateRender({
  default: DefaultMessageItem,
  jamul: JamulMessageItem,
});

export default MessageItem;

