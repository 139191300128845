import React from 'react';
import styled, { css } from 'styled-components';
import HighlightedPurchaseHeader from '../../../../components/JSModal/components/HighlightedHeader';
import Button from '../../../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

function HighlightedContainer({ expireSessionHandler }) {
  return (
    <Container>
      <HighlightedPurchaseHeader titleStyle={titleStyle} title={getText(TEXT_KEY.SESSION_EXPIRED)} />
      <ContentWrapper>
        <SessionExpireIcon />
        <Description>{getText(TEXT_KEY.SESSION_EXPIRED_DESC)}</Description>
        <ButtonWrapper>
          <LoginButton
            imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
            onClick={expireSessionHandler}
            label={getText(TEXT_KEY.LOG_IN)}
            textStroke={BUTTON_STROKES.PRIMARY}
          />
        </ButtonWrapper>
      </ContentWrapper>
    </Container>
  );
}

export default HighlightedContainer;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  height: 300px;
  margin-top: 60px;
`;

const Description = styled.p`
  font-size: 1.2rem;
`;

const SessionExpireIcon = styled.img.attrs({
  src: assetSource(ASSET_KEY.SESSION_EXPIRED_CLOCK_ICON),
})`
  width: 128px;
  height: 128px;
  object-fit: 'contain';
`;

const ButtonWrapper = styled(Container)`
  width: 100%;
  padding: 0px;
`;

const LoginButton = styled(Button).attrs({
  textStyle: {
    fontSize: 24,
    textTransform: 'uppercase',
  },
  containerStyle: {
    width: '11vw',
  },
  imageStyle: {
    width: '11vw',
  },
})``;

const titleStyle = css`
  font-size: 2rem;
`;

