export default {
  type: 'object',
  oneOf: [{
    properties: {
      actions: {
        type: 'array'
      }
    },
    required: ['actions']
  }, {
    properties: {
      requestId: {
        type: 'string',
        format: 'uuid'
      },
      errorMessage: {
        type: 'string',
        nullable: true
      },
      validatedField: {
        type: 'string',
        nullable: true
      },
      validation: {
        type: 'string',
        nullable: true
      },
      errorDescription: {
        type: 'string',
        nullable: true
      }
    },
    required: ['requestId']
  }]
};