import React from 'react';
import theme from 'shared/assets/style/theme';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import styled from 'styled-components';
import Button from '../../../components/Button/Button';
import { localizationOptions } from 'shared/screens/Settings/components/LanguageSwitcher/utils';
import useLanguageSwitcher from 'shared/screens/Settings/components/LanguageSwitcher/hooks/useLanguageSwitcher';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const LanguageSwitcher = () => {
  const { language, setLanguage, handleLanguageChange } = useLanguageSwitcher();

  return (
    <Container>
      <SelectLabel>{getText(TEXT_KEY.LOCALIZATION_SWITCHER_SELECT_LABEL)}</SelectLabel>
      <Select name="language" id="language" onChange={event => setLanguage(event.target.value)} defaultValue={language}>
        {localizationOptions.map(({ value, label }) => (
          <option style={{ color: 'black', backgroundColor: 'white' }} key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
      <Description>{getText(TEXT_KEY.LOCALIZATION_SWITCHER_DESCRIPTION)}</Description>
      <Button label={getText(TEXT_KEY.BUTTON_CONFIRM).toUpperCase()} onClick={handleLanguageChange} />
    </Container>
  );
};

export default LanguageSwitcher;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-inline: 10rem;
  margin-block: 1rem 3rem;
`;

const SelectLabel = styled.p`
  font-size: 1rem;
  opacity: 0.4;
  color: ${theme.palette.common[1]};
  align-self: flex-start;
  margin-bottom: -2rem;
  margin-top: -1rem;
`;

const Select = styled.select`
  width: 100%;
  height: 3rem;
  border-radius: 0.3rem;
  color: white;
  background-color: transparent;
  padding-inline: 1rem;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 600;
  border: 1px solid ${theme.palette.common[34]};

  appearance: none;
  background-image: url(${assetSource(ASSET_KEY.SELECT_ARROW)});
  background-repeat: no-repeat;
  background-position: right 1rem top 50%;
  background-size: 1rem;
`;

const Description = styled.p`
  font-size: 1.45rem;
  color: ${theme.palette.common[1]};
  text-align: center;
`;

