import { ExternalEndpoints } from '../../../api/Constants';
export var postSendSupportMessageMutation = {
  query: function query(data) {
    return {
      url: "".concat(ExternalEndpoints.CUSTOMER_SUPPORT_ENDPOINT),
      method: 'post',
      body: {
        data: {
          body: data,
          raw: true
        },
        baseUrl: "".concat(ExternalEndpoints.CUSTOMER_SUPPORT_BASE_URL)
      }
    };
  },
  transformResponse: function transformResponse(response) {
    if (response.success === false) throw Error("[sendSupportMessage response] ".concat(response.error));
    return response;
  }
};