import { css } from 'styled-components';

export const headerContainerStyle = css`
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

export const wrapperStyle = css`
  width: calc(100% + 2rem);
  left: -1rem;
  top: -1rem;
`;

