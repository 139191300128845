export default {
  type: 'object',
  properties: {
    0: {
      type: 'object',
      properties: {
        playerId: {
          type: 'integer'
        },
        casinoId: {
          type: 'string'
        },
        playerStatus: {
          type: 'string'
        },
        email: {
          type: 'string'
        },
        emailOptIn: {
          type: 'string'
        },
        canPlayerChangeEmail: {
          type: 'string'
        },
        canPlayerChangePassword: {
          type: 'string'
        },
        name: {
          type: 'string'
        }
      },
      required: ['playerId', 'casinoId', 'playerStatus', 'email', 'emailOptIn', 'canPlayerChangeEmail', 'canPlayerChangePassword', 'name']
    }
  },
  required: ['0']
};