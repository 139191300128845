export default {
  type: 'object',
  properties: {
    0: {
      oneOf: [{
        type: 'object',
        properties: {
          msg: {
            type: 'string'
          },
          success: {
            type: 'boolean'
          },
          redeem: {
            type: 'object',
            properties: {
              amount: {
                type: 'integer'
              },
              currency: {
                type: 'string'
              }
            },
            required: ['amount', 'currency']
          }
        },
        required: ['msg', 'success', 'redeem']
      }, {
        type: 'object',
        properties: {
          error: {
            type: 'string'
          },
          success: {
            type: 'boolean'
          },
          error_code: {
            type: 'string'
          }
        },
        required: ['error', 'success', 'error_code']
      }]
    }
  },
  required: ['0']
};