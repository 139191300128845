import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { EVENTS_CARD_TYPE, EVENTS_GAMEPLAY_STATUS } from '../constants';
var generateSingleGameCard = function generateSingleGameCard(eventsAssets) {
  return {
    BackgroundCard: eventsAssets.EVENTS_CARD_PURPLE,
    GlowCard: eventsAssets.EVENTS_CARD_FRAME_GLOW,
    BottomCard: eventsAssets.EVENTS_CARD_BOTTOM_PURPLE,
    BackgroundDetail: eventsAssets.EVENTS_DETAIL_CARD_PURPLE,
    EntriesCounterDetail: eventsAssets.EVENTS_DETAIL_COUNTER_PURPLE,
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_BAR_PURPLE,
    Ribbon: eventsAssets.EVENTS_DEFAULT_RIBBON,
    PodiumBottom: eventsAssets.EVENTS_PODIUM_ACTIVE,
    StrokeColor: '#220d55'
  };
};
var generateMultipleGamesCard = function generateMultipleGamesCard(eventsAssets) {
  return {
    BackgroundCard: eventsAssets.EVENTS_CARD_MULTIlPLE_GAMES,
    GlowCard: eventsAssets.EVENTS_CARD_FRAME_GLOW,
    BottomCard: eventsAssets.EVENTS_CARD_BOTTOM_BLUE,
    Title: eventsAssets.EVENTS_MULTIPLE_GAMES_TITLE,
    BackgroundDetail: eventsAssets.EVENTS_DETAIL_CARD_BLUE,
    EntriesCounterDetail: eventsAssets.EVENTS_DETAIL_COUNTER_BLUE,
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_BAR_BLUE,
    Ribbon: eventsAssets.EVENTS_DEFAULT_RIBBON,
    PodiumBottom: eventsAssets.EVENTS_PODIUM_ACTIVE,
    StrokeColor: '#213787'
  };
};
var generateMultipleActivitiesCard = function generateMultipleActivitiesCard(eventsAssets) {
  return {
    BackgroundCard: eventsAssets.EVENTS_CARD_MULTIlPLE_ACTIVITIES,
    GlowCard: eventsAssets.EVENTS_CARD_FRAME_GLOW,
    BottomCard: eventsAssets.EVENTS_CARD_BOTTOM_PURPLE,
    Title: eventsAssets.EVENTS_MULTIPLE_ACTIVITIES_TITLE,
    BackgroundDetail: eventsAssets.EVENTS_DETAIL_CARD_PURPLE,
    EntriesCounterDetail: eventsAssets.EVENTS_DETAIL_COUNTER_PURPLE,
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_BAR_PURPLE,
    Ribbon: eventsAssets.EVENTS_DEFAULT_RIBBON,
    PodiumBottom: eventsAssets.EVENTS_PODIUM_ACTIVE,
    StrokeColor: '#220d55'
  };
};
var generateTopBannerCard = function generateTopBannerCard(eventsAssets) {
  return {
    BackgroundCard: eventsAssets.EVENTS_CARD_ORANGE,
    GlowCard: eventsAssets.EVENTS_CARD_FRAME_GLOW_LIVE_OPS,
    BottomCard: eventsAssets.EVENTS_CARD_BOTTOM_ORANGE,
    BackgroundDetail: eventsAssets.EVENTS_DETAIL_CARD_ORANGE,
    EntriesCounterDetail: eventsAssets.EVENTS_DETAIL_COUNTER_ORANGE,
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_FILLABLE_BAR_BASE_ORANGE,
    EntriesBarFillDetail: eventsAssets.EVENTS_DETAIL_FILLABLE_BAR_FILL_ORANGE,
    EntriesBarEndDetail: eventsAssets.EVENTS_DETAIL_FILLABLE_BAR_CAP_ORANGE,
    Ribbon: eventsAssets.EVENTS_DEFAULT_RIBBON,
    PodiumBottom: eventsAssets.EVENTS_PODIUM_ACTIVE,
    TopBanner: eventsAssets.EVENTS_CARD_IMAGE_LIVE_OPS,
    StrokeColor: '#a44038'
  };
};
var generateAllGamesCard = function generateAllGamesCard(eventsAssets) {
  return {
    BackgroundCard: eventsAssets.EVENTS_CARD_ALL_GAMES,
    GlowCard: eventsAssets.EVENTS_CARD_FRAME_GLOW,
    BottomCard: eventsAssets.EVENTS_CARD_BOTTOM_PINK,
    Title: eventsAssets.EVENTS_ALL_GAMES_TITLE,
    BackgroundDetail: eventsAssets.EVENTS_DETAIL_CARD_PINK,
    EntriesCounterDetail: eventsAssets.EVENTS_DETAIL_COUNTER_PINK,
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_BAR_PINK,
    Ribbon: eventsAssets.EVENTS_DEFAULT_RIBBON,
    PodiumBottom: eventsAssets.EVENTS_PODIUM_ACTIVE,
    StrokeColor: '#732c5d'
  };
};
var generateTitleCard = function generateTitleCard(cardType, eventsAssets) {
  return _defineProperty(_defineProperty(_defineProperty({}, EVENTS_CARD_TYPE.MULTIPLE_GAMES, eventsAssets.EVENTS_MULTIPLE_GAMES_TITLE), EVENTS_CARD_TYPE.MULTIPLE_ACTIVITIES, eventsAssets.EVENTS_MULTIPLE_ACTIVITIES_TITLE), EVENTS_CARD_TYPE.ALL_GAMES, eventsAssets.EVENTS_ALL_GAMES_TITLE)[cardType] || null;
};
var generateMainEventCard = function generateMainEventCard(cardType, eventsAssets) {
  return _objectSpread(_objectSpread({
    Title: generateTitleCard(cardType, eventsAssets)
  }, cardType === EVENTS_CARD_TYPE.SINGLE_GAME && {
    BackgroundCard: eventsAssets.EVENTS_CARD_BLUE,
    GlowCard: eventsAssets.EVENTS_CARD_FRAME_GLOW,
    BottomCard: eventsAssets.EVENTS_CARD_BOTTOM_BLUE,
    BackgroundDetail: eventsAssets.EVENTS_DETAIL_CARD_BLUE,
    EntriesCounterDetail: eventsAssets.EVENTS_DETAIL_COUNTER_BLUE,
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_BAR_BLUE
  }), {}, {
    Ribbon: {
      Title: eventsAssets.EVENTS_MAIN_RIBBON_TITLE,
      Crown: eventsAssets.EVENTS_MAIN_RIBBON_CROWN,
      Trombone: eventsAssets.EVENTS_MAIN_RIBBON_TROMBONE
    },
    PodiumBottom: eventsAssets.EVENTS_PODIUM_ACTIVE,
    PodiumLightBottom: eventsAssets.EVENTS_PODIUM_LIGHT,
    StrokeColor: '#213787'
  });
};
var generateEndedCard = function generateEndedCard(isMainEvent, eventsAssets, totalEntriesAvailable) {
  return _objectSpread(_objectSpread({
    ShadowCard: eventsAssets.EVENTS_CARD_FRAME_SHADOW,
    BackgroundDetail: eventsAssets.EVENTS_DETAIL_CARD_GREY,
    EntriesCounterDetail: eventsAssets.EVENTS_DETAIL_COUNTER_GREY
  }, totalEntriesAvailable ? {
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_FILLABLE_BAR_BASE_GREY,
    EntriesBarFillDetail: eventsAssets.EVENTS_DETAIL_FILLABLE_BAR_FILL_GREY,
    EntriesBarEndDetail: eventsAssets.EVENTS_DETAIL_FILLABLE_BAR_CAP_GREY
  } : {
    EntriesBarDetail: eventsAssets.EVENTS_DETAIL_BAR_GREY
  }), {}, {
    Ribbon: isMainEvent ? {
      Title: eventsAssets.EVENTS_MAIN_RIBBON_TITLE_SHADOW,
      Crown: eventsAssets.EVENTS_MAIN_RIBBON_CROWN_SHADOW,
      Trombone: eventsAssets.EVENTS_MAIN_RIBBON_TROMBONE_SHADOW
    } : eventsAssets.EVENTS_DEFAULT_RIBBON_SHADOW,
    PodiumBottom: eventsAssets.EVENTS_PODIUM_DISABLED,
    PodiumLightBottom: null
  });
};
var generateEventsCardDataRendering = function generateEventsCardDataRendering(_ref, eventsAssets) {
  var isMainEvent = _ref.isMainEvent,
    cardType = _ref.cardType,
    eventGameplayStatus = _ref.eventGameplayStatus,
    totalEntriesAvailable = _ref.totalEntriesAvailable;
  var generateCard = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, EVENTS_CARD_TYPE.SINGLE_GAME, generateSingleGameCard(eventsAssets)), EVENTS_CARD_TYPE.MULTIPLE_GAMES, generateMultipleGamesCard(eventsAssets)), EVENTS_CARD_TYPE.MULTIPLE_ACTIVITIES, generateMultipleActivitiesCard(eventsAssets)), EVENTS_CARD_TYPE.TOP_BANNER, generateTopBannerCard(eventsAssets)), EVENTS_CARD_TYPE.ALL_GAMES, generateAllGamesCard(eventsAssets))[cardType];
  if (isMainEvent) generateCard = _objectSpread(_objectSpread({}, generateCard), generateMainEventCard(cardType, eventsAssets));
  if (eventGameplayStatus !== EVENTS_GAMEPLAY_STATUS.RUNNING) generateCard = _objectSpread(_objectSpread({}, generateCard), generateEndedCard(isMainEvent, eventsAssets, totalEntriesAvailable));
  return generateCard;
};
export default generateEventsCardDataRendering;