import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getTextsQuery } from './queries';
export var textsApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getTexts: builder.query(getTextsQuery)
    };
  }
});
textsApi.enhanceEndpoints({
  addTagTypes: ['Texts'],
  keepUnusedDataFor: 6000
});
var useGetTextsQuery = textsApi.useGetTextsQuery,
  getTexts = textsApi.endpoints.getTexts;
export { useGetTextsQuery, getTexts };