import React, { Component } from 'react';
import { UpdateTermsBackgroundImage } from './styledComponents';
import SUpdateTerms from 'shared/screens/UpdateTerms';
import StarTCs from '../../../components/TermsAndConditions/TCStar';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default class StarUpdateTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oauthLoading: false,
      statusMessage: null,
      emailPermissionAccepted: null,
    };
  }

  render() {
    return (
      <SUpdateTerms
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { setTerms, terms, onSubmit, termsAndConditionsUrl, privacyPolicyUrl, disableClose } = args;
          return (
            <>
              <UpdateTermsBackgroundImage alt="background" src={assetSource(ASSET_KEY.SPLASH_BG)} />
              <StarTCs
                isVisible={true}
                setTerms={setTerms}
                terms={terms}
                onSubmit={onSubmit}
                termsAndConditionsUrl={this.props.termsAndConditionsUrl || termsAndConditionsUrl}
                privacyPolicyUrl={this.props.privacyPolicyUrl || privacyPolicyUrl}
                test={this.props.poo}
                disableClose={disableClose}
                hideCloseBtn={true}
              />
            </>
          );
        }}
      />
    );
  }
}

