import { SET_PLAYER_INFO, SET_CASINO_ID_STATUS, SET_CASINO_ID, SET_KYC_STATUS, SET_PROPERTY_NAME, SET_PROPERTY_ID, SET_SOUND_SETTINGS, IS_ACCOUNT_LINKED, SET_CURRENT_MEMBER_LEVEL, SET_IS_DEV_USER, SET_AFF_ID, SET_RATING_POPUP_TIME, SET_AD_BLOCKER_POPUP_TIME, SET_GAMES_OPENED_AMOUNT, SET_APPSFLYER_GAMES_OPENED_AMOUNT_CONFIG, SET_PLAYER_ID, CLEAR_TOKEN, SET_INVITE_LINK } from './types';
export var setPlayerInfo = function setPlayerInfo(value) {
  return {
    type: SET_PLAYER_INFO,
    payload: value
  };
};
export var setPlayerId = function setPlayerId(value) {
  return {
    type: SET_PLAYER_ID,
    payload: value
  };
};
export var clearToken = function clearToken(clearPendingMessages) {
  return {
    type: CLEAR_TOKEN,
    payload: clearPendingMessages
  };
};
export var setCasinoIdStatus = function setCasinoIdStatus(value) {
  return {
    type: SET_CASINO_ID_STATUS,
    payload: value
  };
};
export var setCasinoId = function setCasinoId(value) {
  return {
    type: SET_CASINO_ID,
    payload: value
  };
};
export var setKYCStatus = function setKYCStatus(value) {
  return {
    type: SET_KYC_STATUS,
    payload: value
  };
};
export var setPropertyName = function setPropertyName(value) {
  return {
    type: SET_PROPERTY_NAME,
    payload: value
  };
};
export var setPropertyId = function setPropertyId(value) {
  return {
    type: SET_PROPERTY_ID,
    payload: value
  };
};
export var setSoundSettings = function setSoundSettings(value) {
  return {
    type: SET_SOUND_SETTINGS,
    payload: value
  };
};
export var isAccountLinked = function isAccountLinked(value) {
  return {
    type: IS_ACCOUNT_LINKED,
    payload: value
  };
};
export var setCurrentMemberLevel = function setCurrentMemberLevel(value) {
  return {
    type: SET_CURRENT_MEMBER_LEVEL,
    payload: value
  };
};
export var setIsDevUser = function setIsDevUser(value) {
  return {
    type: SET_IS_DEV_USER,
    payload: value
  };
};
export var setUserAffId = function setUserAffId(value) {
  return {
    type: SET_AFF_ID,
    payload: value
  };
};
export var setLastTimeRatingSeen = function setLastTimeRatingSeen(value) {
  return {
    type: SET_RATING_POPUP_TIME,
    payload: value
  };
};
export var setLastTimeAdBlockerSeen = function setLastTimeAdBlockerSeen(value) {
  return {
    type: SET_AD_BLOCKER_POPUP_TIME,
    payload: value
  };
};
export var setGamesOpenedAmount = function setGamesOpenedAmount(value) {
  return {
    type: SET_GAMES_OPENED_AMOUNT,
    payload: value
  };
};
export var setAppsflyerGamesOpenedAmountConfig = function setAppsflyerGamesOpenedAmountConfig(value) {
  return {
    type: SET_APPSFLYER_GAMES_OPENED_AMOUNT_CONFIG,
    payload: value
  };
};
export var setInviteLink = function setInviteLink(value) {
  return {
    type: SET_INVITE_LINK,
    payload: value
  };
};