import React, { useContext } from 'react';
import { EditInfoWrapper, EditTitleHolder, LabelHolder, PasswordSubtitle, LabelValue } from '../styledComponents';
import styles from '../styles';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ThemeContext from 'shared/context/ThemeContext';
import ImageBW from '../../../../components/ImageBoundsWrapper';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const EditInfoSection = props => {
  const { Account } = useContext(ThemeContext);
  const { title, handle, value, verifiedAccount, isPassword } = props;

  return (
    <EditInfoWrapper>
      <EditTitleHolder>
        <LabelHolder theme={Account.Label} isPassword={isPassword} data-label={title}>
          {title}
        </LabelHolder>
        {isPassword ? (
          <PasswordSubtitle>{value || getText(TEXT_KEY.NONE)}</PasswordSubtitle>
        ) : (
          <LabelValue>{value || getText(TEXT_KEY.NONE)}</LabelValue>
        )}
      </EditTitleHolder>
      <div className="edit-button-container" onClick={verifiedAccount ? handle : null}>
        <ImageBW
          source={
            verifiedAccount ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY) : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
          }
          ibw={require(asset`./images/settings/settings-edit-button.ibw`)}
          style={styles.editButtonImage}
          className="edit-button-background-image"
          innerStyle={{
            cursor: 'pointer',
          }}
        >
          <div className="edit-button-content">
            <span className="edit-button-text" data-label={getText(TEXT_KEY.EDIT)}>
              {getText(TEXT_KEY.EDIT)}
            </span>
          </div>
        </ImageBW>
      </div>
    </EditInfoWrapper>
  );
};
export default EditInfoSection;

