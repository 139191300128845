import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getFriendsInviteQuery } from './queries';
export var friendsInviteApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getFriendsInvite: builder.query(getFriendsInviteQuery)
    };
  }
});
friendsInviteApi.enhanceEndpoints({
  addTagTypes: ['FriendsInvite']
});
var useGetFriendsInviteQuery = friendsInviteApi.useGetFriendsInviteQuery,
  getFriendsInvite = friendsInviteApi.endpoints.getFriendsInvite;
export { useGetFriendsInviteQuery, getFriendsInvite };