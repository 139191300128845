export default {
  type: 'object',
  properties: {
    0: {
      type: 'object',
      properties: {
        0: {
          type: 'integer'
        },
        1: {
          type: 'object',
          properties: {
            c: {
              type: 'integer'
            },
            p: {
              type: 'integer'
            },
            l: {
              type: 'integer'
            },
            nextLevelCoins: {
              type: 'integer'
            },
            nextLevelPremium: {
              type: 'integer'
            },
            nextLevelLoyalty: {
              type: 'integer'
            },
            nextBoostLevel: {
              type: 'string'
            },
            nextBoostPercentage: {
              type: 'number'
            },
            isAtMaxBoost: {
              type: 'boolean'
            },
            currentLevel: {
              type: 'string'
            },
            currentBoostPercentage: {
              type: 'number'
            }
          },
          required: ['c', 'p', 'l', 'nextLevelCoins', 'nextLevelPremium', 'nextLevelLoyalty', 'nextBoostLevel', 'nextBoostPercentage', 'isAtMaxBoost', 'currentLevel', 'currentBoostPercentage']
        },
        2: {
          type: 'object',
          properties: {
            appsflyer: {
              type: 'string'
            }
          },
          required: ['appsflyer']
        },
        3: {
          type: 'integer'
        },
        5: {
          type: 'object',
          properties: {
            0: {
              type: 'string'
            },
            1: {
              type: 'string'
            },
            2: {
              type: 'integer'
            },
            3: {
              type: 'integer'
            },
            4: {
              type: 'integer'
            },
            5: {
              type: 'string'
            },
            6: {
              type: 'string'
            }
          }
        },
        6: {
          type: 'object',
          properties: {
            amount: {
              type: 'integer'
            },
            currency: {
              type: 'string'
            }
          },
          required: ['amount', 'currency']
        }
      },
      required: ['0', '1', '2', '3', '5', '6']
    }
  },
  required: ['0']
};