import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import moment from 'moment';
import { NO_EXPIRY_DATE_STRING } from '../../../screens/RewardCenter/constants';
import { TEXT_KEY, getText } from '../../../utils/localization';
var formatLoyaltyExpiry = function formatLoyaltyExpiry(loyaltyExpiryDate) {
  var expiryDate = moment(loyaltyExpiryDate);
  return expiryDate.isValid() ? expiryDate.format('DD MMM YYYY') : false;
};
export var postRewardCenterAdapter = function postRewardCenterAdapter(response) {
  var _profile$, _profile$2, _profile$3, _profile$4, _profile$5, _profile$6, _profile$7, _profile$0$displayRew, _profile$8, _profile$9, _profile$10;
  var _response$profile = response.profile,
    profile = _response$profile === void 0 ? {} : _response$profile,
    offers = response.offers,
    filterList = response.filterList,
    otpExpired = response.otpExpired,
    assetsPackageId = response.assetsPackageId;
  var rewardCenterAdapter = {
    profile: {
      name: (_profile$ = profile[0]) === null || _profile$ === void 0 ? void 0 : _profile$.name,
      playerProfileInfo: (_profile$2 = profile[0]) === null || _profile$2 === void 0 ? void 0 : _profile$2.playerProfileInfo,
      loyaltyExpiryDate: formatLoyaltyExpiry((_profile$3 = profile[0]) === null || _profile$3 === void 0 ? void 0 : _profile$3.loyaltyExpiryDate),
      linkedCasinoId: ((_profile$4 = profile[0]) === null || _profile$4 === void 0 ? void 0 : _profile$4.linkedCasinoId) === 'yes',
      enableOrderCard: ((_profile$5 = profile[0]) === null || _profile$5 === void 0 ? void 0 : _profile$5.enableOrderCard) === 'yes',
      displayRewardProfileScreen: ((_profile$6 = profile[0]) === null || _profile$6 === void 0 ? void 0 : _profile$6.displayRewardProfileScreen) === 'yes' || ((_profile$7 = profile[0]) === null || _profile$7 === void 0 ? void 0 : _profile$7.displayRewardProfileScreen) === true,
      displayRewardHistoryScreen: (_profile$0$displayRew = (_profile$8 = profile[0]) === null || _profile$8 === void 0 ? void 0 : _profile$8.displayRewardHistoryScreen) !== null && _profile$0$displayRew !== void 0 ? _profile$0$displayRew : true,
      displayRewardLoyaltyScreen: (_profile$9 = profile[0]) === null || _profile$9 === void 0 ? void 0 : _profile$9.displayRewardLoyaltyScreen,
      displayFreePlayBalance: (_profile$10 = profile[0]) === null || _profile$10 === void 0 ? void 0 : _profile$10.displayFreePlayBalance
    },
    offers: Object.values(offers).map(function (offer) {
      var _offer$maxperplayer, _offer$maxredemption;
      return {
        offerId: offer.rid,
        offerImage: offer.thumb,
        categoryType: offer.cat_name,
        rewardName: offer.rname,
        rewardType: offer.rewardtype,
        loyaltyPoints: offer.lpts,
        ribbontype: offer.ribbontype,
        remainingredeems: offer.remainingredeems,
        maxperplayer: (_offer$maxperplayer = offer.maxperplayer) !== null && _offer$maxperplayer !== void 0 ? _offer$maxperplayer : 0,
        maxredemption: (_offer$maxredemption = offer.maxredemption) !== null && _offer$maxredemption !== void 0 ? _offer$maxredemption : 0,
        isNew: offer.is_new
      };
    }),
    categories: Object.values(filterList).map(function (category) {
      return {
        categoryId: category.rc_id,
        categoryName: category.cat_name,
        categoryType: category.cat_name,
        categoryImage: category.title_image,
        categoryDescription: category.cat_desc
      };
    }),
    otpExpired: otpExpired,
    assetsPackageId: assetsPackageId
  };
  return rewardCenterAdapter;
};
export var postOfferDetailAdapter = function postOfferDetailAdapter(response, rewardCenterBaseUrl) {
  var _response$offerDetail = response.offerDetails,
    img1 = _response$offerDetail.img1,
    img2 = _response$offerDetail.img2,
    img3 = _response$offerDetail.img3;
  var images = [img1, img2, img3].filter(Boolean).map(function (img) {
    return "".concat(rewardCenterBaseUrl).concat(img);
  });
  return _objectSpread(_objectSpread({}, response), {}, {
    offerDetails: _objectSpread(_objectSpread({}, response.offerDetails), {}, {
      images: images
    })
  });
};
export var postRewardCenterHistoryAdapter = function postRewardCenterHistoryAdapter(response, rewardCenterBaseUrl) {
  var historyArray = [];
  if (typeof response === 'object' && response !== null) {
    historyArray = Object.values(response).map(function (historyItem) {
      return _objectSpread(_objectSpread({}, historyItem), {}, {
        cat_icon: rewardCenterBaseUrl + historyItem.cat_icon,
        date: moment(historyItem.date).format('DD/MM/YYYY')
      });
    });
  }
  return historyArray;
};
export var postRewardOfferHistoryDetailsAdapter = function postRewardOfferHistoryDetailsAdapter(response) {
  var offerHistoryDetailsAdapter = response;
  if (offerHistoryDetailsAdapter.expiryDate === NO_EXPIRY_DATE_STRING) {
    offerHistoryDetailsAdapter.expiryDate = getText(TEXT_KEY.NO_EXPIRY_DATE);
  } else {
    offerHistoryDetailsAdapter.expiryDate = "".concat(getText(TEXT_KEY.EXPIRES_ON), ": ").concat(offerHistoryDetailsAdapter.expiryDate);
  }
  offerHistoryDetailsAdapter.terms = offerHistoryDetailsAdapter.terms.replace(/(?:\r\n|\r|\n)/g, '<br><br>');
  return offerHistoryDetailsAdapter;
};