import LobbyOverlay from './LobbyOverlay';
import { connect } from 'shared/node_modules/react-redux';
import { FE_ANIMATION } from 'shared/utils/featureFlags/constants';
import { MissionTutorialStepsKeys, TUTORIAL_NAME } from 'shared/utils/tutorial';

const mapStateToProps = state => {
  return {
    blockLobby: state.tutorial.blockLobby,
    lobbyPromotions: state.promotions.lobbyNews,
    isMissionsTutorialFirstStep:
      state.tutorial.activeTutorial &&
      state.tutorial.tutorialName === TUTORIAL_NAME.MISSIONS_TUTORIAL &&
      state.tutorial.tutorialStep === MissionTutorialStepsKeys.CHECK_OUT,
    animationFeature: state.featureFlags.enabledFeatures[FE_ANIMATION]?.enabled,
  };
};

export default connect(mapStateToProps, null)(LobbyOverlay);

