import React from 'react';
import MessageDetail from './components/MessageDetail';
import { isEmpty } from 'shared/utils/object';
import MessageList from './components/MessageList';
import MessageEmpty from './components/MessageEmpty';
import styled from 'styled-components';

const Messages = props => {
  const { messages, activeMessage } = props;

  const MessagesComponent = activeMessage ? MessageDetail : !isEmpty(messages) ? MessageList : MessageEmpty;

  return (
    <Container isEmpty={isEmpty(messages)}>
      <MessagesComponent {...props}  />
    </Container>
  );
};

export default Messages;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 95%;
  justify-content: ${({ isEmpty }) => (isEmpty ? 'center' : 'flex-start')};
  ${({ isEmpty }) => !isEmpty && `gap: 1rem; margin-bottom: 1rem;`}
`;

