import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import {
  LoyaltyValueText,
  Container,
  LoyaltyPointsValueContainer,
  LoyaltyPointsExpiryText,
  LoyaltyIcon,
} from './styledComponents';
import { numberWithCommas } from 'shared/utils/number';

const RewardLoyaltyPointsTab = ({ loyaltyExpiryDate, loyaltyPoints }) => {
  return (
    <Container>
      <LoyaltyPointsValueContainer>
        <LoyaltyIcon alt="" src={assetSource(ASSET_KEY.LOYALTY_ICON)} />
        <LoyaltyValueText>{numberWithCommas(loyaltyPoints)}</LoyaltyValueText>
      </LoyaltyPointsValueContainer>
      <LoyaltyPointsExpiryText>
        {getText(TEXT_KEY.YOUR)} {getText(TEXT_KEY.LOYALTY_POINTS)}
        {getText(TEXT_KEY.EXPIRE_ON)}
        {loyaltyExpiryDate}, {getText(TEXT_KEY.BE_QUICK_AND_REDEEM_NOW)}
      </LoyaltyPointsExpiryText>
    </Container>
  );
};

export default RewardLoyaltyPointsTab;

