import { progressiveJackpotsIsFetching, progressiveJackpotsRequestDataSuccess, progressiveJackpotsRequestError } from '../slices/progressiveJackpots/progressiveJackpots';
import { propertiesIsFetching, propertiesRequestDataSuccess, propertiesRequestError } from '../slices/properties/properties';
import { getProgressiveJackpots } from '../api/ProgressiveJackpots';
import { getTexts, initProperty } from '../api/properties';
var progressiveJackpotsAction = {
  fetchData: getProgressiveJackpots,
  isFetching: progressiveJackpotsIsFetching,
  requestDataSuccess: progressiveJackpotsRequestDataSuccess,
  requestError: progressiveJackpotsRequestError
};
var propertiesAction = {
  fetchData: initProperty,
  fetchTexts: getTexts,
  isFetching: propertiesIsFetching,
  requestDataSuccess: propertiesRequestDataSuccess,
  requestError: propertiesRequestError
};
export var ACTION = {
  PROGRESSIVE_JACKPOTS: progressiveJackpotsAction,
  PROPERTIES: propertiesAction
};