import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getWatchAndEarnStartQuery, getWatchAndEarnCompleteQuery } from './queries';
export var videoAdsApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getWatchAndEarnStart: builder.query(getWatchAndEarnStartQuery),
      getWatchAndEarnComplete: builder.query(getWatchAndEarnCompleteQuery)
    };
  }
});
videoAdsApi.enhanceEndpoints({
  addTagTypes: ['WatchAndEarnStart', 'WatchAndEarnComplete']
});
var _videoAdsApi$endpoint = videoAdsApi.endpoints,
  getWatchAndEarnStart = _videoAdsApi$endpoint.getWatchAndEarnStart,
  getWatchAndEarnComplete = _videoAdsApi$endpoint.getWatchAndEarnComplete;
export { getWatchAndEarnStart, getWatchAndEarnComplete };