export default {
  type: 'object',
  oneOf: [{
    properties: {
      0: {
        type: 'object',
        properties: {
          allowMarketingEmails: {
            type: 'boolean'
          }
        },
        required: ['allowMarketingEmails']
      }
    },
    required: ['0']
  }, {
    properties: {
      success: {
        type: 'boolean'
      },
      message: {
        type: 'string'
      }
    },
    required: ['success', 'message']
  }]
};