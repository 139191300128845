import React, { Component } from 'react';
import SSessionExpired from 'shared/screens/SessionExpired';
import Modal from '../../components/JSModal';
import SessionExpireContainer from './components/Container';

export default class SessionExpired extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-session-expired',
    };
  }

  static get DismissHandler() {
    return () => {};
  }

  render() {
    return (
      <SSessionExpired
        navigation={this.props.navigation}
        render={args => {
          const { expireSessionHandler } = args;
          return (
            <Modal
              isVisible={true}
              closeModal={() => null}
              zIndex={101}
              style={styles.modalStyles}
              hideCloseButton={true}
            >
              <div className="sessionExpiredWrapper">
                <SessionExpireContainer expireSessionHandler={expireSessionHandler} />
              </div>
            </Modal>
          );
        }}
      />
    );
  }
}

SessionExpired.hideCloseButton = true;

const styles = {
  modalStyles: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: window.innerWidth < 1300 ? '0 30vmin' : window.innerWidth < 1700 ? '0 45vmin' : '0 55vmin',
  },
};
