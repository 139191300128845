import styled from 'styled-components';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export const HeaderContainer = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  height: 3.7rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-size: calc(100% + 2px) 100%;
  background-image: url(${assetSource(ASSET_KEY.FULL_MODAL_HEADER)});
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
`;

export const HeaderHeight = styled.div`
  height: 3.7rem;
`;

