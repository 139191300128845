import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useEffect, useState } from 'react';
import { useFormik } from '../../../node_modules/formik';
import { useSelector } from '../../../node_modules/react-redux';
import { getText, TEXT_KEY } from '../../../utils/localization';
import { validationSchema, registerCardsFormKeys, getFieldsObject, generateInitialValues } from '../utils';
export default function useRegisterCardForm(_ref) {
  var isWeb = _ref.isWeb,
    showProperty = _ref.showProperty,
    submitFormHandler = _ref.submitFormHandler,
    selectedProperty = _ref.selectedProperty,
    userProperty = _ref.userProperty;
  var _useSelector = useSelector(function (state) {
      return state.properties.data;
    }),
    defaultSignupFormSettings = _useSelector.defaultSignupFormSettings;
  var _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    propertyRequiredFields = _useState2[0],
    changePropertyCat = _useState2[1];
  var _useState3 = useState(''),
    _useState4 = _slicedToArray(_useState3, 2),
    genericError = _useState4[0],
    changeGenericError = _useState4[1];
  var initialValues = generateInitialValues(defaultSignupFormSettings, isWeb, userProperty);
  var checkFormValidation = function checkFormValidation() {
    return getFieldsObject((selectedProperty === null || selectedProperty === void 0 ? void 0 : selectedProperty.signupFormSettings) || defaultSignupFormSettings);
  };
  var formikValidation = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema(isWeb, showProperty, propertyRequiredFields),
    onSubmit: submitFormHandler,
    valideOnMount: false,
    enableReinitialize: false
  });
  var values = formikValidation.values,
    setFieldValue = formikValidation.setFieldValue,
    validateForm = formikValidation.validateForm,
    handleSubmit = formikValidation.handleSubmit;
  useEffect(function () {
    var propertyRequiredFields = checkFormValidation();
    changePropertyCat(propertyRequiredFields || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty === null || selectedProperty === void 0 ? void 0 : selectedProperty.propertyId]);
  useEffect(function () {
    validateForm();
  }, [propertyRequiredFields, validateForm]);
  useEffect(function () {
    setFieldValue(registerCardsFormKeys.STATE, '');
  }, [setFieldValue, values[registerCardsFormKeys.COUNTRY]]);
  var submitForm = function submitForm(isReadyToSend, queryResult) {
    if (!isReadyToSend) {
      queryResult === null || queryResult === void 0 || queryResult.reset();
      changeGenericError(getText(TEXT_KEY.REGISTER_REWARD_CARD_ERROR_MESSAGE));
      return;
    } else {
      changeGenericError('');
      handleSubmit();
    }
  };
  return _objectSpread(_objectSpread({}, formikValidation), {}, {
    propertyRequiredFields: propertyRequiredFields,
    genericError: genericError,
    submitForm: submitForm
  });
}