import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { postForgotPasswordMutation } from './queries';
export var forgotPasswordApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postForgotPassword: builder.mutation(postForgotPasswordMutation)
    };
  }
});
forgotPasswordApi.enhanceEndpoints({
  addTagTypes: ['ForgotPassword']
});
var postForgotPassword = forgotPasswordApi.endpoints.postForgotPassword;
export { postForgotPassword };