import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'LAYOUT';
var initialState = {
  loadingLayer: false
};
var layoutSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setLoadingLayer: function setLoadingLayer(state, action) {
      state.loadingLayer = action.payload;
    }
  }
});
var actions = layoutSlice.actions,
  reducer = layoutSlice.reducer;
var setLoadingLayer = actions.setLoadingLayer;
export { setLoadingLayer };
export default reducer;