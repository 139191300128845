import _slicedToArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useCallback, useEffect, useRef } from 'react';
import { MISSIONS_COUNTER } from '../../../screens/Missions/constants';
import { hasRewardState } from '../../../screens/Missions/utils/missionsPerformance';
import handlerMissionProgress from '../index';
import PushNotification from '../../../lib/notifications/PushNotification';
import Utils from '../../../utils';
import { useLazyGetMissionsQuery } from '../../../state/query/missions';
import { useDispatch } from 'react-redux';
import { setCurrentProgress } from '../../../state/slices/missions/missions';
var useMissionsProgress = function useMissionsProgress(props) {
  var lastSpinEvents = props.lastSpinEvents,
    currentMission = props.currentMission,
    playerId = props.playerId,
    timedBonus = props.timedBonus;
  var missionGameId = currentMission.missionGameId,
    _currentMission$count = currentMission.counterType,
    counterType = _currentMission$count === void 0 ? null : _currentMission$count,
    counterGoal = currentMission.counterGoal,
    serverProgress = currentMission.serverProgress,
    clientProgress = currentMission.clientProgress,
    missionStatus = currentMission.missionStatus;
  var dispatch = useDispatch();
  var _useLazyGetMissionsQu = useLazyGetMissionsQuery(),
    _useLazyGetMissionsQu2 = _slicedToArray(_useLazyGetMissionsQu, 1),
    trigger = _useLazyGetMissionsQu2[0];
  var clientMissionCompletePercent = useRef(0);
  var isInRewardState = hasRewardState(missionStatus);
  var isWeb = Utils.getPlatform() === 'web';
  var handleNewProgress = useCallback(function (percentage) {
    var checkedPercentage = (clientMissionCompletePercent === null || clientMissionCompletePercent === void 0 ? void 0 : clientMissionCompletePercent.current) || percentage;
    if (Math.floor(checkedPercentage) >= 100 && !isInRewardState) {
      clientMissionCompletePercent.current = checkedPercentage;
      setTimeout(function () {
        trigger({
          requestAssetsData: false,
          playerId: playerId
        });
      }, 2000);
    } else {
      dispatch(setCurrentProgress(isInRewardState ? 100 : percentage));
    }
  }, [trigger, playerId, dispatch, isInRewardState]);
  useEffect(function () {
    if (isInRewardState && serverProgress >= 100) {
      clientMissionCompletePercent.current = 0;
      dispatch(setCurrentProgress(100));
    }
  }, [serverProgress, isInRewardState, dispatch]);
  var handleSpinEvent = useCallback(function () {
    if (counterType !== MISSIONS_COUNTER.COLLECT_BONUS_X_TIMES) {
      var newPercentage = handlerMissionProgress[counterType](lastSpinEvents, counterGoal, clientProgress, missionGameId);
      handleNewProgress(newPercentage);
    }
  }, [clientProgress, counterGoal, counterType, lastSpinEvents, missionGameId, handleNewProgress]);
  useEffect(function () {
    handleSpinEvent();
    return function () {
      clientMissionCompletePercent.current = 0;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSpinEvents.createdTimestamp]);
  useEffect(function () {
    if (counterType === MISSIONS_COUNTER.COLLECT_BONUS_X_TIMES && timedBonus !== null && timedBonus !== void 0 && timedBonus.success) handleNewProgress(serverProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timedBonus]);
  useEffect(function () {
    if (Math.round(clientProgress) < Math.round(serverProgress)) dispatch(setCurrentProgress(serverProgress));
  }, [clientProgress, dispatch, serverProgress]);
  useEffect(function () {
    if (!isWeb && serverProgress >= 100) PushNotification.cancelMissionReminderNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverProgress]);
  return {
    clientProgress: clientProgress,
    serverProgress: serverProgress
  };
};
export default useMissionsProgress;