import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import QueryParamsProxy from '../../../utils/queryParamProxy';
import RouteParamConstants from '../../../utils/routeParamConstants';
import { EVENT_EMITTER_TYPE } from '../../../constants';
import Emitter from '../../../services/EmitterService';
import { ACTION_COMMANDS_TYPE } from '../../../api/ActionCommandsInterface/constants';
import { EVENTS_EVENT_TYPES } from '../constants';
import { sendEventsEvent } from '../analytics/eventsLogger';
import { usePostEventsMutation } from '../../../state/query/events';
import screens from '../..';
import { EVENT_TYPES } from '../../../lib/analytics/constants';
import ERROR_CODE from '../../../utils/errorCodes';
import Logger from '../../../lib/analytics/logger';
var COMPLETE_TUTORIAL_SLIDES = 4;
var ON_COMPLETE_ACTION = 'onCompleteAction';
var ON_SKIP_ACTION = 'onSkipAction';
export default function useEventsTutorial() {
  var lastSlide = useRef(1);
  var hasTutorial = QueryParamsProxy.findParam(RouteParamConstants.PARAM_SWEEPSTAKE_TUTORIAL, false);
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showInfoScreen = _useState2[0],
    setShowInfoScreen = _useState2[1];
  var actionCommands = useSelector(function (state) {
    return state.actionCommands.actions.find(function (action) {
      var _action$payload;
      return (action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.screen) === screens.Events;
    });
  });
  var _useSelector = useSelector(function (state) {
      return state.playerInfo;
    }),
    playerId = _useSelector.playerId;
  var _usePostEventsMutatio = usePostEventsMutation(),
    _usePostEventsMutatio2 = _slicedToArray(_usePostEventsMutatio, 1),
    postEvents = _usePostEventsMutatio2[0];
  useEffect(function () {
    if (hasTutorial) {
      sendEventsEvent(EVENTS_EVENT_TYPES.DISPLAY_EVENTS_TUTORIAL);
      setShowInfoScreen(true);
    }
  }, [hasTutorial]);
  var openInfoScreen = function openInfoScreen() {
    sendEventsEvent(EVENTS_EVENT_TYPES.EVENTS_OPEN_INFO_SCREEN);
    setShowInfoScreen(true);
  };
  var dismissInfoScreen = function dismissInfoScreen() {
    try {
      if (hasTutorial) {
        var _actionCommands$event2;
        var actionKey = (lastSlide === null || lastSlide === void 0 ? void 0 : lastSlide.current) === COMPLETE_TUTORIAL_SLIDES ? ON_COMPLETE_ACTION : ON_SKIP_ACTION;
        var _actionCommands$event = actionCommands === null || actionCommands === void 0 || (_actionCommands$event2 = actionCommands.eventsTutorial) === null || _actionCommands$event2 === void 0 ? void 0 : _actionCommands$event2[actionKey],
          actionType = _actionCommands$event.actionType,
          url = _actionCommands$event.url;
        postEvents({
          playerId: playerId,
          action: actionType,
          url: url
        });
        sendEventsEvent(EVENTS_EVENT_TYPES.SKIP_EVENTS_TUTORIAL, {
          step: lastSlide === null || lastSlide === void 0 ? void 0 : lastSlide.current
        });
        Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
          actionType: ACTION_COMMANDS_TYPE.CLEAR_ACTION_COMMAND,
          data: {
            uuid: actionCommands === null || actionCommands === void 0 ? void 0 : actionCommands.uuid
          }
        });
        QueryParamsProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_SWEEPSTAKE_TUTORIAL, undefined));
      }
      lastSlide.current = 1;
    } catch (error) {
      Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        code: ERROR_CODE.EVENTS_TUTORIAL_DISMISS_MODAL_ERROR
      });
    } finally {
      setShowInfoScreen(false);
    }
  };
  return {
    showInfoScreen: showInfoScreen,
    setShowInfoScreen: setShowInfoScreen,
    openInfoScreen: openInfoScreen,
    dismissInfoScreen: dismissInfoScreen,
    lastSlide: lastSlide
  };
}