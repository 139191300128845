import React, { Component } from 'react';
import SCountdownTimer from 'shared/lib/time/component/CountdownTimer';
import TimeRemainingText from '../../../components/TimeRemainingText';
import ImageBW from '../../../components/ImageBoundsWrapper';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const TIMER_BACKGROUND = {
  green: assetSource(ASSET_KEY.PURPLE_TIMER),
  yellow: assetSource(ASSET_KEY.ORANGE_TIMER),
  red: assetSource(ASSET_KEY.RED_TIMER),
};
export default class CountdownTimer extends Component {
  render() {
    return (
      <SCountdownTimer
        navigation={this.props.navigation}
        endDate={this.props.endDate}
        justTimerText={this.props.justTimerText}
        render={({ timerBackgroundColor }) => {
          return (
            <>
              {!this.props.justTimerText ? (
                <ImageBW
                  source={TIMER_BACKGROUND[timerBackgroundColor]}
                  ibw={require(asset`timer.ibw`)}
                  style={{
                    height: '1.7rem',
                    width: '80%',
                    padding: 0,
                    margin: 0,
                    top: '1.9rem',
                    left: '1.1rem',
                    zIndex: -1,
                  }}
                  innerStyle={{
                    width: '100%',
                    padding: 0,
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ImageBW
                    source={assetSource(ASSET_KEY.CLOCK_ICON)}
                    ibw={require(asset`clock-icon.ibw`)}
                    style={{
                      height: 18,
                      width: 18,
                      margin: 0,
                      marginRight: 2,
                    }}
                    innerStyle={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  />
                  <TimeRemainingText
                    style={{
                      fontSize: '14px',
                      margin: 0,
                      fontWeight: 400,
                      opacity: 0.7,
                      marginLeft: 2,
                    }}
                    until={this.props.endDate}
                    counterType={this.props.counterType}
                  />
                </ImageBW>
              ) : (
                <TimeRemainingText
                  style={
                    this.props.style
                      ? this.props.style
                      : {
                          fontSize: '14px',
                          margin: 0,
                          fontWeight: 400,
                          opacity: 0.7,
                          marginLeft: 2,
                        }
                  }
                  until={this.props.endDate}
                  counterType={this.props.counterType}
                  endTimerCallback={this.props.endTimerCallback}
                />
              )}
            </>
          );
        }}
      />
    );
  }
}
