import _toConsumableArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { DailyBonusResponseKeys, SpinnerItemKeys } from '../../../api/Constants';
import transformObjectArrayResponse from '../../../utils/transformObjectArrayResponse';
import transformObjectResponseKeys from '../../../utils/transformObjectResponseKeys';
export var getDailyWheelDataAdapter = function getDailyWheelDataAdapter(callResponse) {
  var _callResponse$LOGIN_S, _bonusInfo$MEMBER_TIE;
  callResponse = transformObjectResponseKeys(callResponse, DailyBonusResponseKeys);
  callResponse.SPINNER_ITEMS = transformObjectArrayResponse(callResponse.SPINNER_ITEMS).map(function (item) {
    return transformObjectResponseKeys(item, SpinnerItemKeys);
  });
  callResponse.RESPIN_COST = parseInt(callResponse.RESPIN_COST);
  callResponse.LOGIN_STREAK_COINS_LIST = transformObjectArrayResponse(callResponse.LOGIN_STREAK_COINS_LIST);
  callResponse.LOGIN_STREAK_LOYALTY_LIST = transformObjectArrayResponse(callResponse.LOGIN_STREAK_LOYALTY_LIST);
  callResponse.LOGIN_STREAK_BONUS_LIST = callResponse.LOGIN_STREAK_COINS_LIST.map(function (item, i) {
    return {
      coins: item.display_amount,
      loyalty: callResponse.LOGIN_STREAK_LOYALTY_LIST[i].display_amount,
      day: parseInt(callResponse.LOGIN_STREAK_LOYALTY_LIST[i]['__sort_key'])
    };
  });
  callResponse.MEMBER_TIERS = transformObjectArrayResponse(callResponse.MEMBER_TIERS);
  if (((_callResponse$LOGIN_S = callResponse.LOGIN_STREAK_BONUS_LIST) === null || _callResponse$LOGIN_S === void 0 ? void 0 : _callResponse$LOGIN_S.findIndex(function (item) {
    return item.day === callResponse.CURRENT_REWARD_DAY;
  })) < 0) {
    return false;
  }
  var bonusInfo = _objectSpread({}, callResponse);
  var spinnerItems = _toConsumableArray((bonusInfo === null || bonusInfo === void 0 ? void 0 : bonusInfo.SPINNER_ITEMS) || []);
  var currentTierBonus = ((_bonusInfo$MEMBER_TIE = bonusInfo.MEMBER_TIERS) === null || _bonusInfo$MEMBER_TIE === void 0 || (_bonusInfo$MEMBER_TIE = _bonusInfo$MEMBER_TIE.find(function (tier) {
    var _bonusInfo$CURRENT_ME, _tier$tier_name;
    return ((_bonusInfo$CURRENT_ME = bonusInfo.CURRENT_MEMBER_TIER) === null || _bonusInfo$CURRENT_ME === void 0 ? void 0 : _bonusInfo$CURRENT_ME.toLowerCase()) === ((_tier$tier_name = tier.tier_name) === null || _tier$tier_name === void 0 ? void 0 : _tier$tier_name.toLowerCase());
  })) === null || _bonusInfo$MEMBER_TIE === void 0 ? void 0 : _bonusInfo$MEMBER_TIE.daily_bonus_boost_percentage) || null;
  var totalLoyalty = bonusInfo.DISPLAY_TOTAL_LOYALTY,
    totalCoinsAfterTierBonus = bonusInfo.DISPLAY_TOTAL_COINS_AFTER_TIER_BONUS,
    dailyBonus = bonusInfo.DISPLAY_DAY_STREAK_COINS,
    wheelBonus = bonusInfo.DISPLAY_WHEEL_BONUS;
  var enableLoyalty = bonusInfo !== null && bonusInfo !== void 0 && bonusInfo.ENABLE_LOYALTY ? bonusInfo.ENABLE_LOYALTY === 'yes' : true;
  return {
    bonusInfo: bonusInfo,
    currentTierBonus: currentTierBonus,
    wheelBonus: wheelBonus,
    dailyBonus: dailyBonus,
    totalLoyalty: totalLoyalty,
    totalCoinsAfterTierBonus: totalCoinsAfterTierBonus,
    spinnerItems: spinnerItems,
    enableLoyalty: enableLoyalty,
    showTotalCoins: totalCoinsAfterTierBonus && totalCoinsAfterTierBonus !== '0' ? true : false,
    showTotalLoyalty: totalLoyalty && totalLoyalty !== '0' ? true : false
  };
};