import React from 'react';
import styled from 'styled-components';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const SplashScreenFooter = () => {
  return (
    <BottomContainer>
      <BottomLeftContainer>
        <BottomLeftImage src={assetSource(ASSET_KEY.LOADING_LOGO_LEFT)} alt="" />
        <BottomLeftText>{getText(TEXT_KEY.SPLASH_SCREEEN_FOOTER_LEFT)}</BottomLeftText>
      </BottomLeftContainer>
      <BottomRightContainer>
        <BottomRightText>{getText(TEXT_KEY.SPLASH_SCREEEN_FOOTER_RIGHT)}</BottomRightText>
        <BottomRightImage src={assetSource(ASSET_KEY.LOADING_LOGO)} alt="" />
      </BottomRightContainer>
    </BottomContainer>
  );
};

export default SplashScreenFooter;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 9rem;
  width: 100%;
  bottom: 0;
  position: absolute;
  justify-content: space-between;
  z-index: 0;
`;

const BottomRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 2.5rem;
`;

const BottomLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 2rem;
`;

const BottomRightImage = styled.img`
  height: 4rem;
  width: 7rem;
`;

const BottomLeftImage = styled.img`
  height: 3.2rem;
  width: 10.7rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

const BottomRightText = styled.h4`
  color: white;
  letter-spacing: 0px;
  font-size: 0.9rem;
  font-weight: 800;
  text-transform: uppercase;
`;

const BottomLeftText = styled.h4`
  color: white;
  letter-spacing: 0px;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
`;

