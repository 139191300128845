import React from 'react';
import Utils from 'shared/utils/index';
import useAssetPreloader from 'shared/components/AssetPreloader/useAssetPreloader';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const DefaultPreloader = () => {
  const { percentLoaded } = useAssetPreloader();

  return (
    <div className="AssetPreloader" style={{ backgroundImage: `url(${assetSource(ASSET_KEY.LOADING_SCREEN)})` }}>
      <div className={'AssetPreloaderLoadingBar' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
        <img alt="" className="ExpBarInset" src={assetSource(ASSET_KEY.LOADING_BAR_INSET)} />
        <img
          alt=""
          src={assetSource(ASSET_KEY.LOADING_BAR_FILL)}
          className="ExpBarInner"
          style={{ width: `${percentLoaded > 3 ? percentLoaded - 2 : 3}%` }}
        />
      </div>
    </div>
  );
};

export default DefaultPreloader;

