import React, { Component } from 'react';
import SFavouritesUnlocked from 'shared/screens/FavouritesUnlocked';
import ImageBW from '../../components/ImageBoundsWrapper';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default class FavouritesUnlocked extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-favouritesUnlocked',
    };
  }

  render() {
    return (
      <SFavouritesUnlocked
        dismissModal={this.props.dismissModal}
        render={args => {
          const { buttonHandler } = args;

          return (
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
              }}
            >
              <div
                onClick={buttonHandler}
                style={{
                  position: 'absolute',
                  top: '-1.75rem',
                  right: '-2rem',
                  padding: 0,
                  height: '4rem',
                  width: '4rem',
                  zIndex: 9,
                  cursor: 'pointer',
                  pointerEvents: 'auto',
                }}
              >
                <ImageBW
                  source={assetSource(ASSET_KEY.CLOSE_BUTTON)}
                  ibw={require(asset`close-button.ibw`)}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  resizeMode="contain"
                />
              </div>
              <img
                alt="Favourites unlocked"
                src={assetSource(ASSET_KEY.INFO_POPUP)}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          );
        }}
      />
    );
  }
}

FavouritesUnlocked.hideDefaultBackground = true;
FavouritesUnlocked.hideCloseButton = true;
