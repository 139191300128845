import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useCallback, useEffect } from 'react';
import Screens from '../screens';
import { MISSIONS_GAMEPLAY_STATUS } from '../screens/Missions/constants';
import { generateDescription } from '../screens/Missions/utils/generateMissionCardDataRendering';
import utils from '../utils';
import QueryParamsProxy from '../utils/queryParamProxy';
var useMissionsTooltips = function useMissionsTooltips(props) {
  var currentMission = props.currentMission,
    shouldShowTooltip = props.shouldShowTooltip,
    tooltipDetails = props.tooltipDetails,
    missionFinishedTooltipDetail = props.missionFinishedTooltipDetail,
    missionsGameplayStatus = props.missionsGameplayStatus,
    modal = props.modal,
    showMissionsProgressBar = props.showMissionsProgressBar,
    navigation = props.navigation;
  var serverProgress = currentMission.serverProgress,
    goalMessage = currentMission.goalMessage;
  var handleMissionEndTooltip = useCallback(function () {
    if (Math.round(serverProgress) >= 100 && missionsGameplayStatus !== MISSIONS_GAMEPLAY_STATUS.TUTORIAL && !modal && showMissionsProgressBar) {
      tooltipDetails(missionFinishedTooltipDetail);
      shouldShowTooltip(true);
      setTimeout(function () {
        var out = _objectSpread(_objectSpread({}, missionFinishedTooltipDetail), {}, {
          animationState: utils.getPlatform() === 'web' ? 'AnimationSlideToTop' : 'out'
        });
        tooltipDetails(out);
      }, 3000);
    }
  }, [serverProgress, missionsGameplayStatus, modal, tooltipDetails, missionFinishedTooltipDetail, shouldShowTooltip, showMissionsProgressBar]);
  useEffect(function () {
    handleMissionEndTooltip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverProgress, modal]);
  var onMissionProgressPressHandler = function onMissionProgressPressHandler() {
    if (serverProgress < 100) {
      var tooltipText = generateDescription('', {
        goalMessage: goalMessage
      });
      var detail = _objectSpread(_objectSpread({}, missionFinishedTooltipDetail), {}, {
        title: tooltipText
      });
      tooltipDetails(detail);
      shouldShowTooltip(true);
      setTimeout(function () {
        var out = _objectSpread(_objectSpread({}, detail), {}, {
          animationState: utils.getPlatform() === 'web' ? 'AnimationSlideToTop' : 'out'
        });
        tooltipDetails(out);
      }, 3000);
    } else {
      QueryParamsProxy.navigate(navigation, Screens.Missions);
    }
  };
  return {
    onMissionProgressPressHandler: onMissionProgressPressHandler
  };
};
export default useMissionsTooltips;