import React from 'react';
import Button from '../../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import generateRender from 'shared/utils/generateRender';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default function CollectButton(props) {
  const RedemButton = generateRender({
    default: DefaultCollectButton,
    star: StarCollectButton,
  });
  return <RedemButton {...props} />;
}

const DefaultCollectButton = props => {
  return (
    <Button {...props} imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)} textStroke={BUTTON_STROKES.PRIMARY} />
  );
};

const StarCollectButton = props => {
  return (
    <Button
      {...props}
      imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_TERTIARY)}
      textStroke={BUTTON_STROKES.TERTIARY}
    />
  );
};

